// アポロ管財情報
export const APOLLO_INFO = {
  name: 'アポロ管財株式会社',
  zip: '〒201-0015',
  address: '東京都狛江市猪方3-36-3',
  tel: '03-5438-8804',
  fax: '03-5438-8809'
}

// 改行コード（見積_カテゴリ名）ここを変更した場合は、帳票や契約書の変更も必須
export const newLineCode = /@@/g

export const ESTIMATE_TYPES = [
  {
    typeId: 'normal',
    name: '通常見積り'
  },
]

// 見積ステータス
export const ESTIMATE_STATUS = [
  { 
    id: 'all',
    name: '全て',
    color: 'gray',
    close: false
  },
  { 
    id: 'inprogress', 
    name: '作成中',
    color: 'orange',
    close: false
  },
  { 
    id: 'awaitingApproval', 
    name: '承認申請中',
    color: 'green',
    close: false
  },
  { 
    id: 'rejectionApproval', 
    name: '差戻',
    color: 'red',
    close: false
  },
  { 
    id: 'incomplete',
    name: 'メインタスク未完了',
    color: 'gray',
    close: false
  },
  {
    id: 'myApproval',
    name: '担当の承認待ち',
    color: 'gray',
    close: false
  },
  {
    id: 'ordered',
    name: '受注',
    color: 'purple2',
    close: true
  },
]

// 見積ステータスカラー
const addStatus = [
  { 
    id: 'approved', 
    name: '承認済み',
    color: 'lightGreen',
    close: false
  },
  { 
    id: 'awaitingApproval', 
    name: '承認待ち',
    color: 'pink',
    close: false
  },
  { 
    id: 'completed', 
    name: '作成完了',
    color: 'blueGray',
    close: false
  },
  {
    id: 'ordered',
    name: '受注登録中',
    color: 'purple2',
    close: true
  },
  {
    id: 'ordered',
    name: '受注完了',
    color: 'lightGreen',
    close: true
  },
  {
    id: 'reject',
    name: '却下',
    color: 'black',
    close: true
  },
]
export const ESTIMATE_STATUS_COLOR
  = ESTIMATE_STATUS.concat(addStatus)

// 消費税率
export const TAX_RATE = 10

// 見積書PDFファイル名
export const ESTIMATE_PDF_FILE_NAME = '【御見積書】{{estimateNo}}_{{buildingName}}.pdf'
export const ESTIMATE_PDF_FILE_NAME_ORDER = '【御注文書】{{estimateNo}}_{{buildingName}}.pdf'
export const ESTIMATE_COVER_PAGE_PDF_FILE_NAME = `【送付状】${ESTIMATE_PDF_FILE_NAME}`

export * as SOLID_ICONS from '@vue-hero-icons/solid'
export * as OUTLINE_ICONS from '@vue-hero-icons/outline'

// 巡回
export const WEIGHT = 15

// 特別清掃 作業項目
export const SPECIALCLEAN_WORK_ITEMS = [
  { value: '腰壁清掃', id: 'wall' },
  { value: '照明器具清掃', id: 'lighting' },
  { value: 'エントランス等石材酸洗浄', id: 'acid' },
  { value: 'エントランスコーティング（皮膜タイプ）', id: 'filmCoating' },
  { value: 'エントランスコーティング（浸透性タイプ）', id: 'penetrationCoating' },
  { value: 'ガラリ清掃', id: 'garari' },
  { value: '側溝', id: 'gutter' },
  { value: '枡清掃', id: 'masu' },
  { value: '駐車場ピット内清掃', id: 'parking' },
  { value: '安全対策要員', id: 'safety' },
  { value: '釜場清掃（スコップ）', id: 'shovel' },
  { value: '汚泥処理', id: 'sludge' },
  { value: 'インターロッキング洗浄', id: 'inter' },
  { value: '屋上ドレン清掃', id: 'drain' },
  { value: '駐輪場清掃', id: 'bicycle' },
  { value: '駐輪場屋根', id: 'roof' },
  { value: '壁面拭き上げ', id: 'wipe' },
  { value: 'エアコンフィルター清掃', id: 'filter' },
  { value: 'ルーバー', id: 'louver' },
  { value: '機械式駐車場パレット清掃（掃き）20/日', id: 'twentyPallet' },
  { value: '機械式駐車場パレット清掃（掃き）50/日', id: 'fiftyPallet' },
  { value: 'クーラーフェンス', id: 'fence' },
  { value: '天井清掃(ステンレス）', id: 'ceiling' },
  { value: '水景(池)清掃', id: 'pond' },
  { value: '外廊下面格子・網戸清掃', id: 'lattice' },
  { value: '該当無', id: 'none' },
]

/**
 * 特別清掃 作業項目名 取得
 * @param {*} id ID
 * @returns 作業項目名
 */
export function getSpecialCleanWorkItemName(id) {
  const targets = SPECIALCLEAN_WORK_ITEMS.filter( i => { return i.id == id })
  if ( targets.length > 0 ) return targets[0].value
  else return ''
}


const APOLLO_CLIENT = {
  clientCode: '9999999999',
  clientName1: 'アポロ管財テスト用',
  clientName2: 'テスト',
}

export const APOLLO = [
  {
    clientCode: APOLLO_CLIENT.clientCode,
    clientName1: APOLLO_CLIENT.clientName1,
    clientName2: APOLLO_CLIENT.clientName2,
    siteCode: '999999999',
    siteName: 'アポロ管財物件テスト',
    zip: '201-0015',
    siteAddress: '東京都狛江市猪方3-36-3',
    latitude: '35.624315',
    longitude: '139.579387'
  },
  {
    clientCode: APOLLO_CLIENT.clientCode,
    clientName1: APOLLO_CLIENT.clientName1,
    clientName2: APOLLO_CLIENT.clientName2,
    siteCode: '999999999_2',
    siteName: 'アポロ管財物件テスト_2',
    zip: '201-0001',
    siteAddress: '東京都狛江市西野川2-36',
    latitude: '35.649823',
    longitude: '139.575519'
  },
  {
    clientCode: APOLLO_CLIENT.clientCode,
    clientName1: APOLLO_CLIENT.clientName1,
    clientName2: APOLLO_CLIENT.clientName2,
    siteCode: '999999999_3',
    siteName: 'アポロ管財物件テスト_3',
    zip: '201-0002',
    siteAddress: '東京都狛江市東野川3-9-5',
    latitude: '35.647058',
    longitude: '139.581668'
  },
  // {
  //   clientCode: APOLLO_CLIENT.clientCode,
  //   clientName1: APOLLO_CLIENT.clientName1,
  //   clientName2: APOLLO_CLIENT.clientName2,
  //   siteCode: '999999999_4',
  //   siteName: 'アポロ管財物件テスト_4',
  //   zip: '201-0001',
  //   siteAddress: '東京都狛江市西野川4-8',
  //   latitude: '35.647329',
  //   longitude: '139.569598'
  // },
  // {
  //   clientCode: APOLLO_CLIENT.clientCode,
  //   clientName1: APOLLO_CLIENT.clientName1,
  //   clientName2: APOLLO_CLIENT.clientName2,
  //   siteCode: '999999999_5',
  //   siteName: 'アポロ管財物件テスト_5',
  //   zip: '201-0002',
  //   siteAddress: '東京都狛江市東野川2-16-10',
  //   latitude: '35.645544',
  //   longitude: '139.579759'
  // },
  // {
  //   clientCode: APOLLO_CLIENT.clientCode,
  //   clientName1: APOLLO_CLIENT.clientName1,
  //   clientName2: APOLLO_CLIENT.clientName2,
  //   siteCode: '999999999_6',
  //   siteName: 'アポロ管財物件テスト_6',
  //   zip: '201-0002',
  //   siteAddress: '東京都狛江市和泉本町2-22',
  //   latitude: '35.642673',
  //   longitude: '139.578383'
  // },
  // {
  //   clientCode: APOLLO_CLIENT.clientCode,
  //   clientName1: APOLLO_CLIENT.clientName1,
  //   clientName2: APOLLO_CLIENT.clientName2,
  //   siteCode: '999999999_7',
  //   siteName: 'アポロ管財物件テスト_7',
  //   zip: '201-0001',
  //   siteAddress: '東京都狛江市西野川3-10-12',
  //   latitude: '35.644207',
  //   longitude: '139.57107'
  // },
  // {
  //   clientCode: APOLLO_CLIENT.clientCode,
  //   clientName1: APOLLO_CLIENT.clientName1,
  //   clientName2: APOLLO_CLIENT.clientName2,
  //   siteCode: '999999999_8',
  //   siteName: 'アポロ管財物件テスト_8',
  //   zip: '201-0005',
  //   siteAddress: '東京都狛江市岩戸南2-17-12',
  //   latitude: '35.62949',
  //   longitude: '139.585122'
  // },
  // {
  //   clientCode: APOLLO_CLIENT.clientCode,
  //   clientName1: APOLLO_CLIENT.clientName1,
  //   clientName2: APOLLO_CLIENT.clientName2,
  //   siteCode: '999999999_9',
  //   siteName: 'アポロ管財物件テスト_9',
  //   zip: '201-0005',
  //   siteAddress: '東京都狛江市岩戸南3-9',
  //   latitude: '35.630497',
  //   longitude: '139.591233'
  // },
  // {
  //   clientCode: APOLLO_CLIENT.clientCode,
  //   clientName1: APOLLO_CLIENT.clientName1,
  //   clientName2: APOLLO_CLIENT.clientName2,
  //   siteCode: '999999999_10',
  //   siteName: 'アポロ管財物件テスト_10',
  //   zip: '201-0011',
  //   siteAddress: '東京都狛江市西和泉1-16-1',
  //   latitude: '35.640268',
  //   longitude: '139.565144'
  // },
  // {
  //   clientCode: APOLLO_CLIENT.clientCode,
  //   clientName1: APOLLO_CLIENT.clientName1,
  //   clientName2: APOLLO_CLIENT.clientName2,
  //   siteCode: '999999999_11',
  //   siteName: 'アポロ管財物件テスト_11',
  //   zip: '201-0011',
  //   siteAddress: '東京都狛江市西和泉2-12-12',
  //   latitude: '35.636545',
  //   longitude: '139.564065'
  // },
  // {
  //   clientCode: APOLLO_CLIENT.clientCode,
  //   clientName1: APOLLO_CLIENT.clientName1,
  //   clientName2: APOLLO_CLIENT.clientName2,
  //   siteCode: '999999999_12',
  //   siteName: 'アポロ管財物件テスト_12',
  //   zip: '201-0012',
  //   siteAddress: '東京都狛江市中和泉2-26-3',
  //   latitude: '35.6388',
  //   longitude: '139.56901'
  // },
  // {
  //   clientCode: APOLLO_CLIENT.clientCode,
  //   clientName1: APOLLO_CLIENT.clientName1,
  //   clientName2: APOLLO_CLIENT.clientName2,
  //   siteCode: '999999999_13',
  //   siteName: 'アポロ管財物件テスト_13',
  //   zip: '201-0012',
  //   siteAddress: '東京都狛江市中和泉5-23-26',
  //   latitude: '35.640906',
  //   longitude: '139.56513'
  // },
  // {
  //   clientCode: APOLLO_CLIENT.clientCode,
  //   clientName1: APOLLO_CLIENT.clientName1,
  //   clientName2: APOLLO_CLIENT.clientName2,
  //   siteCode: '999999999_14',
  //   siteName: 'アポロ管財物件テスト_14',
  //   zip: '201-0013',
  //   siteAddress: '東京都狛江市元和泉1-8-14',
  //   latitude: '35.632045',
  //   longitude: '139.576444'
  // },
  // {
  //   clientCode: APOLLO_CLIENT.clientCode,
  //   clientName1: APOLLO_CLIENT.clientName1,
  //   clientName2: APOLLO_CLIENT.clientName2,
  //   siteCode: '999999999_15',
  //   siteName: 'アポロ管財物件テスト_15',
  //   zip: '201-0013',
  //   siteAddress: '東京都狛江市元和泉2-38-1',
  //   latitude: '35.632805',
  //   longitude: '139.567846'
  // },
  // {
  //   clientCode: APOLLO_CLIENT.clientCode,
  //   clientName1: APOLLO_CLIENT.clientName1,
  //   clientName2: APOLLO_CLIENT.clientName2,
  //   siteCode: '999999999_16',
  //   siteName: 'アポロ管財物件テスト_16',
  //   zip: '201-0014',
  //   siteAddress: '東京都狛江市東和泉4-9',
  //   latitude: '35.625966',
  //   longitude: '139.571085'
  // },
  // {
  //   clientCode: APOLLO_CLIENT.clientCode,
  //   clientName1: APOLLO_CLIENT.clientName1,
  //   clientName2: APOLLO_CLIENT.clientName2,
  //   siteCode: '999999999_17',
  //   siteName: 'アポロ管財物件テスト_17',
  //   zip: '201-0015',
  //   siteAddress: '東京都狛江市猪方3-41-9',
  //   latitude: '35.624378',
  //   longitude: '139.580265'
  // },
  // {
  //   clientCode: APOLLO_CLIENT.clientCode,
  //   clientName1: APOLLO_CLIENT.clientName1,
  //   clientName2: APOLLO_CLIENT.clientName2,
  //   siteCode: '999999999_18',
  //   siteName: 'アポロ管財物件テスト_18',
  //   zip: '201-0015',
  //   siteAddress: '東京都狛江市猪方4-1-17',
  //   latitude: '35.624307',
  //   longitude: '139.57518'
  // },
  // {
  //   clientCode: APOLLO_CLIENT.clientCode,
  //   clientName1: APOLLO_CLIENT.clientName1,
  //   clientName2: APOLLO_CLIENT.clientName2,
  //   siteCode: '999999999_19',
  //   siteName: 'アポロ管財物件テスト_19',
  //   zip: '201-0016',
  //   siteAddress: '東京都狛江市駒井町1-13-4',
  //   latitude: '35.625689',
  //   longitude: '139.58556'
  // },
  // {
  //   clientCode: APOLLO_CLIENT.clientCode,
  //   clientName1: APOLLO_CLIENT.clientName1,
  //   clientName2: APOLLO_CLIENT.clientName2,
  //   siteCode: '999999999_20',
  //   siteName: 'アポロ管財物件テスト_20',
  //   zip: '201-0016',
  //   siteAddress: '東京都狛江市駒井町3-10-16',
  //   latitude: '35.622393',
  //   longitude: '139.583251'
  // },
]


/**
 * 受注で使用する見積カテゴリのプロパティとコード、添付のプロパティー名
 */
export const PROPSET = [
  // ラウンドサービス
  {code: '1', val: 'orderRls', file: 'estimationRoundCommonFile', name: 'ラウンドサービス'},
  // ラウンドプラス
  {code: '2', val: 'orderRlsPlus', file: 'estimationRoundPlusCommonFile', name: 'ラウンドプラス'},
  // 日常清掃
  {code: '3', val: 'orderEverydays', file: 'estimationEverydayCommonFile', name: '日常清掃'},
  // 管理
  {code: '4', val: 'orderManagerWorks', file: 'managerWorkCommonFile', name: '管理員業務'},
  // 定期
  {code: '5', val: 'orderRegular', file: 'regularCleanCommonFile', name: '定期清掃'},
  // フロントサポート
  {code: '6', val: 'orderFront', file: 'frontSupportCommonFile', name: 'フロントサポート'},
  // フロントサポートプラス
  {code: '7', val: 'orderFrontPlus', file: 'frontSupportPlusCommonFile', name: 'フロントサポートプラス'},
  // ランドトラッシュ
  {code: '8', val: 'orderRlsTrash', file:'roundTrashCommonFile', name: 'ラウンドトラッシュ'},
  // クオリティーレポート
  {code: '9', val: 'orderQuality', file:'qualityReportCommonFile', name: 'クオリティレポート'},
  // ガラス清掃
  {code: '10', val: 'orderGlass', file:'glassCleanCommonFile', name: 'ガラス清掃'},
  // 排水管清掃
  {code: '11', val: 'orderDrain', file:'estimationDorainPipeCommonFile', name: '排水管清掃'},
  // 貯水槽清掃
  {code: '12', val: 'orderWaterTank', file:'estimationWaterTankCommonFile', name: '貯水槽清掃'},
  // 植栽剪定
  {code: '13', val: 'orderPlanting', file:'plantingCommonFile', name: '植栽剪定'},
  // 特別清掃
  {code: '14', val: 'orderSpecialCleans', file:'specialCleanCommonFile', name: '特別清掃'},
  // 消防設備点検
  {code: '15', val: 'orderFire', file:'fireInspectCommonFile', name: '消防設備点検'},
  // 建築設備定期検査
  {code: '16', val: 'orderConstruction', file:'constructionEquipmentInspectCommonFile', name: '建築設備定期検査'},
  // 特定建築物定期調査
  {code: '17', val: 'orderSpConstruction', file:'specificInspectCommonFile', name: '特定建築物定期調査'},
  // 共用部設備点検
  {code: '18', val: 'orderCommon', file:'commonAreaFacilityInspectionCommonFile', name: '共用部設備点検'},
  // エレベータ保守点検
  {code: '19', val: 'orderEv', file:'evInspectCommonFile', name: 'エレベーター保守点検'},
  // 増圧ポンプ点検
  {code: '20', val: 'orderPress', file:'pressPumpInspectCommonFile', name: '増圧ポンプ点検'},
  // 給排水ポンプ点検
  {code: '21', val: 'orderWaterSupply', file:'waterSupplyDrainagePumpInspectionCommonFile', name: '給排水ポンプ点検'},
  // 簡易専用水道
  {code: '22', val: 'orderSimpleWater', file:'simpleWaterSupplyCommonFile', name: '簡易専用水道'},
  // 管球対応
  {code: '23', val: 'orderTube', file:'tubeBulbSupportCommonFile', name: '管球対応'},
  // 粗大ごみ
  {code: '24', val: 'orderBulkyTrash', file:'bulkyWasteSupportCommonFile', name: '粗大ごみ対応'},
  // 物品販売
  {code: '25', val: 'orderSale', file:'merchandiseSalesCommonFile', name: '物品販売'},
  // 緊急対応
  {code: '26', val: 'orderEmergency', file:'emergencyResponseCommonFile', name: '緊急対応'},
  // 防火対象物点検
  {code: '27', val: 'orderFireObj', file:'firePreventionObjectInspectionCommonFile', name: '防火対象物点検'},
  // 汚水槽清掃
  {code: '101', val: 'orderSewage', file:'sewageTankCleaningCommonFile', name: '汚水槽清掃'},
  // 防火設備定期検査
  {code: '102', val: 'orderFireFixed', file:'fireProtectionInspectionCommonFile', name: '防火設備定期検査'},
  // 連結送水管耐圧性能点検
  {code: '103', val: 'orderConnented', file:'connectingWaterPipePressureInspectionCommonFile', name: '連結送水管耐圧性能点検'},
  // スポット清掃
  {code: '28', val: 'orderSpot', file:'', name: 'スポット清掃'},
  // MRS
  {code: '29', val: 'orderMRS', file:'mrsCommonFile', name: 'マンションローテーションサービス（MRS）'},
  // カテゴリーなし
  {code: 'none', val: 'orderOthers', file:'', name: ''},
]

/**
 * 面接・雇入れ（必要書類）
 */
export const MATERIAL_SELECTIONS = [
  {
    value: '業務報告書',
    text: '業務報告書'
  },
  // {
  //   value: '指定報告書',
  //   text: '指定報告書'
  // },
  {
    value: '返信用封筒',
    text: '返信用封筒'
  },
  {
    value: '代勤用賃金簿',
    text: '代勤用賃金簿'
  }
]

/**
 * 面接・雇入れ共通（東京23区+α）
 */
export const AREA_SELECTIONS = [
  '',
  '千代田区',
  '中央区',
  '港区',
  '新宿区',
  '文京区',
  '台東区',
  '墨田区',
  '江東区',
  '品川区',
  '目黒区',
  '大田区',
  '世田谷区',
  '渋谷区',
  '中野区',
  '杉並区',
  '豊島区',
  '北区',
  '荒川区',
  '板橋区',
  '練馬区',
  '足立区',
  '葛飾区',
  '江戸川区',
  '多摩',
  '神奈川',
  '千葉',
  '埼玉'
]

/**
 * 面接・雇入れ共通（作業内容）
 */
export const WORK_SELECTIONS = [
  {
    value: '日常清掃',
    text: '日常清掃'
  },
  {
    value: '管理員',
    text: '管理員'
  },
  {
    value: '管理員(日常清掃含)',
    text: '管理員(日常清掃含)'
  },
  {
    value: '巡回員',
    text: '巡回員'
  },
  {
    value: '代勤専門',
    text: '代勤専門'
  }
] 

/**
 * 祝日区分
 */
export const HOLIDAYTYPE_SELECTIONS = [
  {
    value: 'FULL',
    text: 'FULL'
  },
  {
    value: '1時間',
    text: '1時間'
  },
  {
    value: '2時間',
    text: '2時間'
  },
  {
    value: '3時間',
    text: '3時間'
  },
  {
    value: '無',
    text: '無'
  }
] 

/**
 * 有り無し
 */
export const YES_NO_SELECTIONS = [
  {
    value: '有',
    text: '有'
  },
  {
    value: '無',
    text: '無'
  }
]

/**
 * 時間
 */
export const HOURS =  [
  { text: '7' },
  { text: '8' },
  { text: '9' },
  { text: '10' },
  { text: '11' },
  { text: '12' },
  { text: '13' },
  { text: '14' },
  { text: '15' },
  { text: '16' },
  { text: '17' },
  { text: '18' },
  { text: '19' },
  { text: '20' },
  { text: '21' },
  { text: '22' },
  { text: '23' }
]

/**
 * 分
 */
export const MINUTES = [
  { text: '00' },
  { text: '15' },
  { text: '30' },
  { text: '45' }
]

/**
 * 曜日
 */
export const DAYS_SELECTIONS = [
  {
    value: '月',
    text: '月'
  },
  {
    value: '火',
    text: '火'
  },
  {
    value: '水',
    text: '水'
  },
  {
    value: '木',
    text: '木'
  },
  {
    value: '金',
    text: '金'
  },
  {
    value: '土',
    text: '土'
  },
  {
    value: '日',
    text: '日'
  }
]

/**
 * 曜日
 */
export const WEEKS = [
  '月',
  '火',
  '水',
  '木',
  '金',
  '土',
  '日'
]

/**
 * 交通費区分
 */
export const PROVISION_SELECTIONS = [
  {
    value: '１日',
    text: '１日'
  },
  {
    value: '１ヵ月',
    text: '１ヵ月'
  },
  {
    value: '無',
    text: '無'
  }
]

/**
 * サブタスクマスタで設定する期限のもととなる日
 */
export const SUB_TASK_LIMIT = [
  {
    name: '登録日',
    value: null
  },
  {
    name: '入社日',
    value: 'employmentHireDate'
  },
  {
    name: '退職日',
    value: 'employmentRetirementDate'
  }
]

/**
 * 週休〇日制
 */
export const WEEKLY_HOLIDAYS = [
  {
    value: '週休2日制',
    text: '週休2日制'
  },
  {
    value: '週休3日制',
    text: '週休3日制'
  }
]