<template>
  <div>
    <EvaluationInputComponent
      :target="target"
    />
  </div>
</template>
<script>

import EvaluationInputComponent from '../components/EvaluationInputComponent.vue'

export default {
  components: {
    EvaluationInputComponent
  },

  props: ['target'],

  data() {
    return {
    }
  },

  created() {
    if (this.$route.query.id && (!this.target || !this.target.year)) {
      // 一覧に遷移する
      this.$router.push({ name: 'EvaluationList' })
    }
  },
}
</script>

<style scoped>
</style>