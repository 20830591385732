<template>
  <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
    <div class="overflow-hidden rounded main-height">
      <div class="main-height overflow-auto">
        <div class="flex justify-between">
          <div class="font-bold title mb-3">
            社員面接専用 ChatworkルームID 編集
          </div>
          <div class="py-1 px-2 mt-auto cursor-pointer" @click="add()">
            <Icon
              iconName="PlusCircle"
              class="w-8 h-8 text-blue-800"
            />
          </div>
        </div>
        <table class="overflow-auto main table-auto mb-3">
          <thead class="main sticky top-0 bg-white">
            <tr v-if="list && list.length" class="border border-gray-200">
              <th v-for="(c, index) in columns" :key="index" scope="col" class="pl-4 pr-1 py-3 text-xs font-medium text-gray-500 tracking-wider bg-colu text-left" :class="c.class">
                {{c.title}}
              </th>
            </tr>
          </thead>

          <tr v-for="(row, i) in list" :key="i" class="border border-gray-200 tracking-wider hover-color">

            <!-- 種別 -->
            <td class="pl-3 pr-1 py-0.5 text-gray-500">
              <select
                name="category"
                v-model="row.category"
                class="focus:ring-indigo-500 focus:border-indigo-500 block border-gray-300 w-full text-sm rounded"
                @change="edit('category', row, $event)"
              >
                <option
                  v-for="c in categoryList" :key='c.name'
                  :value="c.name"
                  class="bg-white">
                  {{ c.name }}
                </option>
              </select>
            </td>

            <!-- 部署名 -->
            <td class="pl-3 pr-1 py-0.5 text-sm text-gray-500">
              <select
                name="department"
                v-model="row.department"
                class="focus:ring-indigo-500 focus:border-indigo-500 block border-gray-300 w-full text-sm rounded"
                @change="edit('department', row, $event)"
              >
                <option
                  v-for="c in departmentList" :key='c.name'
                  :value="c.name"
                  class="bg-white">
                  {{ c.name }}
                </option>
              </select>
            </td>

            <!-- ルームID -->
            <td class="pl-3 pr-1 py-0.5 text-gray-500">
              <input
                type="number"
                class="border border-gray-300 rounded-md focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900 w-32 py-1.5"
                v-model="row.roomId"
                @input="edit('roomId', row)"
              />
            </td>

            <!-- トークン -->
            <td class="pl-3 pr-1 py-0.5 text-gray-500">
              <input
                type="text"
                class="border border-gray-300 rounded-md focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900 text-xs w-60 py-2.5" 
                v-model="row.apiToken"
                @input="edit('apiToken', row)"
              />
            </td>

            <td class="px-1 py-0.5 text-center text-sm font-medium border-gray">
              <div class="flex justify-center">
                <PrimaryButton text="保存" size="normal" class="w-16 p-1" :disabled="!row.editFlg" :class="!row.editFlg?'bg-gray-300':'bg-blue-800'" @click="save(row)" />
                <PrimaryButton text="削除" size="normal" class="ml-2 w-16 p-1 bg-red-700" @click="remove(row, i)" />
              </div>
            </td>
          </tr>
        </table>

      </div>
      <div v-if="!loading && (!list || !list.length)" class="flex justify-center items-center text-gray-500 font-bold nothing">
        <p class="text-gray-400 font-bold">対象データがありません</p>
      </div>
      <div class="text-transparent">{{reload}}</div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import Icon from '@components/Icon.vue'
import * as requestPostManager from '@managers/requestPostManager'
import * as utils from '@libs/utils'
import * as dialogs from '@libs/dialogs'
import * as logManager from '@managers/logManager'
import * as backend from '@libs/backend'

export default {
  components: {
    Modal,
    PrimaryButton,
    Icon
  },

  props: ['open', 'fromMenu'],

  data() {
    return {
      // ローディング
      loading: false,
      // カラム
      columns: [
        {title: '種別'},
        {title: '依頼先部署名'},
        {title: 'ルームID'},
        {title: 'APIトークン'},
        {title: ''},
      ],
      // リスト
      list: [
        { editFlg: false, category: '', department: '', roomId: null }
      ],
      // 種別リスト
      categoryList: [
        { name: '採用候補', categoryId: 1 },
        { name: '採用', categoryId: 2 },
        { name: '不採用', categoryId: 3 }
      ],
      // 部署名リスト
      departmentList: [],
      reload: 0
    }
  },

  computed: {
  },

  watch: {
    open() {
      if (this.open) {
        this.getData()
      }
    }
  },

  methods: {
    /**
     * 初期にリセット
     */
    closed() {
      // リスト
      this.list = [
        { editFlg: false, category: '', department: '', roomId: null }
      ]
      this.loading = false
      this.$emit('close')
    },

    /**
     * リスト取得
     */
    async getData() {
      // マスタメニューから遷移ではない場合
      if (!this.fromMenu) {
        // 権限チェック
        const userInfo = await backend.searchData('user/getDepartment', { user: this.$store.getters.user.user_name })
        // チャットマスタ編集権限
        const chatMstAuth = utils.isRegularStaffInterviewChatMstEdit(this.$store.getters.user.id, userInfo)
        if (!chatMstAuth) {
          await dialogs.showErrorDialog('権限がないため表示できません', '面接権限がある人、人事部、もしくは限られた人しか表示できません。')
          this.closed()
          return
        }
      }
      this.loading = true
      // 社員面接専用chatworkルームID取得
      this.list = await requestPostManager.getInterviewsChatRoomID(this)
      // 部署データ取得
      this.departmentList = this.$store.getters.departmentList
      this.loading = false
    },

    /**
     * 編集ボタンイベント
     * @param item 対象項目名
     * @param row 編集対象データ
     */
    edit(item, row) {
      if (item == 'category') {
        const e = this.categoryList.find((c) => {
          return c.name == row.category
        })
        row.categoryId = e.categoryId
      }
      row.editFlg = true
      this.reload++
    },

    /**
     * 保存
     * @param row 対象データ
     */
    async save(row) {
      if (!row.category) {
        await dialogs.showErrorDialog('種別', '未選択です。種別を選択してください。')
        return
      }
      if (!row.department) {
        await dialogs.showErrorDialog('部署名', '未選択です。部署名を選択してください。')
        return
      }
      if (!row.roomId) {
        await dialogs.showErrorDialog('ルームID', '未入力です。ルームIDを入力してください。')
        return
      }

      // ダブりがないかチェック
      const double = this.list.filter((l) => {
        return l.category == row.category && l.department == row.department
      })
      if (double.length > 1) {
        await dialogs.showErrorDialog('重複のため保存不可', 'すでに同じ種別・部署のデータがあります。')
        return
      }

      // 同じルームIDがないかチェック
      const room = this.list.filter((r) => {
        return r.roomId == row.roomId
      })
      if (room.length > 1) {
        const dia = await dialogs.showConfirmDialog('ルームID重複あり', 'すでに同じルームIDが登録されていますが、よろしいでしょうか？')
        if (dia != 'YES') {
          return
        }
      }
      const saveData = utils.clone(row)
      delete saveData.editFlg
      const res = await requestPostManager.saveInterviewsChatRoomID(this, saveData)
      if (res) {
        await dialogs.showSuccessDialog('保存しました')
        row.editFlg = false
        this.reload++
        // ログ登録
        let newFlag = true
        if (row._id) {
          newFlag = false
        }
        logManager.recordingByRegist(this, newFlag, '社員面接用ChatworkルームID', `${row.department} の『${row.category}』ルームID`)
      }
    },

    /**
     * 削除
     * @param row 対象データ
     * @param i インデックス
     */
    async remove(row, i) {
      if (row._id) {
        const res = await dialogs.showConfirmDialog('削除してよろしいですか？', `${row.department} の『${row.category}』を削除します。`)
        if (res != 'YES') {
          return
        }
        await requestPostManager.deleteTask(this, row)
        await dialogs.showSuccessDialog('削除しました')
      }
      this.list.splice(i, 1)
      // ログ登録
        logManager.recording(this, logManager.Loglevel.INFO, '社員面接用ChatworkルームID', '削除', `${row.department} の『${row.category}』を削除しました。`)
    },

    /**
     * 追加ボタン
     */
    add() {
      this.list.push({ editFlg: false, category: '', department: '', roomId: null, apiToken: '12e6247b22aa5826875a69997587ea01' })
    }
  }
}
</script>
<style scoped>
.main-height {
  height: 85vh;
}

.h-95vh {
  height: 95vh;
}

.h-93vh {
  height: 93vh;
}

.main {
  min-width: 700px;
}

.b-top {
  border-top: 1px solid rgb(228, 227, 227);
}

.bg-colu {
  background: #edecf7;
}

.hover-color:hover {
  background: #edecf7 !important;
}

.title {
  color: #928ec7;
}
</style>