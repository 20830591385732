<template>
  <div>
      <!-- <div v-if="processing" class="flex justify-center items-center loading-dialog2">
        <p class="text-gray-600 font-bold animate-pulse" style="font-size: 50px">{{ processingText }}</p>
      </div> -->
      <template>
        <div class="overflow-auto" :class="isMobileDevice?'mobile-content':'pc-content'">
          <div v-if="!processing" class="ml-auto pr-1 pl-5 pb-5 cursor-pointer w-fit" @click="previous()">
            <Icon slot="before" iconName="X" iconType="outline" :strokeWidth="2" class="h-8 w-8 ml-auto" :clickable="true" />
          </div>
          <LoadingIcon v-else slot="before" class="h-8 w-8 ml-auto mr-1 mb-5 text-gray-700" />
          <div class="text-lg text-blue-800 font-bold flex">
            <div class="ml-3">報告書</div>
            <!-- <PrimaryButton text="履歴" size="normal" class="ml-auto mr-1 bg-orange" @click="openHistory()" /> -->
          </div>

          <div class="mt-5 ml-12 mr-12 mb-12">

            <div class="w-11/12 mr-auto mt-5 info-group">
              <div class="text-gray-600 font-bold mr-auto info1">
                得意先 : {{ list.clientName1 }}
                <div v-if="list.clientName2 && list.clientName2 != ''" class="font-normal ml-20 text-xs text-gray">（{{ list.clientName2 }}）</div>
              </div>
              <div class="text-gray-600 font-bold info2">
                物件名 : {{ list.siteName }}
              </div>
            </div>
            <div class="w-11/12 mr-auto mt-5 info-group">
              <div class="text-gray-600 font-bold info1">
                巡回日 : {{ list.today }}
              </div>
              <div class="text-gray-600 font-bold info2">
                巡回時間 : <span v-if="start!=''">{{ start }} ～ {{ end }}</span>
              </div>
            </div>
            <div class="w-11/12 mr-auto mt-5 info-group">
              <div class="text-gray-600 font-bold info1">
                点検者 : {{ list.staff }}
              </div>
              <div class="text-gray-600 font-bold info2">
                位置情報 : {{ location }}
              </div>
            </div>

            <div v-if="canEdit" class="flex mt-5 ml-auto mr-auto w-full">
              <PrimaryButton text="開始" size="normal" class="w-full mr-1" :class="start!=''?'bg-gray-400':''" v-if="start==''" @click="startBtn()" :disabled="processing" >
                <LoadingIcon v-if="processing" slot="before" class="h-4 w-4" />
              </PrimaryButton>
              <PrimaryButton text="キャンセル" size="normal" class="w-full mr-1 bg-red-400 hover:bg-red-500" v-if="start!=''" @click="cancelBtn()" :disabled="processing" >
                <LoadingIcon v-if="processing" slot="before" class="h-4 w-4" />
              </PrimaryButton>
              <PrimaryButton text="終了" size="normal" class="w-full ml-1" :class="start==''||end!=''?'bg-gray-400':''" :disabled="start==''||end!=''||processing" @click="endBtn()" >
                <LoadingIcon v-if="processing" slot="before" class="h-4 w-4" />
              </PrimaryButton>
            </div>

            <div v-if="completeBtn" class="flex mt-5 ml-auto mr-auto w-full">
              <PrimaryButton text="完了を再表示" size="normal" class="w-full mr-1 bg-teal-50 hover:bg-teal-50 edit text-teal-700" @click="reDisplay()" />
            </div>

            <div v-if="undoBtn" class="flex mt-5 ml-auto mr-auto w-full">
              <PrimaryButton text="完了を非表示に戻す" size="normal" class="w-full mr-1 bg-yellow-50 hover:bg-yellow-50 edit text-yellow-700 border-yellow-600" @click="undo()" />
            </div>

            <!-- <div v-if="canEdit" class="flex justify-end mt-2">
              <div class="flex">
                <span class="text-gray-500 font-bold text-xs mr-1 ml-auto my-auto">非表示の「該当なし」を表示</span>
                <Toggle v-model="toggleNA" size="small"/>
              </div>
            </div> -->
          </div>

          <div v-for="(row, i) in checkList.items" :key="'r'+i" class="w-11/12 ml-auto mr-auto">
            <div v-if="(!row.complete || !completeBtn) && !row.disabled && !toggleNA">
              
              <div class="flex">    
                <!-- No -->
                <div class="w-8 text-blue-700 font-bold">
                  <span>{{ row.sortNo }}.</span>
                </div>

                <div v-if="(!row.complete || !completeBtn) && !row.disabled && !toggleNA" class="mb-1">
                  <div class="ml-2 flex" :class="canEdit || row.value=='N/A'?'h-9':''">
                    <!-- 該当なし -->
                    <PrimaryButton v-if="canEdit" text="該当なし" size="normal" class="w-97px pl-0 pr-0" :class="row.value!='N/A'?'bg-gray-400 mr-2':'bg-teal-700 mr-2'" :disabled="start==''||row.disabled" @click="setPoint('N/A', i)" />
                    <PrimaryButton v-if="!canEdit && row.value=='N/A'" text="該当なし" size="normal" class="w-97px py-1 pl-0 pr-0" :class="row.value!='N/A'?'bg-gray-400 mr-2':'bg-teal-700 mr-2'" :disabled="true" />
                    <!-- カメラ -->
                    <div v-if="!row.disabled && row.value!='N/A' && canEdit && start!=''" class="flex">
                      <div class="file-btn rounded w-70px h-full mr-1">
                        <PrimaryButton :buttonContents="'要'" class="bg-blue-700 hover:bg-darkgreen h-full w-70px p-1">
                          <Icon slot="before" iconName="FolderDownload" iconType="outline" :strokeWidth="2" class="text-white h-7 w-8 bg-blue-700 hover:bg-darkgreen" />
                        </PrimaryButton>
                        <input class="file-type w-70px bg-darkgreen" type="file" accept="image/*" @change="imageToBase64(i, $event, true)" multiple />
                      </div>
                      <div class="file-btn rounded w-70px bg-darkgreen h-full">
                        <PrimaryButton :buttonContents="'要'" class="bg-darkgreen hover:bg-darkgreen h-full w-70px">
                          <Icon slot="before" iconName="Camera" iconType="solid" :strokeWidth="2" class="text-white h-5 w-8 bg-darkgreen hover:bg-darkgreen" />
                        </PrimaryButton>
                        <input class="file-type w-70px bg-darkgreen" type="file" accept="image/*" capture="environment" @change="imageToBase64(i, $event)" multiple />
                      </div>
                    </div>
                    <!-- 非表示ボタン -->
                    <!-- <PrimaryButton v-if="canEdit && row.value=='N/A' && row.sortNo > 0" text="非表示" size="bg-dark-green" class="rounded text-xs text-white font-bold p-5p w-70px" @click="disabledNA(i)" /> -->
                    <!-- 完了ボタン -->
                    <PrimaryButton v-if="canEdit" :text="processing?'':'完了'" size="bg-blue-50" class="comp-btn text-blue-800 border border-blue-800 rounded font-bold p-6p w-16 ml-3" @click="complete(i)" :disabled="processing" >
                      <LoadingIcon v-if="processing" slot="before" class="h-4 w-4 text-blue-600" />
                    </PrimaryButton>
                  </div>
                </div>
              </div>
              
              
              <div class="flex">
                <!-- No -->
                <!-- <div class="w-8 text-blue-700 font-bold">
                  <span>{{ row.sortNo }}.</span>
                </div> -->

                <!-- 区分 -->
                <div v-if="canEdit">
                  
                  <div class="flex">
                    <WmsSuggestInput
                      id="classific"
                      :name="'classification' + i"
                      :embed="true"
                      displayField="val"
                      :dynamic="false"
                      :incrementalSearch="false"
                      :selectionItems="classificationList"
                      class="embed border border-gray-300"
                      v-model="row.classification"
                      placeholder="入力必須です"
                      :clearable="true"
                      @selected="temporarilyRegister('item', row, $event, i, 'classification')"
                    />
                <!-- </div> -->
                  </div>
                </div>
                <!-- 編集不可 -->
                <div v-else>
                  <!-- 清掃箇所 -->
                  <span class="text-blue-700 font-bold ">
                    {{ row.classification }}
                  </span>
                </div>
              </div>

              <div class="flex">
                <!-- 状況 -->
                <div v-if="canEdit" class="mb-1 w-full" :class="row.value == 'N/A'?'mb-6':''">
                  <WmsSuggestInput
                    :name="'item' + i"
                    :embed="true"
                    displayField="val"
                    :dynamic="false"
                    :incrementalSearch="false"
                    :selectionItems="itemList"
                    class="embed border border-gray-300"
                    v-model="row.item"
                    placeholder="入力必須です"
                    :clearable="true"
                    @selected="temporarilyRegister('item', row, $event, i, 'item')"
                  />
                </div>
                <div v-else class="mb-1" :class="row.value=='N/A'?'mb-6':''">
                  {{ row.item }}
                </div>
              </div>
            </div>

            <!-- 非表示の該当なしを表示した場合 -->
            <div v-if="row.value=='N/A' && row.disabled && toggleNA">
              <!-- No -->
              <div class="flex">
                <div class="w-8 text-blue-700 font-bold">{{ row.sortNo }}.</div>
                <div class="text-blue-700 font-bold ">{{ row.classification }}</div>
              </div>
              <!-- 項目 -->
              <div class="ml-8 mb-1">
                {{ row.item }}
              </div>
            </div>
            
            <!-- 非表示の該当なしを表示した場合 -->
            <div v-if="row.value=='N/A' && row.disabled && toggleNA">
              <PrimaryButton text="表示" size="bg-dark-green" class="rounded text-xs text-white font-bold p-5p mb-5 w-1/3 h-10 ml-8" @click="enableNA(i)" />
            </div>

            <div v-if="(!row.complete || !completeBtn) && !row.disabled && row.value != 'N/A' && !toggleNA">
              <!-- 添付画像 -->
              <div class="mt-3 flex">
                <div v-for="(p, j) in checkList.items[i].photo" :key="'p'+j" class="flex mr-3">
                  <div>
                    <a :href="fullImage" :data-lightbox="i * 100 + j" @click="clickImage(checkList.items[i].sortNo, i, j)">
                      <img :src="p" width="300">
                    </a>
                    <div class="flex">
                      <!-- 左へボタン -->
                      <Icon
                        v-if="j!=0&&checkList.items[i].photo.length!=1&&canEdit"
                        iconName="ArrowNarrowLeft" 
                        :clickable="true" 
                        class="mt-0.5 cursor-pointer text-gray-600 border rounded w-11 py-1 px-3 border-gray-400 bg-gray-50"
                        @click="toLeft(i, j)"
                      />
                      <!-- 右へボタン -->
                      <Icon
                        v-if="j!=checkList.items[i].photo.length-1&&checkList.items[i].photo.length!=1&&canEdit"
                        iconName="ArrowNarrowRight" 
                        :clickable="true" 
                        class="mt-0.5 cursor-pointer text-gray-600 border rounded w-11 py-1 px-3 border-gray-400 bg-gray-50"
                        @click="toRight(i, j)"
                      />
                    </div>
                    
                  </div>
                  <!-- 削除 -->
                  <div @click="deletePhoto(i, j)" v-if="p && p != '' && canEdit" class="ml-0.5 mb-auto mt-1">
                    <TrashIcon class="text-gray-400 cursor-pointer hover:text-gray-500 active:text-gray-600 w-7 h-7" />
                  </div>
                </div>
              </div>
              <div class="text-transparent">{{reload}}</div>
            </div>
          </div>
        </div>
      </template>
  </div>
</template>

<script>
import Icon from '@components/Icon.vue'
import { TrashIcon } from '@vue-hero-icons/solid'
import PrimaryButton from '@components/PrimaryButton.vue'
// import Toggle from '@components/Toggle.vue'
// import CheckListHistoryModal from '../components/CheckListHistoryModal.vue'
// import WmsTextInput from '@wmscomponents/WmsTextInput.vue'
import WmsSuggestInput from '@wmscomponents/WmsSuggestInput.vue'
import LoadingIcon from '@assets/loading-circle.svg' 
// import NumericTextInput from '@components/NumericTextInput.vue'
import * as backend from '@libs/backend'
import * as dialogs from '@libs/dialogs'
import * as utils from '@libs/utils'
import moment from 'moment'
import * as logManager from '@managers/logManager'

const ITEMS = {
    // 区分
    classification: '',
    // 内容
    item: '',
    // 該当なし履歴
    valueNA: false,
    // 該当なし非表示
    disabled: false,
    // 写真
    photo: [],
    // 完了ボタン
    complete: false,
  }

export default {
  components: {
    Icon,
    TrashIcon,
    PrimaryButton,
    LoadingIcon,
    // Toggle,
    // CheckListHistoryModal,
    // WmsTextInput,
    WmsSuggestInput
    // NumericTextInput
  },

  props: {
    // 区分名
    typeName: {
      type: String,
      default: null
    },
    // 区分（1：品質巡回、2：巡回清掃、3：定期清掃）
    type: {
      type: Number,
      default: null
    },
    // 親画面（予定一覧）
    routerName: {
      type: String,
      default: null
    },
    // 物件位置情報
    siteLocation: {
      type: Object
    },
  },

  data() {
    return {
      // 表示対象予定詳細情報
      target: {},
      // 表示データ
      list: [],
      // 開始時間
      start: '',
      // 終了時間
      end: '',
      // 位置情報
      location: '',
      // チェック項目
      checkList: {
        items: [
          utils.clone(ITEMS)
        ],
        memo: ''
      },
      // 並べ替え
      // sortable: false,
      // 履歴モーダル
      historyModal: false,
      openHistoryModal: 0,
      // 編集できるか
      canEdit: true,
      // 備考の大きさ
      memoCount: 5,
      // 処理中
      processing: false,
      processingText: 'Loading...',
      // 写真更新
      reload: 0,
      // 完了ボタンが押されたか
      completeBtn: false,
      // 非表示の該当なしトグル
      toggleNA: false,
      // 添付画像 オリジナルデータ
      fullImage: '',
      // 全添付画像 オリジナルデータ
      fullImages: null,
      // 完了を非表示ボタン活性
      undoBtn: false,
      // テンプレートID
      tempId: null,
      // クレーム対応(定期清掃にクレーム対応はない)
      claim: false,
      // 清掃箇所リスト
      classificationList: [
        { val: '建物' },
        { val: '敷地内' },
        { val: '廊下' },
        { val: '階段' },
        { val: 'ゴミ置き場' },
        { val: '駐輪場' },
        { val: '駐車場' },
        { val: '備品類' }
      ],
      // 状況リスト
      itemList: [
        { val: '掃き清掃致しました。' },
        { val: '拭き清掃致しました。' },
        { val: 'モップ掛け致しました。' },
        { val: '水洗い致しました。' },
        { val: '除草剤致しました。' },
        { val: '除塵致しました。' },
        { val: '放置物があります。' },
        { val: 'チラシ回収致しました。' },
        { val: '点灯確認致しました。' }
      ]
    }
  },

  async created() {
    // 巡回清掃にクレーム対応はなし
    this.claim = false
    // booleanでない場合
    if (typeof this.$route.query.claim != 'boolean') {
      this.previous()
    }
    await this.getTarget()
  },

  computed: {
    /**
     * スマホ
     */
    isMobileDevice() {
      return utils.deviceInfo.isMobile
    },
  },

  methods: {
    /**
     * 表示するデータ取得
     */
    async getTarget() {
      if (this.$route.query.scheduleId) {
        this.processing = true
        this.processingText = 'Loading...'

        // スケジュールIDに紐づくデータを取得
        let schedule = await backend.searchData('patrolSchedule/getById', { id: this.$route.query.scheduleId })

        if (schedule.data.data && schedule.data.data.length) {
          this.target = schedule.data.data[0]
        } else {
          this.processing = false
          await dialogs.showErrorDialog('エラー', '対象データがありません。')
          return
        }
        this.processing = false

        this.list = this.target
        this.list.today = moment().format('YYYY/MM/DD')

        // 巡回清掃済み
        if (this.target.completionDate && this.target.completionDate != '') {
          this.canEdit = false
          this.memoCount = 1

          // データ取得
          this.processing = true
          this.processingText = 'Loading...'
          const compR = await backend.searchData('patrolResult/getByPatrolScheduleId', { patrolScheduleId: this.target.id, clientCode: this.target.clientCode, siteCode: this.target.siteCode, scheduledDate: moment(this.target.scheduledDate).format('YYYY-MM-DD'), type: this.type, claim: this.claim })
          if (compR.data && compR.data.data) {
            this.setData(compR.data.data)
            // 添付画像 取得
            this.getfullImages()
          }
          this.processing = false

        // 巡回清掃まだ
        } else {
          this.canEdit = true
          // 途中保存があり、途中から行う場合
          if (await this.temporary()) {
            return
          }
          
          await this.initCheck()
        }
        this.processing = false
      }
    },

    /**
     * 保存している結果をセット
     */
    setData(r) {
      if (r.result.length) {
        let result = r.result[0]
        this.start = result.startTime
        if (result.endTime) {
          this.end = result.endTime
        } else {
          this.end = ''
        }
        if (result.completionDate) {
          this.list.today = moment(result.completionDate).format('YYYY/MM/DD')
        }
        this.location = result.location
        this.point = result.getPoint
        this.totalPoint = result.totalPoint
      }
      if (r.item) {
        this.checkList.items = r.item
      }
    },

    /**
     * 途中保存を取得
     */
    async temporary() {
      const temporaryS =  await backend.searchData('patrolTemporarySaving/isSaving', { patrolScheduleId: this.target.id })
      if (temporaryS && temporaryS.data && temporaryS.data.data.length) {
        const dialogR = await dialogs.showConfirmDialog('途中保存データあり', '途中保存しているデータがあります。\r\n途中から再開する場合➡「途中から」\r\n最初からやり直す➡「最初から」\r\nを押してください。', '途中から', '最初から')
        
        // 途中から再開する場合
        if (dialogR == 'YES') {
          this.processing = true
          this.processingText = 'Loading...'
          let temporaryR = []

          let param = { clientCode: this.target.clientCode, siteCode: this.target.siteCode, patrolScheduleId: this.target.id, type: this.type, claim: this.claim }

          temporaryR = await backend.searchData('patrolTemporarySaving/getByPatrolScheduleId', param)
          
          // 完了を表示ボタンを出す
          if (temporaryR.data.data.item && temporaryR.data.data.item.length) {
            for (let i = 0; i < temporaryR.data.data.item.length; i++) {
              const it = temporaryR.data.data.item[i]
              if (it.complete) {
                this.completeBtn = true
                break
              }
            }
          }
          
          this.setData(temporaryR.data.data)
          this.processing = false

          // 添付画像 取得
          this.getfullImages()

          return true
        
        // 最初からやり直す場合
        } else {
          // データを削除
          await backend.deleteDataByKey('patrolResult/deleteByPatrolScheduleId', { key: this.target.id, sub: this.$route.query.sub })
          return false
        }
      }
      return false
    },

    /**
     * 点検前の点検表セット
     */
    async initCheck() {
      this.processing = true
      this.processingText = 'Loading...'

      // 物件コードが変更になっていないか確認
      let isChangeCode = await backend.searchData('checkListHistory/isChangeCode', { siteCode: this.target.siteCode, type: this.type, claim: this.claim, siteAddress: this.target.siteAddress, siteName: this.target.siteName })
      if (isChangeCode.data && isChangeCode.data.data && isChangeCode.data.data.result) {
        this.processing = false
        const inf = isChangeCode.data.data.info
        const msg = `
          前回完了日：${inf.completionDate}
          物件名：${inf.siteName}
          住所：${inf.siteAddress}
        `
        const chagneCode = await dialogs.showConfirmDialog('物件コードが変わった可能性があります。', '過去の物件コードと新しい物件コードを一致させますか？\r\n新しい物件コードと一致させると過去のデータが引き継がれます。\r\n\r\n【同じと思われる過去のデータ】' + msg)
        this.processing = true
        // 過去の物件コードを変更する
        if (chagneCode == 'YES') {
          const updateRes = await backend.postData('checkListHistory/changingCode', { newSiteCode: this.target.siteCode, oldSiteCode: inf.siteCode, type: this.type, claim: this.claim, id: this.target.id })
          if (updateRes && updateRes.status == 200) {
            //ログ出力
            logManager.recording(this, logManager.Loglevel.INFO, this.typeName, '物件コード変更', this.typeName + '（物件名：' + inf.siteName + '、物件コード：' + inf.siteCode + '、完了日：'+ inf.completionDate + '以前全て、予定ID：'+ inf.id +'以前全て）を（物件コード：' + this.target.siteCode + '）に変更しました。') 
          } else {
            alert('エラーが発生しました。')
          }
        }
      }

      // 点検表
      let cheR = await backend.searchData('checkListByClient/getByClientCode', { clientCode: this.target.clientCode, siteCode: this.target.siteCode, type: this.type })
      if (cheR.data && cheR.data.data && cheR.data.data.length) {
        this.checkList.items = cheR.data.data
      }
      this.processing = false
    },

    /**
     * 初期化
     */
    initData() {
      // 表示データ
      this.list = []
      // 開始時間
      this.start = ''
      // 終了時間
      this.end = ''
      // チェック項目
      this.checkList = {
        items: [
          utils.clone(ITEMS)
        ],
        memo: ''
      }
      // 並べ替え
      // this.sortable = false
      // 編集できるか
      this.canEdit = true
      // 備考の大きさ
      this.memoCount = 5
      // 処理中
      this.processing = false
      // 完了ボタン
      this.completeBtn = false
      // 非表示の該当なしトグル
      this.toggleNA = false
      // 保存したデータ
      // this.registeredItem = []
      // 添付画像 オリジナルデータ
      this.fullImage = ''
      // 全添付画像 オリジナルデータ
      this.fullImages = null
      // 完了を非表示ボタン活性
      this.undoBtn = false
    },

    /**
     * 開始ボタン
     */
    async startBtn() {
      // 現在地と物件の位置を比較
      const user = this.$store.getters.user.user_name
      const userId = this.$store.getters.user.id
      this.location = await utils.patrolLocation({ ...this.target, ...this.siteLocation, user, userId })
      // 調査後に以下をリリース（ダイアログメッセージの内容も要変更…utils内）
      // if (!this.location) {
      //   return
      // }

      this.list.start = moment().format('HH:mm')
      this.start = moment().format('HH:mm')
      // 一時保存
      this.temporarilyRegister('start', null)
    },

    /**
     * 完了したデータを削除
     */
    async deleteCompData() {
      // 1度でも完了ボタンを押していたら確認ダイアログ出す
      if (this.completeBtn || this.undoBtn) {
        const canscelR = await dialogs.showConfirmDialog('キャンセル', '完了しているデータも元に戻ります。よろしいでしょうか？')
        if (canscelR != 'YES') {
          return false

        // 途中保存データを削除
        } else {
          await backend.deleteDataByKey('patrolResult/deleteByPatrolScheduleId', { key: this.target.id, sub: this.$route.query.sub })
          this.initData()
          this.list = this.target
          this.list.today = moment().format('YYYY/MM/DD')
          await this.initCheck()
          return false
        }
      }
      // まだ1度も完了した項目がなければ、値のみ空にする
      return true
    },

    /**
     * キャンセルボタン
     */
    async cancelBtn() {
      let cancelR = await this.deleteCompData()
      if (!cancelR) {
        return
      }
      
      this.start = ''
      this.completeBtn = false
      this.checkList.items.forEach(i =>{
        if (!i.valueNA) {
          i.disabled = false
        }
        i.complete = false
        i.photo = []
      })
    },

    /**
     * 終了ボタン
     */
    async endBtn() {
      // 変更後に再保存していないもの
      let dirty = []
      // すべての値が選択されているか確認
      for (let i = 0; i < this.checkList.items.length; i++) {
        const ci = this.checkList.items[i]
        // 完了済みか確認
        if (!ci.complete && !ci.disabled) {
          await dialogs.showErrorDialog('終了できません', `【${ci.classification}】の完了ボタンを押してください。`)
          return
        }

        let msg = `【${ci.classification}】の`
        if (!ci.classification) {
          msg = `No.${ci.sortNo} の`
        }

        if (!await this.validate(ci, msg)) {
          return
        }
      }
      this.end = moment().format('HH:mm')
      
      await this.allRegister(dirty)
    },

    /**
     * 保存時に必要なデータを生成
     */
    setSaveData() {
      return {
        patrolScheduleId: this.target.id,
        clientCode: this.target.clientCode,
        siteCode: this.target.siteCode,
        completionDate: this.list.today,
        startTime: this.start,
        endTime: this.end,
        location: this.location,
        insertUser: this.$store.getters.user.id
      }
    },

    /**
     * 終了ボタン押下時の最終保存
     */
    async allRegister(dirty) {
      this.processing = true
      this.processingText = '保存中...'
      
      try {
        let save = this.setSaveData()
        // 巡回結果保存
        await backend.postData('patrolResult/save', { save, item: this.checkList.items, note: [], memo: '', dirty, key: this.target.id, type: this.type, claim: this.claim })
        
        this.processing = false
      } catch (err) {
        alert("エラーが発生しました。")
        console.log('エラーが発生しました。')
        console.log(err)
        this.processing = false
        // 再度、終了ボタンが押せるようにする
        this.end = ''
      }

      //ログ出力
      logManager.recording(this, logManager.Loglevel.INFO, this.typeName, '完了', this.typeName + '（期限日：'+ moment(this.list.termDate).format('YYYY-MM-DD')  + '、担当者：'+ this.list.staff + '、物件名：'+ this.list.siteName +'）を完了しました。')  
      this.previous()
      this.initData()
    },

    /**
     * 一時保存処理
     * @param itemName 変更になった項目
     *   （開始ボタン：start、項目：item、写真：photo、写真削除：deletePhoto)
     * @param val 登録内容
     */
    temporarilyRegister(itemName, val, event, i, tar) {
      try {
        let save = this.setSaveData()
        let item = null
        let photo = null
        let deletePhoto = null

        // 項目
        if (itemName == 'item') {
          item = val
          // 清掃中の場合
          if (event) {
            item = utils.clone(this.checkList.items[i])
            item[tar] = event.val
          }
        // 写真
        } else if (itemName == 'photo') {
          photo = val
        // 写真削除
        } else if (itemName == 'deletePhoto') {
          deletePhoto = val
        }
        backend.postData('patrolResult/temporarily', { save, item, photo, deletePhoto, type: this.type })
      } catch (err) {
        alert("エラーが発生しました。")
        console.log('エラーが発生しました。')
        console.log(err)
        this.processing = false
      }
    },

    /**
     * 各項目の完了ごとの登録処理
     * @param i 対象項目インデックス
     */
    async register(i) {
      this.processing = true
      this.processingText = '保存中...'

      try {
        let save = this.setSaveData()

        // 完了した対象の項目を保存(値・指摘事項・写真)
        await backend.postData('patrolResult/itemAndNoteSave', { save, item: this.checkList.items[i], memo: '' })
        
        this.processing = false
      } catch (err) {
        alert("エラーが発生しました。")
        console.log('エラーが発生しました。')
        console.log(err)
        this.processing = false
        this.checkList.items[i].complete = false
      }
    },

    /**
     * 写真添付Base64に変換
     * @param i index
     * @param isfolder フォルダボタン押下
     */
    async imageToBase64 (i, event, isfolder) {
      // 選択されたファイルの取得
      const file = event.target.files
      if (!this.checkList.items[i].photo) {
        this.checkList.items[i].photo = []
      }
      // Base64に変換する
      for (let j = 0; j < file.length; j++) {
        const reader = new FileReader()
        const f = file[j]

        // 撮影日時取得
        // if (isfolder) {
        //   const dt = await utils.getEXIFDateTimeOriginal(f)
        //   const checkDate = utils.checkPhotoDate(dt)
        //   if (!checkDate) {
        //     return
        //   }
        // }

        reader.readAsDataURL(f)
        // 変換
        const compressedImage = await new Promise((resolve) => {
          reader.onload = () => {
            const img = new Image();
            img.src = reader.result;
            
            img.onload = () => {
              const compressedDataUrl = this.compressImage(img, isfolder)
              resolve(compressedDataUrl)
            }
          }
        })
        this.checkList.items[i].photo.push(compressedImage)
        this.reload++
        this.temporarilyRegister('photo', this.checkList.items[i])
      }
    },

    /**
     * 画像圧縮
     * @param {dataURL} img 
     * @param dt 写真撮影日時
     * @param isfolder フォルダボタン押下
     */
      compressImage(img, isfolder) {
      // 画像のサイズを変更するためにリサイズ処理
      const maxWidth = 854 // リサイズ後の最大幅
      let width = img.width
      let height = img.height

      if (width > maxWidth) {
        height *= maxWidth / width
        width = maxWidth
      }
      // 新しいキャンバスを作成
      const canvas = document.createElement('canvas')
      canvas.width = width
      canvas.height = height

      // キャンバスに描画するためのコンテキストを取得
      const ctx = canvas.getContext('2d')
      // 画像をキャンバスに描画せずに直接リサイズ
      ctx.drawImage(img, 0, 0, width, height)
      // 写真撮影日時を写真に描画
      if (!isfolder) {
        utils.setDateToImg(null, canvas, ctx, false)
      }
      // キャンバスの画像を再度DataURLに変換して圧縮率を下げる
      const compressedDataUrl = canvas.toDataURL('image/jpeg', 1.0) // 圧縮率を設定（0.7は例です）

      return compressedDataUrl
    },

    /**
     * 写真削除
     * @param i 項目インデックス
     * @param j 写真インデックス
     */
    deletePhoto(i, j) {
      this.checkList.items[i].photo.splice(j, 1)
      this.reload++

      // 一時保存
      this.temporarilyRegister('deletePhoto', { item: this.checkList.items[i], deleteIdx: j })
    },

    /**
     * 該当なしボタン
     * @param value 値
     * @param idx インデックス
     */
    setPoint(value, idx) {
      if (this.checkList.items[idx].value == value) {
        this.checkList.items[idx].value = null
      } else {
        this.checkList.items[idx].value = value
      }
      this.reload++
    },

    /**
     * 非表示ボタン
     * @param i 対象項目
     */
    // async disabledNA(i) {
    //   let txt = '次回からもこの項目は非表示にします。\r\n'
    //   if (this.checkList.items[i].sortNo < 0) {
    //     txt = ''
    //   }
    //   const dr = await dialogs.showConfirmDialog('非表示にします', txt + '設定変更する場合は、【非表示の「該当なし」を表示】ボタンを押してください。')
    //   if (dr != 'YES') {
    //     return
    //   }
    //   // 変更フラグをfalseにする
    //   this.checkList.items[i].changeFlag = false
    //   this.checkList.items[i].disabled = true
    // },

    /**
     * 該当なし表示に変更ボタン
     * @param i 対象項目
     */
    async enableNA(i) {
      this.checkList.items[i].disabled = false
      this.checkList.items[i].complete = false
    },

    /**
     * 完了ボタン
     * @param i 対象項目
     */
    async complete(i) {
      if (this.start == '') {
        await dialogs.showErrorDialog('開始ボタン', '開始ボタンを押してください。')
        return
      }

      if (!await this.validate(this.checkList.items[i], '')) {
        return
      }

      this.checkList.items[i].complete = true
      this.undo()
      this.reload++

      // 保存
      this.register(i)
    },

    /**
     * チェック処理
     * @param row 対象データ
     */
    async validate(row, msg) {
      // 該当なしは無条件でOK
      if (row.value == 'N/A') {
        return true
      }

      if (!row.classification) {
        await dialogs.showErrorDialog('清掃箇所', msg + '清掃箇所を入力してください。')
        return false
      }

      if (!row.item && !row.disabled) {
        await dialogs.showErrorDialog('状況', msg + '状況を入力してください。')
        return false
      }

      if (!row.disabled) {
        if (!row.photo || row.photo.length != 2) {
          await dialogs.showErrorDialog('写真は2枚です', msg + '写真を2枚添付してください。')
          return false
        }
      }
      return true
    },

    /**
     * 完了を表示ボタン
     */
    reDisplay() {
      this.completeBtn = false
      this.undoBtn = true
    },

    /**
     * 完了ボタン押下の状態に戻す
     */
    undo() {
      this.completeBtn = true
      this.undoBtn = false
    },

    /**
     * 履歴モーダルオープン
     */
    // openHistory() {
    //   this.historyModal = true
    //   this.openHistoryModal++
    // },

    /**
     * 履歴モーダルクローズ
     */
    // checkListHistoryModalClose() {
    //   this.historyModal = false
    // },

    // 添付画像 取得
    async getfullImages() {
      const res = await backend.searchData('patrolResult/getPhoto', { patrolId: this.target.id, type: this.type })
      if (res.data) this.fullImages = res.data
    },

    /**
     * 画像 クリック イベント
     * @param i 対象項目
     * @param j 画像インデックス
     */
    async clickImage(sortNo, i, j) {
      const key = this.target.id + '/' + sortNo + '/' + j
      if (this.fullImages && this.fullImages[key]) this.fullImage = this.fullImages[key]
      else this.fullImage = this.checkList.items[i].photo[j]
    },

    /**
     * 前の「予定一覧_詳細」画面に戻る
     */
    previous() {
      let route = this.routerName
      // 作業完了一覧から来た場合
      if (Number(this.$route.query.sub) == -1) {
        route = 'RoundCheckList'
      }
      this.$router.push({ 
        name: route,
        params: {
          checkListDate: this.target.scheduledDate,
          userId: this.target.userId,
          name: this.target.staff
        },
        query: {
          checkList: 'true'
        }
      })
    },

    /**
     * 左へ写真を移動
     * @param i 項目インデックス
     * @param j 写真インデックス
     */
    toLeft(i, j) {
      let val = this.checkList.items[i].photo[j]
      this.checkList.items[i].photo.splice(j-1, 0, val)
      this.checkList.items[i].photo.splice(j+1, 1)
      this.checkList.items[i].changePhotoSort = true
      this.reload++
    },

    /**
     * 右へ写真を移動
     * @param i 項目インデックス
     * @param j 写真インデックス
     */
    toRight(i, j) {
      let val = this.checkList.items[i].photo[j]
      this.checkList.items[i].photo.splice(j+2, 0, val)
      this.checkList.items[i].photo.splice(j, 1)
      this.checkList.items[i].changePhotoSort = true
      this.reload++
    },
  }
}
</script>
<style scoped>
.mobile-content {
  width: 100vw !important;
  /* width: 500px !important; */
  height: 98vh !important;
  overflow: auto;
  padding: 10px;
}

.pc-content {
  width: 950px;
  height: calc(100vh - 20px);
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  overflow: auto;
  padding: 10px;
  border: solid 1px rgb(206, 206, 246);
  border-radius: 10px;
}

.red {
  background: rgb(242, 70, 70);
}

.blue {
  background: rgb(86, 86, 235);
}

.max-w-lg {
  max-width: none !important;
}

.col-no {
  width: 70px;
}

.hover-color:hover {
  color: rgb(11, 118, 200);
}

.bg-orange {
  background-color: rgb(231 137 70);
}

.bg-orange:hover {
  background-color: rgb(239 111 20);
}

.vsa-item {
  background-color: rgb(214, 238, 243);
}

.file-btn {
  /* border: 3px solid blue;
  background-color: rgb(75, 191, 152); */
  display: inline-block;
  position: relative;
}

.w-70px {
  width: 70px;
}

.file-type {
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
}

/* safari用のスタイル */
_::-webkit-full-page-media,
_:future,
:root .file-type {
  height: 100%;
  top: 0;
  left: 0;
  width: 70px;
  opacity: 0;
  position: absolute;
}

/* safari用のスタイル */
_::-webkit-full-page-media,
_:future,
:root .comp-btn {
  position: relative;
  z-index: 50;
  width: 4rem;
}

.mr-73px {
  margin-right: 73px;
}

.bg-darkblue {
  background: #010178;
}

.bg-darkgreen {
  background: #010178;
}

.ml-32p {
  margin-left: 32px;
}

.w-96pa {
  width: 96%;
}

.edit {
  border: 1px solid #053a3a;
}

.p-5p {
  padding: 5px;
}

.p-6p {
  padding: 6px;
}

.w-354 {
  width: 354px;
}

.info-group {
  display: flex;
}

.info1 {
  width: 50%;
}

.info2 {
  width: 50%;
  margin-left: auto;
}

#classific >>> .v-suggest input {
  color: blue !important;
  font-weight: bold !important;
}

@media screen and (max-width: 900px) {
  /* .mobile-content {
    width: 900px !important;
    height: calc(100vh - 90px);
    overflow: auto;
  } */

  .info-group {
    display: block;
  }

  .info1 {
    width: 100%;
  }

  .info2 {
    width: 100%;
    margin-right: auto;
  }

}
</style>