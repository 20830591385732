<template>
	<Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
		<div v-if="list && list.length" class="rounded overflow-auto w-fit">

			<table>
				<thead class="">
					<tr class="">
						<th v-for="(c, i) in columns" :key="'m' + i" class="text-center pl-3 pr-3 pt-1 pb-1 whitespace-pre-line">{{ c }}</th>
					</tr>
				</thead>
				<!-- <tbody> -->
				<tr v-for="(row, i) in list" :key="'mr' + i">

					<!-- 年 -->
					<td class="px-3">{{ row.year }}<span v-if="thisYear<row.year" class="ml-2 text-gray-500 text-xs font-bold">来年</span></td>

					<!-- 色 -->
					<td class="w-32 h-11 text-right px-3 pb-1">
						<input
							type="color"
							class="w-full"
							v-model="row.color"
						>
					</td>

					<!-- 削除 -->
					<td class="pb-1" >
						<div class="mx-auto w-fit">
							<TrashIcon @click="deleteSetting(i)" class="text-gray-400 cursor-pointer hover:text-gray-500 active:text-gray-600 w-5 h-5" />
						</div>
					</td>
				</tr>
			</table>
			
			<PrimaryButton
				text="保存"
				class="m-3 save-btn"
				@click="save()"
			>
			</PrimaryButton>
		</div>
	</Modal>
</template>

<script>
import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import { TrashIcon } from '@vue-hero-icons/solid'
import * as backend from '@libs/backend'
import * as moment from 'moment'
import * as utils from '@libs/utils'

const ROW = {
	id: null,
	year: null,
	color: null
}

export default {
	components: {
		Modal,
		PrimaryButton,
		TrashIcon
	},

	props: ['open'],

	data() {
		return {
			// カラム
			columns: [
				'年',
				'色',
				'削除'
			],
			// 表示データ
			list: utils.clone(ROW),
			// 並べ替え
			sortable: false,
			// 今年の年
			thisYear: Number(moment().format('YYYY'))
		}
	},

	computed: {
	},

	watch: {
		open() {
			this.getList()
		}
	},

	methods: {
		/**
		 * モーダルclose
		 */
		closed() {
			this.list = utils.clone(ROW)
		},

		/**
		 * データ取得
		 */
		async getList() {
			if (!this.open) {
				return
			}
			this.list = []
			let result = await backend.searchData('estimateCountingDisplay/getYearColor')
			if (result.data.data) {
				this.list = result.data.data
			}

			// 過去4年分、今年、来年分のyearは最低限用意
			const start = Number(moment().format('YYYY')) - 4
			for (let i = 0; i < 6; i++) {
				let flg = false
				let year = start + i
				for (let j = 0; j < this.list.length; j++) {
					const l = this.list[j]
					if (year == l.year) {
						flg = true
						break
					}
				}
				if (!flg) {
					let r = utils.clone(ROW)
					r.year = year
					this.list.push(r)
				}
			}
			
		},

		/**
		 * 背景色
		 * @param val 色コード
		 */
		setColor(val) {
			let css = ''
			if (val) {
				css = 'background-color: ' + val + ' !important;'
			}
			return css
		},

		/**
		 * 設定値削除
		 * @param i index
		 */
		deleteSetting(i) {
			this.list.splice(i, 1)
		},

		/**
		 * 保存
		 */
		async save() {
			await backend.postData('estimateCountingDisplay/saveYearColor', { list: this.list, insertUser: this.$store.getters.user.id})
			this.$emit('close')
		}
	}
}
</script>
<style scoped>
.save-btn {
	width: -webkit-fill-available;
}
</style>