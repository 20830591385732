<template>
  <div>
    <div v-if="downloading" class="flex justify-center items-center loading-dialog3">
      <p class="text-gray-600 font-bold animate-pulse" style="font-size: 30px">Downloading...</p>
    </div>
    <PageHeader :title="isMobileDevice || isTabletDevice ? '':'評価シート 一覧'" class="main-form">
      <template #title-header-content>
        <div class="flex-1 flex justify-end items-center">
          <!-- 年度検索 -->
          <div class="mr-2 mt-auto">
            <input
              type="number"
              name="filterYear" 
              min="2024"
              max="2999"
              placeholder="年度 検索"
              class="py-2 border border-gray-300 rounded-md leading-5 focus:bg-white focus:text-gray-900 font-bold w-28 text-center" 
              v-model="filter.year"
            /> 
            <span class="pl-1 pr-2 font-bold text-white text-sm">年度</span>
          </div>

          <!-- 切替 -->
          <div class="bg-white rounded mr-5">
            <div class="flex items-center py-1 rounded mx-5 ">
              <div v-for="tab in typeList" 
                :key="tab.id" 
                class="w-28 text-center mx-1 cursor-pointer"
                :class="[type == tab.id ? 'bg-light-blue-700' : 'hover:bg-gray-400', 'bg-gray-300 rounded-md py-1 px-3 text-sm font-medium text-white']" 
                @click="typeChanged(tab)"
              >
                {{ tab.title }}
              </div>
            </div>
          </div>

          <!-- 検索ボタン -->
          <PrimaryButton
            text="検索"
            class="button-contents mr-5"
            @click="getData()"
          >
          </PrimaryButton>

          <!-- 入力 -->
          <PrimaryButton class="bg-green-600 mr-2" text="本人入力フォーム" @click="toInputForm()">
            <Icon slot="before" iconName="PencilAlt" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
          </PrimaryButton>

          <!-- Excel -->
          <PrimaryButton v-if="auth" text="Excel" @click="download()" :buttonContents="'要'" class="mr-1 bg-blue-600">
            <Icon v-if="!downloading" slot="before" iconName="Download" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
            <LoadingIcon v-else slot="before" class="h-4 w-4 my-auto ml-2 text-white" />
          </PrimaryButton>

          <!-- リロードアイコン -->
          <Icon
            iconName="Refresh"
            :clickable="false"
            :class="{ 'animate-spin': loading || downloading }"
            class="text-white hover:text-gray-300 active:text-gray-400 ml-4 cursor-pointer" @click.native="refresh"
          />
        </div>
      </template>
      <template #page-header-content>
        <div class="flex justify-end">
          <div v-if="!boss" class="text-white font-bold mr-3 mt-auto">
            ※上司以外は自分のデータのみ表示されます。
          </div>
          <div v-else class="flex items-stretch">
            <!-- 上司待ち -->
            <PrimaryButton
              text="上司入力未完了"
              :buttonContents="'要'"
              class="mr-2 mt-auto"
              :class="[filter.stopBoss ? 'clickButtonColor2' : 'normalButtonColor', isMobileDevice||isTabletDevice?'px-1':'']"
              @click="filter.stopBoss=!filter.stopBoss"
            >
              <Icon v-if="!isMobileDevice&&!isTabletDevice" slot="before" iconName="User" iconType="solid" :strokeWidth="2" class="h-5 w-5 " />
            </PrimaryButton>

            <div class="mr-2 mt-auto">
              <input
                type="search"
                name="filterUser" 
                placeholder="名前 検索"
                class="py-2 border border-gray-300 rounded-md leading-5 focus:bg-white focus:text-gray-900 w-36" 
                v-model="filter.user"
              /> 
            </div>
            
            <div class="mr-2 mt-auto">
              <select
                name="departmentName"
                v-model="filter.departmentId"
                class="border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900 w-36"
              >
                <option
                  v-for="(d, i) in departmentList" :key="'department' + i"
                  :value="d.departmentId"
                  class="bg-white">
                  {{ d.name }}
                </option>
              </select>
            </div>

            <div class="text-white font-bold text-sm items-stretch my-auto">
              ※上司の方は評価対象者を選択してください。
            </div>

          </div>
        </div>
      </template>
    </PageHeader>

    <main class="absolute top-28 bottom-4 leading-8 w-full main-form mx-auto">
      <div class="mx-auto px-2 sm:px-4 lg:px-8 h-full w-full">
        <div v-if="list.length" class="rounded-md border border-gray-200 table-h overflow-auto">
          <table class="table text-sm">
            <thead class="th sticky top-0 z-20 bg-colu">
              <tr class="text-gray-500 text-xs border border-gray-200 bg-colu">
                <th v-for="(c, i) in columns" :key="'col'+i" class="text-left p-2" :class="c.class" @click="allChecked(c.val)">{{ c.val }}</th>
              </tr>
            </thead>
            <tbody v-for="(row, i) in list" :key="i">
              <tr class="hover-color border-l border-r border-gray-200 bg-white">

                <!-- 年度 -->
                <td :rowspan="3" class="whitespace-prerap text-sm text-gray-700 min-w-fit p-2 cursor-pointer text-center" @click="toInputForm(row)">
                  {{ row.year }} <span>{{ row.periodName }}</span>
                </td>

                <!-- 名前 -->
                <td :rowspan="3" class="whitespace-prerap text-sm text-gray-700 min-w-fit p-2 cursor-pointer font-bold" @click="toInputForm(row)">
                  {{ row.userName }}
                </td>

                <!-- 部署名 -->
                <td :rowspan="3" class="whitespace-prerap text-sm text-gray-700 min-w-fit p-2 cursor-pointer border-r" @click="toInputForm(row)">
                  {{ row.departmentName }}
                </td>

                <!-- 種別 -->
                <td class="whitespace-prerap text-sm text-gray-700 min-w-fit p-2 cursor-pointer border-r text-center" @click="toInputForm(row)">
                  本人入力
                </td>
                
                <!-- 本人入力 -->
                <td class="whitespace-prerap text-sm text-gray-700 min-w-fit p-2 cursor-pointer border-r" :class="row.ps1==0?'bg-red-100':''" @click="toInputForm(row)">
                  <div v-if="row.ps1==1">
                    <Icon slot="before" iconName="CheckCircle" iconType="outline" :strokeWidth="2" class="h-6 w-6 text-blue-700 m-auto" />
                  </div>
                  <div v-else-if="row.ps1==0" class="text-center text-red-800 font-bold">未完了</div>
                  <div v-else-if="row.ps1==-1" class="text-center text-gray-500 text-xs">入社前</div>
                </td>
                
                <!-- 本人入力 -->
                <td class="whitespace-prerap text-sm text-gray-700 min-w-fit p-2 cursor-pointer border-r" :class="row.ps2==0?'bg-red-100':''" @click="toInputForm(row)">
                  <div v-if="row.ps2==1">
                    <Icon slot="before" iconName="CheckCircle" iconType="outline" :strokeWidth="2" class="h-6 w-6 text-blue-700 m-auto" />
                  </div>
                  <div v-else-if="row.ps2==0" class="text-center text-red-800 font-bold">未完了</div>
                  <div v-else-if="row.ps2==-1" class="text-center text-gray-500 text-xs">入社前</div>
                </td>
                
                <!-- 本人入力 -->
                <td class="whitespace-prerap text-sm text-gray-700 min-w-fit p-2 cursor-pointer border-r" :class="row.ps3==0?'bg-red-100':''" @click="toInputForm(row)">
                  <div v-if="row.ps3==1">
                    <Icon slot="before" iconName="CheckCircle" iconType="outline" :strokeWidth="2" class="h-6 w-6 text-blue-700 m-auto" />
                  </div>
                  <div v-else-if="row.ps3==0" class="text-center text-red-800 font-bold">未完了</div>
                  <div v-else-if="row.ps3==-1" class="text-center text-gray-500 text-xs">入社前</div>
                </td>
                
                <!-- 本人入力 -->
                <td class="whitespace-prerap text-sm text-gray-700 min-w-fit p-2 cursor-pointer border-r" :class="row.ps4==0?'bg-red-100':''" @click="toInputForm(row)">
                  <div v-if="row.ps4==1">
                    <Icon slot="before" iconName="CheckCircle" iconType="outline" :strokeWidth="2" class="h-6 w-6 text-blue-700 m-auto" />
                  </div>
                  <div v-else-if="row.ps4==0" class="text-center text-red-800 font-bold">未完了</div>
                  <div v-else-if="row.ps4==-1" class="text-center text-gray-500 text-xs">入社前</div>
                </td>
                
                <!-- 本人入力 -->
                <td class="whitespace-prerap text-sm text-gray-700 min-w-fit p-2 cursor-pointer border-r" :class="row.ps5==0?'bg-red-100':''" @click="toInputForm(row)">
                  <div v-if="row.ps5==1">
                    <Icon slot="before" iconName="CheckCircle" iconType="outline" :strokeWidth="2" class="h-6 w-6 text-blue-700 m-auto" />
                  </div>
                  <div v-else-if="row.ps5==0" class="text-center text-red-800 font-bold">未完了</div>
                  <div v-else-if="row.ps5==-1" class="text-center text-gray-500 text-xs">入社前</div>
                </td>
                
                <!-- 本人入力 -->
                <td class="whitespace-prerap text-sm text-gray-700 min-w-fit p-2 cursor-pointer border-r" :class="row.ps6==0?'bg-red-100':''" @click="toInputForm(row)">
                  <div v-if="row.ps6==1">
                    <Icon slot="before" iconName="CheckCircle" iconType="outline" :strokeWidth="2" class="h-6 w-6 text-blue-700 m-auto" />
                  </div>
                  <div v-else-if="row.ps6==0" class="text-center text-red-800 font-bold">未完了</div>
                  <div v-else-if="row.ps6==-1" class="text-center text-gray-500 text-xs">入社前</div>
                </td>
              </tr>
              <tr class="">
                <!-- 種別 -->
                <td class="whitespace-prerap text-sm text-gray-700 min-w-fit p-2 cursor-pointer border-r text-center" @click="toInputForm(row)">
                  上司入力
                </td>

                <!-- 上司入力 -->
                <td class="whitespace-prerap text-sm text-gray-700 min-w-fit p-2 cursor-pointer border-r" :class="row.pb1==-10?'bg-red-100':''" @click="toInputForm(row)">
                  <div v-if="row.pb1==1">
                    <Icon slot="before" iconName="CheckCircle" iconType="outline" :strokeWidth="2" class="h-6 w-6 text-blue-700 m-auto" />
                  </div>
                  <div v-else-if="row.pb1==-10" class="text-center text-red-800 font-bold">未完了</div>
                  <div v-else-if="row.pb1==-1" class="text-center text-gray-500 text-xs">入社前</div>
                  <div v-else-if="row.pb1!=2" class="text-center text-gray-500 text-xs">本人入力待ち</div>
                </td>

                <!-- 上司入力 -->
                <td class="whitespace-prerap text-sm text-gray-700 min-w-fit p-2 cursor-pointer border-r" :class="row.pb2==-10?'bg-red-100':''" @click="toInputForm(row)">
                  <div v-if="row.pb2==1">
                    <Icon slot="before" iconName="CheckCircle" iconType="outline" :strokeWidth="2" class="h-6 w-6 text-blue-700 m-auto" />
                  </div>
                  <div v-else-if="row.pb2==-10" class="text-center text-red-800 font-bold">未完了</div>
                  <div v-else-if="row.pb2==-1" class="text-center text-gray-500 text-xs">入社前</div>
                  <div v-else-if="row.pb2!=2" class="text-center text-gray-500 text-xs">本人入力待ち</div>
                </td>
                <!-- 上司入力 -->
                <td class="whitespace-prerap text-sm text-gray-700 min-w-fit p-2 cursor-pointer border-r" :class="row.pb3==-10?'bg-red-100':''" @click="toInputForm(row)">
                  <div v-if="row.pb3==1">
                    <Icon slot="before" iconName="CheckCircle" iconType="outline" :strokeWidth="2" class="h-6 w-6 text-blue-700 m-auto" />
                  </div>
                  <div v-else-if="row.pb3==-10" class="text-center text-red-800 font-bold">未完了</div>
                  <div v-else-if="row.pb3==-1" class="text-center text-gray-500 text-xs">入社前</div>
                  <div v-else-if="row.pb3!=2" class="text-center text-gray-500 text-xs">本人入力待ち</div>
                </td>
                
                <!-- 上司入力 -->
                <td class="whitespace-prerap text-sm text-gray-700 min-w-fit p-2 cursor-pointer border-r" :class="row.pb4==-10?'bg-red-100':''" @click="toInputForm(row)">
                  <div v-if="row.pb4==1">
                    <Icon slot="before" iconName="CheckCircle" iconType="outline" :strokeWidth="2" class="h-6 w-6 text-blue-700 m-auto" />
                  </div>
                  <div v-else-if="row.pb4==-10" class="text-center text-red-800 font-bold">未完了</div>
                  <div v-else-if="row.pb4==-1" class="text-center text-gray-500 text-xs">入社前</div>
                  <div v-else-if="row.pb4!=2" class="text-center text-gray-500 text-xs">本人入力待ち</div>
                </td>
                
                <!-- 上司入力 -->
                <td class="whitespace-prerap text-sm text-gray-700 min-w-fit p-2 cursor-pointer border-r" :class="row.pb5==-10?'bg-red-100':''" @click="toInputForm(row)">
                  <div v-if="row.pb5==1">
                    <Icon slot="before" iconName="CheckCircle" iconType="outline" :strokeWidth="2" class="h-6 w-6 text-blue-700 m-auto" />
                  </div>
                  <div v-else-if="row.pb5==-10" class="text-center text-red-800 font-bold">未完了</div>
                  <div v-else-if="row.pb5==-1" class="text-center text-gray-500 text-xs">入社前</div>
                  <div v-else-if="row.pb5!=2" class="text-center text-gray-500 text-xs">本人入力待ち</div>
                </td>
                
                <!-- 上司入力 -->
                <td class="whitespace-prerap text-sm text-gray-700 min-w-fit p-2 cursor-pointer border-r" :class="row.pb6==-10?'bg-red-100':''" @click="toInputForm(row)">
                  <div v-if="row.pb6==1">
                    <Icon slot="before" iconName="CheckCircle" iconType="outline" :strokeWidth="2" class="h-6 w-6 text-blue-700 m-auto" />
                  </div>
                  <div v-else-if="row.pb6==-10" class="text-center text-red-800 font-bold">未完了</div>
                  <div v-else-if="row.pb6==-1" class="text-center text-gray-500 text-xs">入社前</div>
                  <div v-else-if="row.pb6!=2" class="text-center text-gray-500 text-xs">本人入力待ち</div>
                </td>
              </tr>
              <tr class="border-b-4 ">
                <!-- 種別 -->
                <td class="whitespace-prerap text-sm text-gray-700 min-w-fit p-2 cursor-pointer border-r text-center" @click="toInputForm(row)">
                  方針共有
                </td>

                <!-- 共有方針 -->
                <td class="whitespace-prerap text-sm text-gray-700 min-w-fit p-2 cursor-pointer border-r" :class="row.po1==0?'bg-red-100':''" @click="toInputForm(row)">
                  <div v-if="row.po1==1">
                    <Icon slot="before" iconName="CheckCircle" iconType="outline" :strokeWidth="2" class="h-6 w-6 text-blue-700 m-auto" />
                  </div>
                  <div v-else-if="row.po1==0" class="text-center text-red-800 font-bold">未完了</div>
                  <div v-else-if="row.po1==-1" class="text-center text-gray-500 text-xs">入社前</div>
                </td>

                <!-- 共有方針 -->
                <td class="whitespace-prerap text-sm text-gray-700 min-w-fit p-2 cursor-pointer border-r" :class="row.po2==0?'bg-red-100':''" @click="toInputForm(row)">
                  <div v-if="row.po2==1">
                    <Icon slot="before" iconName="CheckCircle" iconType="outline" :strokeWidth="2" class="h-6 w-6 text-blue-700 m-auto" />
                  </div>
                  <div v-else-if="row.po2==0" class="text-center text-red-800 font-bold">未完了</div>
                  <div v-else-if="row.po2==-1" class="text-center text-gray-500 text-xs">入社前</div>
                </td>
                
                <!-- 共有方針 -->
                <td class="whitespace-prerap text-sm text-gray-700 min-w-fit p-2 cursor-pointer border-r" :class="row.po3==0?'bg-red-100':''" @click="toInputForm(row)">
                  <div v-if="row.po3==1">
                    <Icon slot="before" iconName="CheckCircle" iconType="outline" :strokeWidth="2" class="h-6 w-6 text-blue-700 m-auto" />
                  </div>
                  <div v-else-if="row.po3==0" class="text-center text-red-800 font-bold">未完了</div>
                  <div v-else-if="row.po3==-1" class="text-center text-gray-500 text-xs">入社前</div>
                </td>
                
                <!-- 共有方針 -->
                <td class="whitespace-prerap text-sm text-gray-700 min-w-fit p-2 cursor-pointer border-r" :class="row.po4==0?'bg-red-100':''" @click="toInputForm(row)">
                  <div v-if="row.po4==1">
                    <Icon slot="before" iconName="CheckCircle" iconType="outline" :strokeWidth="2" class="h-6 w-6 text-blue-700 m-auto" />
                  </div>
                  <div v-else-if="row.po4==0" class="text-center text-red-800 font-bold">未完了</div>
                  <div v-else-if="row.po4==-1" class="text-center text-gray-500 text-xs">入社前</div>
                </td>
                
                <!-- 共有方針 -->
                <td class="whitespace-prerap text-sm text-gray-700 min-w-fit p-2 cursor-pointer border-r" :class="row.po5==0?'bg-red-100':''" @click="toInputForm(row)">
                  <div v-if="row.po5==1">
                    <Icon slot="before" iconName="CheckCircle" iconType="outline" :strokeWidth="2" class="h-6 w-6 text-blue-700 m-auto" />
                  </div>
                  <div v-else-if="row.po5==0" class="text-center text-red-800 font-bold">未完了</div>
                  <div v-else-if="row.po5==-1" class="text-center text-gray-500 text-xs">入社前</div>
                </td>
                
                <!-- 共有方針 -->
                <td class="whitespace-prerap text-sm text-gray-700 min-w-fit p-2 cursor-pointer border-r" :class="row.po6==0?'bg-red-100':''" @click="toInputForm(row)">
                  <div v-if="row.po6==1">
                    <Icon slot="before" iconName="CheckCircle" iconType="outline" :strokeWidth="2" class="h-6 w-6 text-blue-700 m-auto" />
                  </div>
                  <div v-else-if="row.po6==0" class="text-center text-red-800 font-bold">未完了</div>
                  <div v-else-if="row.po6==-1" class="text-center text-gray-500 text-xs">入社前</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else-if="!loading && !list.length" class="w-full h-full flex justify-center items-center text-gray-400">
          <EmptyMessage
            iconName="Document"
            message="該当するデータはありません。"
          />
        </div>
      </div>
    </main>
  </div>
</template>
  
<script>
import Icon from '@components/Icon.vue'
import PageHeader from '@components/PageHeader.vue'
import EmptyMessage from '@components/EmptyMessage.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import * as backend from '@libs/backend'
import * as utils from '@libs/utils'
import * as moment from 'moment'
import LoadingIcon from '@assets/loading-circle.svg' 

const settionStrageName = 'evaListStrage'

// 評価シート
const COL = [
  { val: '年度', class: 'text-center' },
  { val: '名前' },
  { val: '部署' },
  { val: '' },
]

export default {
  components: {
    Icon,
    PageHeader,
    EmptyMessage,
    PrimaryButton,
    LoadingIcon
  },

  computed: {
    /**
     * スマホ
     */
    isMobileDevice() {
      return utils.deviceInfo.isMobile
    },

    /**
     * タブレット
     */
    isTabletDevice() {
      return utils.deviceInfo.isTablet
    },
  },

  data() {
    return {
      loading: false,
      downloading: false,
      // 切替ボタン
      typeList: [
        { id: 1, title: '上期' },
        { id: 2, title: '下期' }
      ],
      type: 1,
      // リスト
      list: [],
      // カラム
      columns: utils.clone(COL),
      // 検索条件
      filter: {
        // 上司入力待ちで絞り込み
        stopBoss: false,
        // 年度
        year: null,
        // 部署絞込み
        departmentId: '',
        // 対象者絞込み
        user: '',
      },
      // 部署リスト
      departmentList: utils.clone(this.$store.getters.departmentList),
      // 状態リスト
      statusList: [
        { id: '', val: '' },
        { id: '1', val: '未完了あり' },
        { id: '2', val: '全て完了' }
      ],
      // 上司フラグ
      boss: false,
      // Excelボタン権限
      auth: false
    }
  },

  async created() {
    // 年度
    this.filter.year = Number(moment().format('YYYY'))
    const mm = Number(moment().format('M'))
    if (mm == 1) {
      this.filter.year--
      this.type = 2
    } else if (mm <= 7) {
      this.type = 1
    } else {
      this.type = 2
    }
    // 2月、8月の場合、中旬までは前期にする
    if ([2, 8].includes(mm)) {
      if (moment().format('DD') == '16') {
        if (mm == 2) {
          this.type = 2
          this.filter.year--
        } else {
          this.type = 1
        }
      }
    }
    // 上司フラグ
    this.boss = this.$store.getters.loginUserData.boss
    // 部署リスト
    this.departmentList.unshift({ departmentId: '', name: '全ての部署' })
    // Excelボタン権限
    const userInfo = this.$store.getters.loginUserData
    if (userInfo.departmentName && userInfo.departmentName.indexOf('人事') > -1) {
      this.auth = true
    }
    this.getSettionStrage()
    await this.getData()
  },

  methods: {
    /**
     * リフレッシュアイコンイベント
     */
    async refresh() {
      await this.getData()
    },

    /**
     * セッションストレージ取得
     */
    getSettionStrage() {
      const searchJson = sessionStorage.getItem(settionStrageName)
      const getItem = JSON.parse(searchJson)
      if (getItem) {
        if (getItem.type) {
          this.type = getItem.type
        }
        delete getItem.type
        this.filter = getItem
      }
    },

    /**
     * セッションストレージ保存
     */
    setSessionStorage() {
      const searchStr = JSON.stringify({ ...this.filter, type: this.type })
      sessionStorage.setItem(settionStrageName, searchStr)
    },

    /**
     * データ取得
     */
    async getData() {
      this.list = []
      this.setColumn()
      // データ取得
      this.loading = true

      // カナを削除したログインユーザー名、ユーザーID
      const loginUser = utils.deleteKana(this.$store.getters.user.user_name)
      const loginUserId = this.$store.getters.user.id
      const res = await backend.postData('evaluation/getList', { ...this.filter, loginUser, loginUserId, period: this.type, boss: this.boss  })
      if (res.data.data && res.data.data.length) {
        this.list = res.data.data
      }

      this.setSessionStorage()
      this.loading = false
    },

    /**
     * カラムをセット
     */
    setColumn() {
      this.columns = utils.clone(COL)
      // 上期の場合
      let mm = 2
      if (this.type == 2) {
        mm = 8
      }
      for (let i = 0; i < 6; i++) {
        let month = (mm + i) + '月'
        if (month == '13月') {
          month = '1月'
        }
        this.columns.push({ val: month, class: 'text-center' })
      }
    },

    /**
     * 切替イベント
     * @paran tab 選択したタブ
     */
    async typeChanged(tab) {
      this.type = tab.id
      await this.getData()
    },

    /**
     * 入力フォームへ遷移イベント
     */
    toInputForm(row) {
      if (row) {
        this.$router.push({
          name: 'EvaluationInput',
          params: {
            target: row
          },
          query: {
            id: row.id
          }
        })
      } else {
        this.$router.push({
          name: 'EvaluationInput'
        })
      }
    },

    /**
     * ダウンロードイベント
     */
    async download() {
      this.downloading = true
      const res = await backend.postBlob2('evaluationDownload/excel', { year: this.filter.year, period: this.type })
      try {
        const uint8Array = res.data
        const blob = new Blob([uint8Array], { "Content-Type" : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        const a = document.createElement("a");
        a.href = (window.URL || window.webkitURL).createObjectURL(blob)
        let pe = '上期'
        if (this.type == 2) {
          pe = '下期'
        }
        let name = '評価シート（' + this.filter.year + '年度' + pe + '）'

        a.download = name + '.xlsx'
        a.click();
        a.remove();
        this.downloading = false
      } catch (e) {
        console.log(e)
        alert(e)
        this.downloading = false
      }
    },

  }
}
</script>
  
<style scoped>
.main-form {
  min-width: 1100px;
}

.table-h {
  max-height: calc(100% - 40px);
}

.table {
  width: 100%;
}

.bg-colu {
  background: #ebf0fb;
}
</style>