<template>
  <div>
    <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
      <template #action>
        <div class="content-container-app overflow-auto">
          <div class="text-lg text-blue-800 font-bold" v-if="isNew">新規登録</div>
          <div class="text-lg text-blue-800 font-bold" v-else>編集</div>

          <div class="mt-5 ml-12 mr-12 mb-12">

            <div class="flex w-full mt-3">
                <!-- 表示順 -->
              <div class="w-2/5">
                <div class="text-xs text-gray-600 font-bold">表示順<br> <span class="font-normal">数値が小さいほど上にきます</span></div>
                <NumericTextInput
                  name="sortNo"
                  v-model="user.sortNo"
                  class="border-gray text-center focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 rounded"
                />
              </div>

              <!-- 印鑑 -->
              <div class="w-2/5 ml-2 stamp-block">
                <div class="text-xs text-gray-600 font-bold mb-1">印鑑</div>
                <img ref="imageStamp" :src="user.stampUrl" id="imageStamp" loading="lazy" decoding="async" />
                <!-- <div v-else style="width: 55px;" /> -->
                <div class="upload-button">
                  <PrimaryButton text="アップロード" @click="clickUploadStamp()" size="sm"/>
                  <input ref="inputStamp" type="file" accept="image/png" @change="changeStamp($event)" style="display:none" />
                </div>
              </div>
            </div>

            <div class="flex w-full mt-3">

              <!-- 名前 -->
              <div class="w-2/5">
                <WmsTextInput
                  name="name"
                  caption="名前" 
                  v-model="user.userName"
                  :required="true"
                  class="focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block rounded "
                />
              </div>

              <!-- かな -->
              <div class="w-2/5 ml-2">
                <WmsTextInput
                  name="kana"
                  caption="かな" 
                  v-model="user.userKana"
                  class="focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block rounded"
                />
              </div>
            </div>
            
            <div class="flex mt-3">

              <!-- メアド -->
              <div class="w-2/5">
                <WmsTextInput
                  name="eMailAdress"
                  caption="メールアドレス" 
                  v-model="user.eMailAdress"
                  :required="true"
                  class="focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block rounded"
                />
              </div>

              <!-- パス -->
              <div class="w-2/5 ml-2">
                <WmsTextInput
                  name="eMailAdress"
                  :caption="pasMsg" 
                  v-model="user.userPassword"
                  :required="isNew"
                  class="focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block rounded"
                />
              </div>

            </div>
            
            <div class="flex mt-3">

              <!-- チャットワークアカウントID -->
              <div class="w-2/5">
                <WmsTextInput
                  name="chatworkAccountId"
                  caption="チャットワークアカウントID" 
                  v-model="user.chatworkAccountId"
                  class="focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block rounded"
                />
              </div>

            </div>

            <!-- 権限 -->
            <div class="mt-5 text-sm text-gray-600 font-bold mb-1 flex">
              権限
              <div class="pl-5">
                <input 
                  type="checkbox" 
                  id="all"
                  class="h-4 w-4 border-gray-400 rounded cursor-pointer" 
                  v-model="all"
                />
                <label for="all" class="pl-2 pr-5 py-5 cursor-pointer text-blue-800">全てにチェック</label>
              </div>
            </div>
            <div class="rounded border-gray px-5 py-4 flex flex-wrap">
              <!-- <table>
                <tbody>
                  <tr> -->
                    <td v-for="a in auth" :key='a.id'>
                      <div>
                        <input 
                          type="checkbox" 
                          :id="a.item"
                          class="h-4 w-4 my-2 border-gray-400 rounded cursor-pointer" 
                          v-model="a.auth"
                        />
                        <label :for="a.item" class="pl-2 pr-5 py-1 cursor-pointer">{{ a.name }}</label>
                      </div>
                    </td>
                  <!-- </tr>
                </tbody>
              </table> -->
            </div>
            
            <!-- その他 -->
            <div class="mt-5 text-sm text-gray-600 font-bold mb-1 flex">
              その他
              <div class="pl-5">
              </div>
            </div>
            <div class="rounded border-gray p-5">
              <table>
                <tbody>
                  <tr>
                    <td v-for="o in other" :key='o.id'>
                      <div>
                        <input 
                          type="checkbox" 
                          :id="o.item"
                          class="h-4 w-4 border-gray-400 rounded cursor-pointer green-check" 
                          v-model="o.flag"
                        />
                        <label :for="o.item" class="pl-2 pr-5 py-5 cursor-pointer">{{ o.name }}</label>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <PrimaryButton text="部署異動" size="normal" class="add-color mt-5" @click="addHistory()"/>
            
            <div v-for="(d, dIdx) in departmentHistory" :key='d.id' class="mb-12">
              <div class="flex mt-3">
                <!-- 部署 -->
                <div class="text-xs text-gray-600 font-bold w-1/5">部署
                  <form class="border-gray rounded mt-1">
                    <select
                      name="departmentName"
                      v-model="departmentHistory[dIdx].departmentId"
                      @change="ChangeDepartment(dIdx)"
                      class="text-center focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block border-transparent w-full text-xs drop"
                    >
                      <option
                        v-for="list in departmentList" :key='list.id'
                        :value="list.departmentId"
                        class="bg-white">
                        {{ list.name }}
                      </option>
                    </select>
                  </form>
                </div>

                <!-- 課 -->
                <div class="text-xs text-gray-600 font-bold w-1/5 mx-1" v-tooltip="tip(d.departmentId, d.subList)">課・エリア
                  <form class="border-gray rounded mt-1">
                    <select
                      :disabled="!d.subList.length"
                      name="departmentSubName"
                      v-model="d.departmentSubId"
                      class="border-gray rounded text-center focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block border-transparent w-full text-xs drop"
                      :class="!d.subList.length?'cursor-not-allowed':''"
                    >
                      <option
                        v-for="list in d.subList" :key='list.id'
                        :value="list.id"
                        class="bg-white">
                        {{ list.name }}
                      </option>
                    </select>
                  </form>
                </div>

                <div class="text-xs text-gray-600 font-bold w-1/5">開始日<span v-if="d.departmentId && d.departmentId!=''" class="-top-0.5 ml-1 text-red-500"> * </span>
                  <WmsDateInput
                    name="startDay"
                    v-model="d.startDay"
                    @change="changeStartDay(d.startDay, dIdx)"
                  />
                </div>
                <div v-if="dIdx!=0" class="pt-6 mx-3">
                  ～
                </div>
                <div v-if="dIdx!=0" class="text-xs text-gray-600 font-bold w-1/5">終了日
                  <WmsTextInput
                    name="endDay"
                    v-model="d.endDay"
                    :disabled="true"
                  />
                </div>

                <TrashIcon v-if="dIdx==0" class="text-gray-400 cursor-pointer hover:text-gray-500 active:text-gray-600 w-6 h-6 mt-6 mx-3" @click="deleteHistory()"/>
              </div>
            </div>
          </div>

          <div>
            <PrimaryButton text="登録" size="normal px-10 py-3 place-items-end btn" @click="register()"/>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>

import Modal from '@components/Modal.vue'
import { TrashIcon } from '@vue-hero-icons/solid'
import PrimaryButton from '@components/PrimaryButton.vue'
import WmsTextInput from '@wmscomponents/WmsTextInput.vue'
import NumericTextInput from '@components/NumericTextInput.vue'
import WmsDateInput from '@wmscomponents/WmsDateInput.vue'
import * as backend from '@libs/backend'
import * as dialogs from '@libs/dialogs'
import * as utils from '@libs/utils'
import * as logManager from '@managers/logManager'

export default {
  components: {
    Modal,
    TrashIcon,
    // PlusCircleIcon,
    PrimaryButton,
    // WmsTextInput,
    WmsTextInput,
    NumericTextInput,
    WmsDateInput
  },

  props: {
    target: {
      type: Object
    },
    open: {
      type: Number,
      default: null
    },
  },

  data() {
    return {
      // ユーザーデータ
      user: {
        id: null,
        sortNo: null,
        userName: '',
        userKana: '',
        eMailAdress: '',
        userPassword: '',
        chatworkAccountId: '',
        insertUserId: this.$store.getters.user.id,
        stampUrl: 'stamps/none.png',
        stampImage: null
      },
      // 権限
      auth: [
        {item:'adminAuth', auth:false, name: '管理者'},
        {item:'userAuth', auth:false, name: 'ユーザー管理'},
        {item:'estimateApproveAuth', auth:false, name: '見積承認'},
        {item:'mainAuth', auth:false, name: 'メインタスク登録'},
        {item:'subAuth', auth:false, name: 'サブタスク登録'},
        {item:'estimateAuth', auth:false, name: '見積り登録'},
        {item:'patrolAuth', auth:false, name: '品質巡回'},
        {item:'roundCleanAuth', auth:false, name: '巡回清掃'},
        {item:'fixedCleanAuth', auth:false, name: '定期清掃'},
        {item:'facilityPatrolAuth', auth:false, name: '設備点検'},
        {item:'photoReportAuth', auth:false, name: '写真報告'},
        {item:'regularStaffAuth', auth:false, name: '社員面接'},
      ],
      // その他
      other: [
        {item:'transmission', flag:false, name: 'デンタツ'},
        {item:'partTimer', flag:false, name: 'パート'},
        {item:'employmentCC', flag:false, name: '雇い入れCC'},
      ],
      // 所属部署履歴
      departmentHistory: [{
        id: null,
        userId: null,
        departmentId: '',
        departmentSubId: null,
        subList: [],
        startDay: utils.getToday(),
        endDay: ''
      }],
      // 新規作成
      isNew: false,
      // 一度保存した部署データを削除したデータ
      deleteDepartment: [],
      // 全ての権限にチェックする
      all: false,
      // 部署リスト
      departmentList: [],
      // 課リスト
      allDepartmentSubList: []
    }
  },

  computed: {
    /**
     * パスワードのcaption
     */
    pasMsg() {
      let result = 'パスワード'
      if (!this.isNew) {
        result = 'パスワード（変更したい時のみ記入してください）'
      }
      return result
    }
  },

  watch: {
    open() {
      this.getTarget()
    },

    /**
     * 権限全てにチェック
     */
    all() {
      if (this.all) {
        this.auth.forEach(a => {
          a.auth = true
        })
      }
    }
  },

  methods: {
    /**
     * 表示するデータ取得
     */
    async getTarget() {
      let res = await backend.getData('department/get')
      this.departmentList = res.data.data
      let res2 = await backend.getData('departmentSub/get')
      this.allDepartmentSubList = res2.data.data
      // 編集の場合
      if (this.target && Object.keys(this.target).length) {
        this.user = {
          id: this.target.id,
          sortNo: this.target.sortNo,
          userName: this.target.userName,
          userKana: this.target.userKana,
          eMailAdress: this.target.eMailAdress,
          userPassword: '',
          chatworkAccountId: this.target.chatworkAccountId,
          updateUserId: this.$store.getters.user.id,
          stampUrl: 'stamps/none.png'
        }

        this.setImage(this.user,  'stamps/' + this.target.id + '.png')

        // 権限
        for (let authIdx = 0; authIdx < this.auth.length; authIdx++) {
          const a = this.auth[authIdx]
          a.auth = this.target[a.item]
        }
        // その他
        for (let otherIdx = 0; otherIdx < this.other.length; otherIdx++) {
          const o = this.other[otherIdx]
          o.flag = this.target[o.item]
        }
        // 所属部署
        let history = await backend.searchData('userDepartment/getByUser', {id: this.target.id})
        if (history.data.data && history.data.data.length) {
          this.departmentHistory = history.data.data

          // 課リスト
          for (let i = 0; i < this.departmentHistory.length; i++) {
            const h = this.departmentHistory[i];
            h.endDay = utils.getDate(h.endDay)

            if (h.departmentId) {
              h.subList = this.allDepartmentSubList.filter((ads) => {
                return ads.departmentId == h.departmentId
              })
            }
          }
        }
      } else {
        this.isNew = true
      }
    },

    async setImage(user, url) {
      var img = new Image();
      img.onload = function()  { 
        /* 読み込み成功時の処理 */
        user.stampUrl = url
      }
      img.src = url;      
    },

    /**
     * 部署選択イベント
     */
    ChangeDepartment(i) {
      // 課リスト変更
      this.departmentHistory[i].subList = this.allDepartmentSubList.filter((ads) => {
        return ads.departmentId == this.departmentHistory[i].departmentId
      })

      if (this.departmentHistory[i].subList && this.departmentHistory[i].subList.length == 1) {
        this.departmentHistory[i].departmentSubId = this.departmentHistory[i].subList[0].id
        this.departmentHistory[i].subName = this.departmentHistory[i].subList[0].name
      } else {
        this.departmentHistory[i].departmentSubId = null
        this.departmentHistory[i].subName = ''
      }
    },

    /**
     * アップロードボタン クリックイベント
     */
    async clickUploadStamp() {
      this.$refs.inputStamp.click()
    },

    /**
     * 部署異動ボタン（追加）
     */
    async addHistory() {
      let h = {
        id: null,
        userId: null,
        departmentId: '',
        departmentSubId: null,
        subList: [],
        startDay: utils.getToday(),
        endDay: '',
      }

      if (this.departmentHistory.length > 0 && this.departmentHistory[0].departmentId && this.departmentHistory[0].departmentId != '') {
        if (this.departmentHistory[0].startDay && this.departmentHistory[0].startDay != '') {
          this.departmentHistory.splice(0, 0, h)
        } else {
          await dialogs.showErrorDialog('以前の部署確認', '1行目の開始日がありません。')
          return
        }
      } else {
        await dialogs.showErrorDialog('以前の部署確認', '1行目の部署がありません。')
        return
      }
      if (this.departmentHistory[1]) {
        this.departmentHistory[1].endDay = utils.addDays(this.departmentHistory[0].startDay, -1)
      }
    },

    /**
     * 部署削除イベント
     */
    deleteHistory() {
      if (this.departmentHistory[0].id) {
        this.deleteDepartment.push(this.departmentHistory[0].id)
      }
      this.departmentHistory.splice(0, 1)
      if (!this.departmentHistory.length) {
        this.departmentHistory = [{
          id: null,
          userId: null,
          departmentId: '',
          departmentSubId: null,
          subList: [],
          startDay: utils.getToday(),
          endDay: '',
        }]
      }
    },

    /**
     * 印鑑 changeイベント
     */
    async changeStamp (event) {
      // 選択されたファイルの取得
      const reader = new FileReader()
        const f = event.target.files[0]
        if (!f) return
        reader.readAsDataURL(f)
        // 変換
        new Promise(() => {
          reader.onload = () => {
            const img = new Image();
            img.src = reader.result;
            
            img.onload = () => {
              // 新しいキャンバスを作成
              const canvas = document.createElement('canvas')
              // キャンバスに描画するためのコンテキストを取得
              const ctx = canvas.getContext('2d')
              // 画像をキャンバスに描画せずに直接リサイズ
              ctx.drawImage(img, 0, 0)
              // キャンバスの画像を再度DataURLに変換して圧縮率を下げる
              const compressedDataUrl = canvas.toDataURL('image/png', 1.0) // 圧縮率を設定（0.7は例です）

              this.user.stampImage = img.src
              this.user.stampUrl = img.src
              this.$refs.imageStamp.src = compressedDataUrl
            }
          }
        })
    },
    
    /**
     * 初期化
     */
    closed() {
      // ユーザーデータ
      this.user = {
        id: null,
        sortNo: null,
        userName: '',
        userKana: '',
        eMailAdress: '',
        userPassword: '',
        chatworkAccountId: '',
        insertUserId: this.$store.getters.user.id,
        stampUrl: 'stamps/none.png'
      }
      // 権限
      this.auth = [
        {item:'adminAuth', auth:false, name: '管理者'},
        {item:'userAuth', auth:false, name: 'ユーザー管理'},
        {item:'estimateApproveAuth', auth:false, name: '見積承認'},
        {item:'mainAuth', auth:false, name: 'メインタスク登録'},
        {item:'subAuth', auth:false, name: 'サブタスク登録'},
        {item:'estimateAuth', auth:false, name: '見積り登録'},
        {item:'patrolAuth', auth:false, name: '品質巡回'},
        {item:'roundCleanAuth', auth:false, name: '巡回清掃'},
        {item:'fixedCleanAuth', auth:false, name: '定期清掃'},
        {item:'facilityPatrolAuth', auth:false, name: '設備点検'},
        {item:'photoReportAuth', auth:false, name: '写真報告'},
        {item:'regularStaffAuth', auth:false, name: '社員面接'},
      ],
      // その他
      this.other = [
        {item:'transmission', flag:false, name: 'デンタツ'},
        {item:'partTimer', flag:false, name: 'パート'},
        {item:'employmentCC', flag:false, name: '雇い入れCC'}
      ],
      // 所属部署履歴
      this.departmentHistory = [{
        id: null,
        userId: null,
        departmentId: '',
        departmentSubId: null,
        subList: [],
        startDay: utils.getToday(),
        endDay: ''
      }]
      // 新規作成
      this.isNew = false
      // 一度保存した部署データを削除したデータ
      this.deleteDepartment = [],
      // 権限全てチェック
      this.all = false
      
      this.$emit('modalClose')
    },

    /**
     * 登録処理
     */
    async register() {
      // 名前チェック
      if (!this.user.userName || this.user.userName == '') {
        await this.errRequired('名前')
        return
      }

      // メアドチェック
      if (!this.user.eMailAdress || this.user.eMailAdress == '') {
        await this.errRequired('メールアドレス')
        return
      } else {
        const dupli = await backend.searchData('user/dupliMail', {mail: this.user.eMailAdress, id: this.user.id})
        if (dupli.data.data) {
          // console.log(dupli.data.data)
          await dialogs.showErrorDialog('メールアドレス重複', 'メールアドレスが過去に使用していたものも含め、重複しています。\r\n1度でも登録したメールアドレスは再度使用できません。\r\nメールアドレスを変更してください。\r\n（同じメールアドレスを使用していた人：' + dupli.data.data.userName + 'さん）')
          return
        }
      }

      // パスチェック
      if (this.isNew) {
        if (!this.user.userPassword || this.user.userPassword == '') {
          await this.errRequired('パスワード')
          return
        }
      }

      // デンタツ確認
      for (let oi = 0; oi < this.other.length; oi++) {
        const o = this.other[oi];
        if (o.item == 'transmission' && o.flag) {
          if (!this.departmentHistory[0].departmentId || this.departmentHistory[0].departmentId == '') {
            await dialogs.showErrorDialog('部署を選択してください。', '「デンタツ」にチェックがついています。\r\n部署を選択してください。')
            return
          }
          let tr = this.$store.getters.transDepartmentList
          let isTrans = false
          for (let tri = 0; tri < tr.length; tri++) {
            if (tr[tri].transDepartment == this.departmentHistory[0].departmentId) {
              isTrans = true
              break
            }
          }
          if (!isTrans) {
            await dialogs.showInfoDialog('デンタツ部署未設定', '選択した部署に紐づく「デンタツ」部署がまだ作られていません。\r\n「部署設定」画面で「デンタツ」部署を作成してください。')
          }
        }
      }

      // 部署チェック
      if (!this.departmentHistory.length || !this.departmentHistory[0].departmentId || this.departmentHistory[0].departmentId == undefined || this.departmentHistory[0].departmentId == '') {
        const dialogResult = await dialogs.showWarningConfirmDialog('部署', '部署が選択されていませんが、よろしいですか？')
        if (dialogResult != 'YES') {
          return
        }
      }

      // 開始日チェック
      for (let i = 0; i < this.departmentHistory.length; i++) {
        const d = this.departmentHistory[i];
        if (d.departmentId != '' && (!d.startDay || d.startDay == '')) {
          await this.errRequired('部署の開始日')
          return
        }
      }

      // mst_user登録
      const userR = await backend.postData('user/save', this.user)

      // mst_auth登録
      let authId = null
      let otherId = null
      let userId = userR.data.data
      if (!this.isNew) {
        authId = this.target.authId
        otherId = this.target.otherId
      }
      if (!userId) {
        alert('登録中にエラーが発生しました。userId取得失敗')
        return
      }
      let authData = {id: authId, userId: userId, insertUser: this.$store.getters.user.id}
      for (let i = 0; i < this.auth.length; i++) {
        const a = this.auth[i]
        if (a.auth) {
          authData[a.item] = true
        } else {
          authData[a.item] = false
        }
      }
      await backend.postData('auth/save', authData)

      // mst_user_other登録
      let otherData = {id: otherId, userId: userId, insertUser: this.$store.getters.user.id}
      for (let otherIdx = 0; otherIdx < this.other.length; otherIdx++) {
        const o = this.other[otherIdx]
        if (o.flag) {
          otherData[o.item] = true
        } else {
          otherData[o.item] = false
        }
      }
      await backend.postData('userOther/save', otherData)

      // mst_user_department登録
      for (let udIdx = 0; udIdx < this.departmentHistory.length; udIdx++) {
        const dh =  this.departmentHistory[udIdx];
        let sDay = ''
        if (dh.startDay && dh.startDay != '') {
          sDay = utils.getDate(dh.startDay)
        }
        let eDay = ''
        if (udIdx == 0) {
          // あり得ない未来日をセット
          eDay = '2999-12-31'
        } else {
          eDay = utils.getDate(dh.endDay)
        }
        let udData = {
          id: dh.id,
          userId: userR.data.data,
          departmentId: dh.departmentId,
          departmentSubId: dh.departmentSubId,
          startDay: sDay,
          endDay: eDay,
          insertUser: this.$store.getters.user.id
        }

        if (dh.departmentId && dh.departmentId != '') {
          const dr = await backend.postData('userDepartment/save', udData)
          if (dr.data != "complete") {
            alert('部署の保存中にエラーが発生しました。')
            console.log(dr)
          }
        }
      }

      // mst_user_department削除
      for (let deIdx = 0; deIdx < this.deleteDepartment.length; deIdx++) {
        const dd = this.deleteDepartment[deIdx];
        await backend.deleteData('userDepartment/delete', {id: dd}, false)
      }

      logManager.recordingByRegist(this, this.isNew, 'ユーザー設定', 'ユーザー（ID：' + userId + '、 ユーザー名：' + this.user.userName + '）')
      this.$emit('modalClose')
      this.closed()
    },

    /**
     * 必須項目アラート
     * @param item 項目名
     */
    async errRequired(item) {
      await dialogs.showErrorDialog(item + 'は必須です', item + 'が入力されていません。')
    },

    /**
     * ツールチップ（課）
     * @param id 部署ID
     * @param sub サブリスト
     */
    tip(id, sub) {
      if (id && id != '' && !sub.length) {
        return '選択した部署に課はありません。'
      } else {
        return ''
      }
    },

    /**
     * 開始日変更イベント
     * @param sDay 開始日
     * @param i インデックス
     */
    changeStartDay(sDay, i) {
      if (sDay && sDay != '' && this.departmentHistory[i+1]) {
        this.departmentHistory[i+1].endDay = utils.addDays(sDay, -1)
      }
    }
  }
}
</script>
<style scoped>
.content-container-app {
  width: 988px;
  height: calc(100vh - 230px);
  overflow: auto;
  padding: 10px;
}

.border-gray {
  border: solid #d3cfcf 1px;
}

.td-height {
  height: 40px;
}

.input {
  height: 50px;
}

.table {
  width: auto;
  margin: auto;
}

.table-width {
  width: 350px;
}

.number {
  width: 40px;
}

.add {
    margin-left: auto;
    margin-right: 35px;
    width: 24px;
}

.btn {
  width: 94%;
  display: flex;
  bottom: 19px;
  position: absolute;
  justify-content:space-between;
}

.app1-color {
  background-color: rgb(253 237 237 / 53%);
}

.department {
  display: flex;
}

.con {
  display: flex;
  justify-content: space-around;
}

.drop {
  width: 92%;
  margin-right: auto;
}

.route {
  height: 20px;
}

.add-color {
  background: darkcyan;
}

.add-color:hover {
  background: rgb(94, 196, 196);
}

.green-check {
  color: rgb(39, 152, 39);
}

.stamp-block {
  position: relative;
}

.upload-button {
  position: absolute;
  top: 40px;
  left: 90px;
}

#imageStamp {
  width: 55px;
  height: 55px;
}

@media screen and (max-width: 910px) {
  .content-container-app {
    width: 900px !important;
    height: calc(100vh - 90px);
    overflow: auto;
  }

  .btn {
    position: static;
    width: 100%;
  }

  .form {
    margin-bottom: 30px;
  }
}
</style>