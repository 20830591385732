<template>
  <div>
    <PageHeader :title="`apollo-RP - ${typeName}`" class="list">
      <template #title-header-content>
        <div class="flex-1 pl-2 flex ml-6 justify-end items-center">
          <PrimaryButton
            text="検索"
            class="ml-auto flex button-contents"
            :buttonContents="'要'"
            :disabled="disabledBtn"
            @click="getData()"
          >
          </PrimaryButton>

          <h1 class="text-lg font-bold text-white flex items-center ml-2">
            <Icon iconName="" class="w-7 h-7 mr-2" /> <span class="mt-0 ">{{ list.length }} 件</span>
          </h1>

          <div class="ml-2">
            <!-- サイクル 未設定 -->
            <div class="ml-auto">
              <PrimaryButton  v-tooltip="type==2?'月1回（毎月）以外で絞る':'3,6,9,12月以外で絞る'" text="例外サイクル" :class="exception ? 'clickButtonColor' : 'normalButtonColor'" @click="filterBtn()"/>
            </div>
          </div>

          <div class="ml-2 flex">
            <PrimaryButton text="クリア" class="normalButtonColor" @click="clear" :buttonContents="'要'" />
          </div>

          <Icon 
            iconName="Refresh" 
            :clickable="false" 
            :class="{'animate-spin': loading}" 
            class="text-white hover:text-gray-300 active:text-gray-400 ml-4 cursor-pointer"
            @click.native="refresh"
          />
        </div>
      </template>
      <template #page-header-content>
        <div class="pl-4 flex items-center">
          <h1 class="text-lg font-bold text-white flex items-center">            
            <Icon iconName="" class="w-7 h-7 mr-2" /> <span class="mt-0">物件一覧</span>
          </h1>
          
          <div class="rounded ml-10">
            <div class="font-bold text-xs mb-1 text-white ml-2 mt-2">
              契約期間
              <span v-if="disabledBtn" class="ml-1 text-red-500"> * 日付を選択してください </span>
            </div>
            <div class="flex items-center">
              <div class="rounded mx-5 mb-2">
                <WmsDateInput
                  name="startDay"
                  v-model="startDay"
                />
              </div>

              <div class="text-white font-bold text-2xl">～</div>

              <div v-if="!startDay || startDay==''" class="rounded mx-5 mb-2">
                <WmsTextInput 
                  name="endDay" 
                  :disabled="true"
                />
              </div>
              
              <div v-else class="rounded mx-5 mb-2">
                <WmsDateInput
                  name="endDay"
                  v-model="endDay"
                  :min="minEndDay"
                />
              </div>
            </div>
          </div>

          <div class="ml-auto mt-5 mr-2">
            <input
              v-tooltip="'記号・スペースは無視します'"
              type="search"
              name="filterSite" 
              placeholder="物件名 検索"
              class="w-full py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900" 
              v-model="filterSite"
            />
          </div>
          <div class="mt-5 mr-2">
            <input
              v-tooltip="'記号・スペースは無視します'"
              type="search"
              name="filterClient" 
              placeholder="得意先名 検索"
              class="w-full py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900" 
              v-model="filterClient"
            />
          </div>
          <div class="mt-5">
            <input
              type="search"
              name="filterAddress" 
              placeholder="住所 検索"
              class="w-full py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900 inline" 
              v-model="filterAddress"
            />
          </div>
        </div>     
      </template>
    </PageHeader>

    <main class="absolute top-32 w-screen bottom-4 mt-8 list">
      <div class="mx-auto px-2 sm:px-4 lg:px-8 h-full">
        <div class="bg-white rounded-md border border-gray-200 overflow-auto h-full">
          <!-- 一覧 -->
          
          <div v-if="loading" class="w-full h-full flex justify-center items-center">
            <p class="text-gray-400 font-bold animate-pulse">Searching...</p>
          </div>

          <div v-if="!loading">

            <div class="rounded main-height">
              <table class="main table-auto  overflow-auto">
                  <thead class="main sticky top-0">
                    <tr v-if="list && list.length" class="border border-gray-200">
                      <th v-for="(c, index) in columns" :key="'c2'+index" scope="col" class="px-5 py-3 text-xs font-medium text-gray-500 tracking-wider bg-colu" :class="c.class">
                        {{c.title}}
                      </th>
                    </tr>
                  </thead>

                <tr v-for="(row, i) in list" :key="'r'+i" class="border border-gray-200 bg-white tracking-wider hover-color" :class="setBgColor(row)">

                  <!-- No -->
                  <td class="b-top text-left px-5 py-3 whitespace-nowrap text-sm font-medium text-gray-500 col-no cursor-not-allowed">
                    {{ i + 1 }}.
                  </td>

                  <!-- 物件名 -->
                  <td class="px-5 py-3 whitespace-pre-line text-sm text-gray-700 min-300" v-tooltip="'物件コード： ' + row.siteCode">
                    {{ row.siteName }}
                    <p v-if="row.dateCancellation" class="text-xs text-blue-500">（解約月 : {{row.dateCancellation}}）</p>
                    <p v-else-if="row.cancelNextFlag&&row.cancelNextFlag=='1'" class="text-xs text-green-500">（次年度解約, 備考：{{row.cancelReason}}）</p>
                  </td>
                  <!-- 棟名称 -->
                  <td v-if="type==3" class="px-5 py-3 whitespace-pre-line text-sm min-164">
                    <div v-if="row.ridgeCode" v-tooltip="'棟コード： ' + row.ridgeCode" class="font-bold text-red-700">{{ row.ridgeName }}</div>
                  </td>
                  <!-- 得意先名 -->
                  <td class="px-5 py-3 whitespace-pre-line text-sm text-gray-700 min-200" v-tooltip="'得意先コード： ' + row.clientCode">
                    {{ row.clientName1 }}
                    <div v-if="row.clientName2" class="text-gray-400 text-xs">
                      {{ row.clientName2 }}
                    </div>
                  </td>
                  <!-- 住所 -->
                  <td class="px-5 py-3 whitespace-pre-line text-sm text-gray-700 min-200">
                    {{ row.siteAddress }}
                  </td>
                  <!-- 巡回担当エリア -->
                  <td v-if="type==1" class="px-5 py-3 whitespace-pre-line text-sm text-gray-700 min-w-130">
                    {{ row.departmentAreaName }}
                  </td>
                  <!-- 点検サイクル -->
                  <td v-if="type==2" class="px-5 py-3 whitespace-pre-line text-sm text-gray-700 min-164">
                    <div v-if="row.term">
                      {{ row.term }}
                    </div>
                    <div v-else>
                      月 {{ row.monthly }} 回 （{{ row.monthes }}）
                    </div>
                  </td>
                  <td v-else class="px-5 py-3 whitespace-pre-line text-sm text-gray-700 min-w-130">
                    {{ row.term }}
                  </td>
                  <!-- Weight -->
                  <td class="px-5 py-3 whitespace-pre-line text-sm text-gray-700 min-164 text-center">
                    {{ row.weight }}
                  </td>
                  <!-- 人数 -->
                  <td class="px-5 py-3 whitespace-pre-line text-sm text-gray-700 min-w-80px text-center">
                    {{ row.people }} <span class="text-xs text-gray-400">人</span>
                  </td>
                  <!-- 編集ボタン -->
                  <td class="px-5 py-0.5 text-center text-sm font-medium border-gray">
                    <div class="flex justify-center w-max m-auto">
                      <PrimaryButton :text="type==1?'通常':'編集'" size=" normal px-2 py-2 btn app-btn-w" @click="edit(row)"/>
                    </div>
                  </td>
                  <!-- クレームサイクル -->
                  <td v-if="type==1" class="px-5 py-3 whitespace-pre-line text-sm text-green-700 min-w-130 max-w-216px">
                    {{ row.claimTerm }}
                  </td>
                  <!-- 設備点検項目 -->
                  <td v-else-if="type==4" class="px-5 py-3 whitespace-pre-line text-sm text-green-700 min-w-100px max-w-216px">
                    <p v-if="row.item" class="box2">デフォルト以外</p>
                    <p v-else class="box">デフォルト</p>
                  </td>
                  <!-- クレームボタン -->
                  <td v-if="type==1" class="px-5 py-0.5 text-center text-sm font-medium border-gray">
                    <div class="flex justify-center w-max">
                      <PrimaryButton text="クレーム" class="bg-teal-600 hover:bg-teal-700 focus:ring-teal-500" @click="editClaim(row)"/>
                    </div>
                  </td>
                  <!-- 定期清掃 -->
                  <td v-else-if="type==3" class="px-5 py-0.5 text-center text-sm font-medium border-gray">
                    <div class="w-max m-auto">
                      <PrimaryButton text="項目" class="bg-teal-600 hover:bg-teal-700 focus:ring-teal-500" @click="editFixedItem(row)"/>
                    </div>
                  </td>
                  <!-- 設備点検 -->
                  <td v-else-if="type==4" class="px-5 py-0.5 text-center text-sm font-medium border-gray">
                    <div class="w-max m-auto">
                      <PrimaryButton text="項目" class="bg-teal-600 hover:bg-teal-700 focus:ring-teal-500" @click="editFacilityItem(row)"/>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </main>
    <!-- 通常期間設定 品質巡回・定期清掃 -->
    <TermModal 
      v-model="openTermModal"
      :target="row"
      :typeName="typeName"
      :type="type"
      :open="openModal"
      @modalClose="modalClose"
    />
    <!-- 通常期間設定 巡回清掃 -->
    <RoundTermModal 
      v-model="openRoundTermModal"
      :target="row"
      :typeName="typeName"
      :type="type"
      :open="openRoundTermModal"
      @modalClose="modalClose"
    />
    <!-- クレーム期間設定 -->
    <ClaimTermModal 
      v-model="openClaimTermModal"
      :target="row"
      :type="type"
      :open="openClaimTermModal"
      @modalClose="modalClose"
    />
    <!-- 項目設定 -->
    <CheckListSettingModal 
      v-model="openCheckListSettingModal"
      :target="row"
      :typeName="typeName"
      :type="type"
      :open="openCheckListSettingModal"
      @modalClose="modalClose"
    />
    <!-- 定期清掃 項目設定ダイアログ -->
    <ItemHistorySettingModal 
      v-model="openFixedItemSettingModal"
      :target="row"
      :tempId="tempId"
      :open="openFixedItemSettingModal"
      @modalClose="closeItemHistorySettingModal"
    />
  </div>
</template>

<script>
import Icon from '@components/Icon.vue'
import PageHeader from '@components/PageHeader.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import TermModal from './TermModal.vue'
import RoundTermModal from '../../RoundClean/components/RoundTermModal.vue'
import ClaimTermModal from './ClaimTermModal.vue'
import CheckListSettingModal from '../components/CheckListSettingModal'
import ItemHistorySettingModal from '../../FixedClean/components/ItemHistorySettingModal.vue'
import WmsDateInput from '@wmscomponents/WmsDateInput.vue'
import WmsTextInput from '@wmscomponents/WmsTextInput.vue'
import { WEIGHT } from '@libs/constants'
import * as utils from '@libs/utils'
import * as moment from 'moment'
import * as backend from '@libs/backend'
import * as dialogs from '@libs/dialogs'
import { APOLLO } from '@libs/constants'

const DEFAULT_TERM = '3,6,9,12'
const PEOPLE = 1

export default {
  components: {
    Icon,
    PrimaryButton,
    PageHeader,
    TermModal,
    RoundTermModal,
    ClaimTermModal,
    CheckListSettingModal,
    ItemHistorySettingModal,
    WmsDateInput,
    WmsTextInput
  },

  props: {
    // タイトル名
    typeName: {
      type: String,
      default: null
    },
    // 区分（1：品質巡回、2：巡回清掃、3：定期清掃、4：設備点検）
    type: {
      type: Number,
      default: null
    },
    // セッションストレージ名
    settionStrageName: {
      type: String,
      default: null
    },
  },

  data() {
    return {
      startDay: moment().format('YYYY-01-01'),
      endDay: moment().format('YYYY-12-31'),
      loading: false,
      
      searchCondition: {
        startDay : '',
        endDay :  '',
        filterSite : '',
        filterClient : '',
        filterAddress : '',
        exception:false
      },
      // カラム
      columns: [
          {title: 'No.', class: 'text-left'},
          {title: '物件名', class: 'text-left'},
          {title: '得意先名/支店名', class: 'text-left'},
          {title: '住所', class: 'text-left'},
          {title: '通常サイクル', class: 'text-left'},
          {title: 'weight', class: 'text-center'},
          {title: '人数', class: 'text-center'},
          {title: '通常編集', class: 'text-center'},
          {title: 'クレームサイクル', class: 'text-left px-1'},
          {title: 'クレーム編集', class: 'text-center'},
      ],
      // 期間 未設定
      exception: false,
      // 物件名絞込み
      filterSite: '',
      // 得意先名絞込み
      filterClient: '',
      // 住所絞込み
      filterAddress: '',
      // テーブルデータ
      original: [],
      // 表示するデータ
      list: [],
      openTermModal: false,
      openRoundTermModal: false,
      openClaimTermModal: false,
      openCheckListSettingModal: false,
      openModal: 0,
      // 定期清掃の項目設定モーダル
      openFixedItemSettingModal: false,
      // 定期清掃テンプレートID
      tempId: null,
      // 編集する行のデータ
      row: {}
    }
  },

  computed: {
    /**
     * 検索ボタン 活性・非活性
     */
    disabledBtn() {
      let result = false
      if (!this.startDay || this.startDay == '' || !this.endDay || this.endDay == '') {
        result = true
      }
      return result
    },

    /**
     * 終了日の最小値
     */
    minEndDay() {
      let result = ''
      if (this.startDay == '') {
        return result
      } else {
        return this.startDay
      }
    }
  },

  created() {
    // 巡回清掃・設備点検の場合、カラム変更
    if ([2, 3].includes(this.type)) {
      if (this.type == 2 || this.type == 3) {
        this.columns[4].title = '施工月'
        this.columns[7].title = '編集'
      }
      this.columns.splice(8, 2)

      // 定期清掃の場合、棟名称追加
      if (this.type == 3) {
        this.columns.splice(2, 0, {title: '棟名称', class: 'text-left'})
        this.columns.push({ title: '項目設定', class: 'text-center' })
      }
    
    // 設備点検
    } else if (this.type == 4) {
      this.columns[7].title = 'サイクル'
      this.columns[8].title = '項目'
      this.columns[9].title = '項目'

    // 品質巡回の場合、カラム追加
    } else {
      this.columns.splice(4, 0, {title: '担当エリア', class: 'text-left'})
    }
    this.getCondition()
    this.getData()
  },

  methods: {
    async refresh() {
      this.loading = true
      await utils.wait(500)
      await this.getData()
      await utils.wait(500)
      this.loading = false
    },

    /**
     * セッションストレージデータ取得
     */
    getCondition(){
      const searchJson = sessionStorage.getItem(this.settionStrageName)
      const getItem = JSON.parse(searchJson)
      
      if(getItem !== null && getItem !== undefined){
        this.startDay = getItem.startDay
        this.endDay = getItem.endDay
        this.filterSite = getItem.filterSite
        this.filterClient = getItem.filterClient
        this.filterAddress = getItem.filterAddress
        this.exception = getItem.exception
      }
    },
    /**
     * データ取得
     */
    async getData() {
      this.loading = true
      this.original = []
      if (!this.startDay || !this.endDay) {
        await dialogs.showErrorDialog('契約期間', '契約期間の範囲指定をしてください。')
        return
      }
      //セッションストレージ保存用
      this.searchCondition.startDay = this.startDay
      this.searchCondition.endDay = this.endDay
      this.searchCondition.filterSite = this.filterSite
      this.searchCondition.filterClient = this.filterClient
      this.searchCondition.filterAddress = this.filterAddress
      this.searchCondition.exception = this.exception 
      //セッションストレージ保存
      this.setSessionStorage(this.searchCondition, this.settionStrageName)

      // AKBSから得意先データ取得
      const akbs = await backend.searchData('akbs/getClientAndSite', {startDate: this.startDay, endDate: this.endDay, filterSite: this.filterSite, filterClient: this.filterClient, filterAddress: this.filterAddress, type: this.type })
      let client = []
      // テストデータを検索にかける
      for (let i = 0; i < APOLLO.length; i++) {
        let apollo = utils.clone(APOLLO)
        const A = apollo[i]
        if (A.siteName.indexOf(this.filterSite) != -1 && A.clientName1.indexOf(this.filterClient) != -1 && A.siteAddress.indexOf(this.filterAddress) != -1) {
          client.push(A)
        }
      }
      
      if (akbs.data.data) {
        client = client.concat(akbs.data.data)
      }
      // 期間マスタ取得
      const termData = await backend.searchData('patrolTerm/get', { type: this.type })

      // 巡回清掃の場合
      if (this.type === 2) {
        if (termData.data && termData.data.data.length) {
          for (let k = 0; k < client.length; k++) {
            const c = client[k]
            let flag = false
            for (let l = 0; l < termData.data.data.length; l++) {
              const td = termData.data.data[l]
              if (c.siteCode == td.siteCode) {
                flag = true
                if (td.week || td.week == 0) {
                  c.term = `毎週${utils.toDayOfWeek(td.week)}曜日`
                } else {
                  // ひと月の回数
                  if (td.monthly) {
                    c.monthly = td.monthly
                  } else {
                    // 無ければデフォルトの月1回
                    c.monthly = 1
                  }
                  // 実践月
                  if (td.monthes && td.monthes != 'NULL') {
                    c.monthes = td.monthes
                  } else {
                    c.monthes = '毎月'
                  }
                }
                // wight
                c.weight = String(td.weight)
                if (!td.people) {
                  c.people = String(PEOPLE)
                } else {
                  c.people = String(td.people)
                }
                break
              }
            }
            if (!flag) {
              c.monthly = 1
              c.monthes = '毎月'
              c.weight = String(WEIGHT)
              c.people = String(PEOPLE)
            }
          }
        } else {
          let clientWithTerm = client.map((c) => {
            return {...c, monthly: 1, weight: String(WEIGHT), people: String(PEOPLE)}
          })
          client = clientWithTerm
        }

      // 巡回清掃以外
      } else {
        if (termData.data && termData.data.data.length) {
          // 定期清掃の場合
          if (this.type == 3) {
            // まず棟の情報を得意先のデータに結合する
            let isRidge = termData.data.data.filter((term) => {
              return (term.ridgeName)
            })
            if (isRidge) {
              for (let k = 0; k < isRidge.length; k++) {
                const isr = isRidge[k]
                for (let l = 0; l < client.length; l++) {
                  const main = client[l]
                  // 該当する物件データがあり、まだ棟のデータが入っていない場合、そのデータに上書き
                  if (main.siteCode == isr.siteCode && main.clientCode == isr.clientCode && !main.ridgeName) {
                    main.ridgeCode = isr.ridgeCode
                    main.ridgeName = isr.ridgeName
                    break
                  
                  // 該当する物件データだが、すでに棟のデータが入っている場合は、データを挿入
                  } else if (main.siteCode == isr.siteCode && main.clientCode == isr.clientCode) {
                    let copy = utils.clone(main)
                    copy.ridgeCode = isr.ridgeCode
                    copy.ridgeName = isr.ridgeName
                    client.splice(l, 0, copy)
                    break
                  }
                }
              }
            }

          // 設備点検の場合、項目設定有無取得
          } else if (this.type == 4) {
            if (termData.data.item && termData.data.item.length) {
              for (let i = 0; i < termData.data.item.length; i++) {
                const it = termData.data.item[i]
                for (let j = 0; j < client.length; j++) {
                  const cl = client[j]
                  if (it.siteCode == cl.siteCode) {
                    cl.item = true
                  }
                }
              }
            }
          }
          for (let j = 0; j < client.length; j++) {
            const c = client[j]
            let flag = false
            for (let i = 0; i < termData.data.data.length; i++) {
              const td = termData.data.data[i]
              // 定期清掃の場合、棟のデータがあるため（棟のデータの紐づけは、得意先コード＆棟コードの両方。棟がない場合は、どちらもnull）
              if (td.siteCode == c.siteCode && (!td.clientCode || td.clientCode == c.clientCode) && (!td.ridgeCode || td.ridgeCode == c.ridgeCode))  {
                flag = true
                if (td.weight) {
                  c.weight = String(td.weight)
                } else {
                  c.weight = String(WEIGHT)
                }
                if (td.people) {
                  c.people = String(td.people)
                } else {
                  c.people = String(PEOPLE)
                }
                if (td.monthes && td.monthes != 'NULL') {
                  c.term = td.monthes
                } else if (td.week || td.week == 0) {
                  c.term = `毎週${utils.toDayOfWeek(td.week)}曜日`
                }
                if (!c.term) {
                  c.term = DEFAULT_TERM
                }

                // クレーム用の期間
                if (td.claimMonthes && td.claimMonthes != 'NULL') {
                  c.claimTerm = td.claimMonthes
                } else if (td.claimWeek || td.claimWeek == 0) {
                  c.claimTerm = `毎週${utils.toDayOfWeek(td.claimWeek)}曜日`
                }
                // クレーム用weight
                if (td.claimWeight) {
                  c.claimWeight = String(td.claimWeight)
                }
                // クレーム用people
                if (td.claimPeople) {
                  c.claimPeople = String(td.claimPeople)
                }
                break
              }
            }
            if (!flag) {
              c.term = DEFAULT_TERM
              c.weight = String(WEIGHT)
              c.people = String(PEOPLE)
            }
          }
        } else {
          let clientWithTerm = client.map((c) => {
            return {...c, term: DEFAULT_TERM, weight: String(WEIGHT), people: String(PEOPLE)}
          })
          client = clientWithTerm
        }
      }
      this.original = client
      this.filtering()
      // 品質巡回の場合、担当エリアも取得
      this.getArea(termData.data)
      this.loading = false
    },

    /**
     * 検索条件をセッションストレージへ保存
     * @param {Object} searchCondition 
     * @param {String} storageName 
     */
    setSessionStorage(searchCondition,storageName) {
      const searchStr = JSON.stringify(searchCondition)
      sessionStorage.setItem(storageName, searchStr)
    },
    /**
     * ボタン切替
     */
    async filterBtn() {
      this.exception = !this.exception
    },

    /**
     * 検索
     */
    filtering() {
      this.list = []
      if (this.original.length) {
        let result = this.original
        let result2 = []
        if (this.exception) {
          if (this.type == 2) {
            result2 = result.filter((o) => {
              return (o.monthly != 1 || o.monthes != '毎月')
            })
          } else {
            result2 = result.filter((o) => {
              return o.term != DEFAULT_TERM
            })
          }
        } else {
          result2 = result
        }
        this.list = result2
      }
    },

    /**
     * 品質巡回の場合、担当エリアを取得
     * @param res エリアデータ
     */
    getArea(res) {
      if (this.type == 1 && res && res.area) {
        const area = res.area
        for (let i = 0; i < area.length; i++) {
          const a = area[i]
          for (let j = 0; j < this.list.length; j++) {
            const l = this.list[j]
            if (a.clientCode == l.clientCode && a.siteCode == l.siteCode) {
              l.departmentArea = a.id
              l.departmentAreaName = a.name
              break
            }
          }
        }
      }
    },

    /**
     * 編集ボタンイベント
     * @prama row 対象データ
     */
    edit(row) {
      // 巡回清掃の場合
      if (this.type == 2) {
        this.openRoundTermModal = true
      } else {
        this.openTermModal = true
        this.openModal++
      }
      this.row = row
    },

    /**
     * クレーム編集ボタンイベント
     * @prama row 対象データ
     */
    editClaim(row) {
      this.openClaimTermModal = true
      this.row = row
    },

    /**
     * モーダル閉じる
     */
    async modalClose() {
      this.openTermModal = false
      this.openRoundTermModal = false
      this.openClaimTermModal = false
      this.openCheckListSettingModal = false
      // リロード
      // this.$router.go({path: this.$router.currentRoute.path, force: true})
      await this.getData()
    },

    /**
     * 行の背景色をセット
     * @param row 対象データ
     */
    setBgColor(row) {
      // 解約月がある場合
      if (row.dateCancellation && row.dateCancellation != '') {
        let target = row.dateCancellation.replace(/年|月/g, '-') + '01'
        target = moment(target).format('YYYYMM')
        let today = moment().format('YYYYMM')
        // 解約月が今月より前の場合、グレー
        if (Number(target) < Number(today)) {
          return 'bg-gray-200'
        }
      }
      // 次年度解約フラグがある場合
      if (row.cancelNextFlag && row.cancelNextFlag == '1') {
        return 'bg-yellow-100'
      }
      return ''
    },

    // 検索条件をクリア
    clear() {
      window.sessionStorage.clear()
      this.$router.go({path: this.$router.currentRoute.path, force: true})
    },

    /**
     * 定期清掃項目編集モーダルopen
     * @param row 対象データ
     */
    editFixedItem(row) {
      this.tempId = null
      this.tempId = utils.getTempId(row.clientName1, row.siteName)
      this.row = row
      this.openFixedItemSettingModal = true
    },

    /**
     * 定期清掃項目編集モーダルclosed
     */
    closeItemHistorySettingModal() {
      this.openFixedItemSettingModal = false
    },

    /**
     * 設備点検
     * @param row 対象データ
     */
    editFacilityItem(row) {
      this.openCheckListSettingModal = true
      this.row = row
    }
  },
}
</script>
<style scoped>
.list {
  overflow: auto;
}

.main {
  width: 100%;
}

.bg-colu {
    background: #ebf0fb;
}

.col-no {
    width: 70px;  
}

.min-300 {
    min-width: 300px;
}

.min-164 {
  min-width: 164px;
}

.min-120 {
  min-width: 121px;
}

.min-100 {
    min-width: 100px;
}

.min-200 {
    min-width: 200px;
}

.icon-green {
  color: darkcyan;
}

.hover-color:hover {
  background: rgb(232, 244, 253) !important;
}

@media screen and (max-width: 1500px) {
  .list {
    min-width: 1500px !important;
  }
}
</style>