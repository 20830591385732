<template>
  <div>
    <PhotoReportComponent
      :typeName="typeName"
      :type="type"
      :commonItem="commonItem"
      :title="title"
      :editSameMonth="editSameMonth"
      :editSameDay="editSameDay"
      :editSameUser="editSameUser"
      :autoDate="autoDate"
      :sendChat="sendChat"
    >
    </PhotoReportComponent>
  </div>
</template>
<script>

import PhotoReportComponent from '../components/PhotoReportComponent.vue'

export default {
  components: {
    PhotoReportComponent
  },
  
  props: ['target'],
  
  data() {
    return {
      typeName: '',
      type: null,
      commonItem: false,
      title: '',
      editSameMonth: false,
      editSameDay: false,
      editSameUser: false,
      autoDate: false,
      sendChat: false
    }
  },

  created() {
    if (!this.target || !this.target.typeName) {
      // 一覧に遷移する
      this.$router.push({ name: 'PhotoReportList' })
    } else {
      this.getTarget()
    }
  },

  methods: {
    getTarget() {
      if (this.target) {
        this.typeName = this.target.typeName
        this.type = this.target.type
        this.commonItem = this.target.commonItem
        this.title = this.target.title
        this.editSameMonth = this.target.editSameMonth
        this.editSameDay = this.target.editSameDay
        this.editSameUser = this.target.editSameUser
        this.autoDate = this.target.autoDate
        this.sendChat = this.target.sendChat
      }
    }
  }

}
</script>

<style scoped>
</style>