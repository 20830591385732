<template>
  <div>
    <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
      <template #action>
        <div class="content-container-app">
          <!-- 説明 -->
          <div class="text-sm text-gray-600">
            途中保存しているデータは、
            <router-link :to="{ name: 'FixedScheduleListByStaff' }" class="text-blue-700 border-b border-blue-700" @click="clickInfo()">予定一覧</router-link>
            から開くことができます。（通常と同様）
          </div>
          <!-- 施工月 -->
          <div class="text-xs font-bold text-gray-700 mb-1 mt-5">
            施工月（期限） <span class="-top-0.5 ml-1 text-red-500"> * </span>
          </div>
          <div class="flex">
            <select
              name="year"
              v-model="year"
              class="text-center border-gray-300 rounded focus:ring-indigo-500 focus:border-indigo-500 block text-sm text-gray-700"
            >
              <option
                v-for="list in yearList" :key='list.val'
                :value="list.val"
                class="bg-white">
                {{ list.val }}
              </option>
            </select>
            <div class="text-gray-600 text-xs font-bold mt-auto mx-2">年</div>
            <select
              name="month"
              v-model="month"
              class="text-center border-gray-300 rounded focus:ring-indigo-500 focus:border-indigo-500 block text-sm text-gray-700"
            >
              <option
                v-for="list in monthList" :key='list.val'
                :value="list.val"
                class="bg-white">
                {{ list.val }}
              </option>
            </select>
            <div class="text-gray-600 text-xs font-bold mt-auto mx-2">月</div>
          </div>

          <!-- 得意先名 -->
          <WmsSuggestInput
            id="client"
            name="client"
            caption="得意先名"
            :clearable="true"
            displayField="clientName1"
            :dynamic="false"
            :incrementalSearch="false"
            :selectionItems="clientList1 || []"
            :required="true"
            class="rounded-md leading-5 border-0 mt-3 text-gray-700"
            v-model="clientName1"
            @selected="selectClient($event)"
          />

          <!-- 支店名 -->
          <WmsSuggestInput
            id="client2"
            name="client2"
            caption="支店名"
            :clearable="true"
            displayField="clientName2"
            :dynamic="false"
            :incrementalSearch="false"
            :selectionItems="clientList2 || []"
            class="rounded-md leading-5 border-0 mt-3 text-gray-700"
            v-model="clientName2"
          />

          <!-- 物件名 -->
          <WmsSuggestInput
            id="site"
            name="site"
            caption="物件名"
            :clearable="true"
            displayField="siteName"
            :dynamic="false"
            :incrementalSearch="false"
            :selectionItems="siteList || []"
            :required="true"
            class="rounded-md leading-5 border-0 mt-3 text-gray-700"
            v-model="siteName"
          />

          <!-- 責任者 -->
          <div class="text-xs font-bold text-gray-700 mt-3 mb-1">
            担当者（責任者） <span class="-top-0.5 ml-1 text-red-500"> * </span>
          </div>
          <select
            name="staff"
            v-model="staffId"
            @change="selectStaff($event)"
            class="border-gray-300 rounded focus:ring-indigo-500 focus:border-indigo-500 block w-full text-sm text-gray-700"
          >
            <option
              v-for="list in staffList" :key='list.id'
              :value="list.userId"
              class="bg-white">
              {{ list.name }}
            </option>
          </select>

          <PrimaryButton
            text="報告書作成"
            class="mt-5 mb-3 w-full"
            @click="save()"
          >
          </PrimaryButton>
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>

// import Icon from '@components/Icon.vue'
import Modal from '@components/Modal.vue'
import * as backend from '@libs/backend'
import WmsSuggestInput from '@wmscomponents/WmsSuggestInput.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import * as dialogs from '@libs/dialogs'
import * as utils from '@libs/utils'
import * as moment from 'moment'

const MONTHES = [
  { val: '01' },
  { val: '02' },
  { val: '03' },
  { val: '04' },
  { val: '05' },
  { val: '06' },
  { val: '07' },
  { val: '08' },
  { val: '09' },
  { val: '10' },
  { val: '11' },
  { val: '12' }
]

export default {
  components: {
    // Icon,
    Modal,
    WmsSuggestInput,
    PrimaryButton,
  },

  props: {
    open: {
      type: Boolean,
      default: false
    },
    isInfo: {
      type: Boolean,
      default: true
    },
  },

  data() {
    return {
      // 年
      year: null,
      // 月
      month: '',
      // 得意先
      clientName1: '',
      // 支店名
      clientName2: '',
      // 物件名
      siteName: '',
      // 責任者
      staff: '',
      // 責任者ID
      staffId: null,

      // 得意先リスト
      clientList1: [],
      // 支店名リスト
      clientList2: [],
      // 物件リスト
      siteList: [],
      // 責任者リスト
      staffList: [],
      // 年リスト
      yearList: [],
      // 月リスト
      monthList: MONTHES
    }
  },

  computed: {
    today() {
      return moment().format('YYYY-MM-DD')
    }
  },

  watch: {
    open() {
      if (this.open) {
        this.getData()
      } else {
        return
      }
    },
    
  },

  methods: {
    /**
     * データ取得
     */
    async getData() {
      // 得意先、物件、スタッフリストを取得
      const res = await backend.searchData('patrolSchedule/getClientAndStaff', { type: 3 })
      if (res.data.clientData) {
        this.clientList1 = res.data.clientData
      }
      if (res.data.siteData) {
        this.siteList = res.data.siteData
      }
      if (res.data.staffData) {
        this.staffList = res.data.staffData
      }
      // ログインユーザーを初期値にする
      const staff = utils.deleteKana(this.$store.getters.user.user_name).replace('　', ' ')
      const staffId = this.$store.getters.user.id
      if (this.staffList && this.staffList.length) {
        let staffData = this.staffList.find((s) => {
          return s.userId == staffId
        })
        if (!staffData || !staffData.userId) {
          staffData = this.staffList.find((s) => {
            return s.name == staff
          })
        }
        if (staffData && staffData.userId) {
          this.staff = staffData.name
          this.staffId = staffData.userId
        }
      }
      // 年リスト生成
      this.year = Number(moment().format('YYYY'))
      this.yearList = [{ val: this.year }]
      this.yearList.push({ val: this.year + 1 })
      this.month = moment().format('MM')
    },

    /**
     * 得意先選択イベント
     * @param e 選択したデータ
     */
    selectClient(e) {
      const res = []
      if (e && e.clientName2) {
        for (let i = 0; i < e.clientName2.length; i++) {
          const c = e.clientName2[i]
          if (c) {
            res.push({ clientName2: c })
          }
        }
      }
      this.clientList2 = res
    },

    /**
     * 責任者選択イベント
     */
    selectStaff() {
      const res = this.staffList.find((s) => {
        return s.userId == this.staffId
      })
      if (res && res.name) {
        this.staff = res.name
      }
    },

    /**
     * 報告書作成ボタン
     */
    async save() {
      if (!this.year) {
        await dialogs.showErrorDialog('施工月の「年」', '施工月は「完了一覧」での表示に必要です。施工月の「年」を選択してください。')
        return
      }
      if (!this.month) {
        await dialogs.showErrorDialog('施工月の「月」', '施工月は「完了一覧」での表示に必要です。施工月の「月」を選択してください。')
        return
      }
      if (!this.clientName1) {
        await dialogs.showErrorDialog('得意先名', '得意先名を入力してください。')
        return
      }
      if (!this.siteName) {
        await dialogs.showErrorDialog('物件名', '物件名を入力してください。')
        return
      }
      if (!this.staff) {
        await dialogs.showErrorDialog('責任者', '責任者を選択してください。')
        return
      }
      // 施工月の月末
      const limit = moment(this.year + '-' + this.month + '-01 00:00:00').endOf('month').format('YYYY-MM-DD')

      const saveData = {
        clientCode: this.clientName1,
        clientName1: this.clientName1,
        clientName2: this.clientName2,
        siteCode: this.siteName,
        siteName: this.siteName,
        limit,
        scheduledDate: this.today,
        staff: this.staff,
        userId: this.staffId,
        sortNo: -1,
        insertUser: this.$store.getters.user.id,
        type: 3,
        claim: false
      }
      const res =await backend.postData('patrolSchedule/save', [saveData])
      this.closed
      // 報告書画面に遷移
      if (res.data.data) {
        // paramにスケジュールIDを渡す
        let scheduleId = res.data.data
        // 点検表に遷移
        this.$router.push({ 
          name: 'FixedCheckListTable',
          query: { scheduleId, claim: false, sub: 0 },
          params: { scheduledDate: this.today, siteLocation: null }
        })
      }

    },

    /**
     * 説明書きクリックイベント
     */
    clickInfo() {
      if (!this.isInfo) {
        this.$emit('closed')
        this.closed
      }
    },

    /**
     * 閉じる
     */
    closed() {
      // 得意先
      this.clientName1 = ''
      // 支店名
      this.clientName2 = ''
      // 物件名
      this.siteName = ''
      // 責任者
      this.staff = ''
      // 責任者ID
      this.staffId = null
      // 得意先リスト
      this.clientList1 = []
      // 支店名リスト
      this.clientList2 = []
      // 責任者リスト
      this.staffList = []
    },
  }
}
</script>
<style scoped>
.content-container-app {
  width: 100%;
}
</style>