<template>
  <div v-if="filterStatus === 'メインタスク未完了'" class="h-full">
    <VirtualDataTable 
      :columns="columns"
      :columnWidth="columnWidth" 
      :columnAlignment="columnAlignment" 
      :rowHeight="80" 
      :data="filteredIncomplete" 
      :selectable="false"
      :rowClassesFunc="rowClassesFunc" 
    />
  </div>
  <div v-else class="h-full">
    <VirtualDataTable 
      :columns="columns"
      :columnWidth="columnWidth" 
      :columnAlignment="columnAlignment" 
      :rowHeight="80" 
      :data="filteredData" 
      :selectable="false"
      :rowClassesFunc="rowClassesFunc" 
    />
  </div>
</template>

<script>
import VirtualDataTable from '@components/VirtualDataTable.vue'
import * as estimateManager from '@managers/estimateManager'
import * as requestPostManager from '@managers/requestPostManager'
import * as utils from '@libs/utils'
import * as helper from '@libs/helper'
import * as constants from '@libs/constants'
import * as moment from 'moment'

const EstimateStatusColorTable = {}
constants.ESTIMATE_STATUS_COLOR.forEach((s) => {
  EstimateStatusColorTable[s.name] = s.color
})

// データ取得する項目
const selectFields = [
  '_id',
  'estimateNo',
  'clientName',
  'buildingName',
  'subject',
  'requirement',
  'withoutTax',
  'totalAmount',
  'estimateDate',
  'expireDate',
  'estimateStatus',
  'approval1',
  'approval2',
  'requestPostName',
  'userStamp',
  'taskRequestId',
  'taskStaff',
  'estimateCreater',
  'approvalFlow1',
  'approvalFlow2',
  'approvalPetitionDate',
  'orderStatus',
  'upDate'
]

// 「キーワード検索」の検索対象項目
const searchKeys = [
  'estimateNo',
  'clientName',
  'buildingName',
  'subject',
  'requirement',
  'taskStaff',
  'estimateCreaterName',
  'requestPostName',
  'approval1',
  'approval2',
  'upDate'
  ]

const searchDateKey = ['estimateDate']

export default {
  components: {
    VirtualDataTable
  },

  props: [ 
    'filterStatus',
    'searchKeyword',
    'period',
    'freeze',
    'taskRequestId',
    'filterUser',
    'filterClient',
    'filterBuliding',
    'filterSubject',
    'filterCategoryName'
  ],

  data() {
    return {
      data: [],
      // メインタスク未完了のデータ（メインタスクも取得）
      incompleteData: [],
      filteredData: [],
      filteredIncomplete:[],
      fetchDate: '',
      // ユーザーが承認者として登録されている見積もりがあるか false:なし
      isApprover: false,
      userId: {},
      columnsFlag: false,
      userName: '',
      // データが多すぎて表示不可
      overData: false,
      incompleteOverData: false
    }
  },

  watch: {

  },

  computed: {
    /**
     * スマホorタブレット
     */
    isMobile() {
      return utils.deviceInfo.isMobile || utils.deviceInfo.isTablet
    },

    /**
     * ユーザーリスト
     */
    userList() {
      return this.$store.getters.userList
    },

    /**
     * カラム
     */
    columns() {
      // カラムを設定
      let columnData = [
        [ { name: 'estimateNo', title: '見積番号', type: 'link', url: '/estimate?estimateNo=${record.estimateNo}' } ],
        [
          { name: 'requestPostName', title: 'サブタスク', type: 'text', style: "" },
          { name: 'requirement', title: '要件', type: 'text', style: "" }
        ],
        [ { name: 'clientName', title: '得意先名', type: 'text' }, { name: 'buildingName', title: '建物名', type: 'text' } ],
        [ { name: 'subject', title: 'お問い合わせ内容', type: 'text'} ],
        [
          { name: 'withoutTax', title: '税抜き', type: 'number', formatter: 'currency', align: 'right' },
          { name: 'totalAmount', title: '税込み', type: 'number', formatter: 'currency', align: 'right' }
        ],
        [ 
          { 
            name: 'estimateDate', 
            title: '作成日', 
            type: 'text',
            valueFunc: rowData => {
              if (rowData.estimateDate) {
                const d = utils.stringToDate(rowData.estimateDate)
                return utils.formatDate(d, '{yyyy}年{MM}月{dd}日')
              } else {
                return '作成日なし'
              }
            }
          }, 
          { 
            name: 'expireDate', 
            title: '期限', 
            type: 'text',
            valueFunc: rowData => {
              if (rowData.expireDate) {
                const d = utils.stringToDate(rowData.expireDate)
                return utils.formatDate(d, '{yyyy}年{MM}月{dd}日')
              } else {
                return '期限なし'
              }
            }
          } 
        ],
        [
          { name: 'taskStaff',
            title: '担当者',
            type: 'text'
          },
          { name: 'estimateCreaterName',
            title: '作成者',
            type: 'text'
          },
          { name: 'approvalPetitionDate',
            title: '',
            type: 'text',
            valueFunc: rowData => {
              if (rowData.approvalPetitionDate) {
                return moment(rowData.approvalPetitionDate).format('MM/DD HH:mm')
              } else {
                return null
              }
            },
            style: 'font-size: small; color: rgb(17 83 123);'
          },
        ],
        [
          {
            name: 'approval1',
            title: '承認者1',
            type: 'badge',
            colorTable: EstimateStatusColorTable,
            style: "min-width: 60px;"
          },
          {
            name: 'approval1Name',
            title: '',
            type: 'text',
            style: "font-size: small;"
          },
          {
            name: 'approval1Date',
            title: '',
            type: 'text',
            style: "font-size: small; color: rgb(17 83 123);"
          },
        ],
        [
          {
            name: 'approval2',
            title: '承認者2',
            type: 'badge',
            colorTable: EstimateStatusColorTable,
            style: "min-width: 60px;"
          },
          {
            name: 'approval2Name',
            title: '',
            type: 'text',
            style: "font-size: small;"
          },
          {
            name: 'approval2Date',
            title: '',
            type: 'text',
            style: "font-size: small; color: rgb(17 83 123);"
          },
        ],
        [
          {
            name: 'statusIcon',
            title: '状態',
            type: 'badge',
            colorTable: EstimateStatusColorTable,
            style: "min-width: 60px;"
          },
          {
            name: 'orderUser',
            title: '',
            type: 'text',
            style: "font-size: small;"
          },
          {
            name: 'oorderDate',
            title: '',
            type: 'text',
            style: "font-size: small; color: rgb(17 83 123);"
          },
        ],
        [ 
          { 
            name: 'upDate', 
            title: '更新日時', 
            type: 'text',
            valueFunc: rowData => {
              return moment(rowData.upDate).format('YYYY/MM/DD')
            }
          }, 
          { 
            name: 'upDate2', 
            title: '', 
            type: 'text',
            valueFunc: rowData => {
              return moment(rowData.upDate).format('HH:mm')
            }
          } 
        ],
        [ { name: 'viewLink', fixedName: '参照', title: '', type: 'link', url: '/EstimateView?estimateNo=${record.estimateNo}' } ],
      ]

      // ユーザーが承認者の場合、承認確認ボタンを表示
      if (this.isApprover) {
        let col = [{ name: 'confirmation', title: '承認', text: '確認',  type: 'button', style: "min-width: 60px;", url: '/EstimateView?estimateNo=${record.estimateNo}&approver=true'}]
        columnData.splice(-2, 0, col)
      }

      // スマホかタブレットの場合カラムの（左からの）順番を変更
      if (this.isMobile) {
        columnData = this.changeColumnSort(columnData)
      }
      return columnData
    },

    /**
     * カラム幅
     */
    columnWidth()  {
      // カラム幅設定
      let width = [
        '120px', // 見積番号
        'auto', // リクエストポスト依頼
        'auto', // 得意先・建物名
        '150px', // お問い合わせ内容
        '120px', // 見積金額
        '140px', // 作成日・期限
        '130px', // 担当者
        '100px', // 承認者1
        '100px', // 承認者2
        '120px', // 状態
        '107px', // 更新日時
        '75px', // 参照
      ]
      // ユーザーが承認者ならカラム幅を追加
      if (this.isApprover) {
        width.splice(-2, 0, '100px')
      }

      // スマホかタブレットの場合カラムの（左からの）順番を変更
      if (this.isMobile) {
        width = this.changeColumnSort(width)
      }
      return width
    },

    /**
     * カラム位置
     */
    columnAlignment() {
      // カラム位置設定
      let alignment = [
        'left', // 見積番号
        'left', // サブタスク
        'left', // お客様・建物名
        'left', // お問い合わせ内容
        'right', // 見積金額
        'center', // 作成日・期限
        'center', // 社内担当者
        'center', // 承認者1
        'center', // 承認者2
        'center', // 状態
        'right', // 更新日時
        'center' // 参照
      ]
      
      // ユーザーが承認者ならカラム位置を追加
      if (this.isApprover) {
        alignment.splice(-2, 0, 'center')
      }

      // スマホかタブレットの場合カラムの（左からの）順番を変更
      if (this.isMobile) {
        alignment = this.changeColumnSort(alignment)
      }
      return alignment
    }
  },

  async created() {
    
    // ログインしているユーザーIDを取得
    this.userId = this.$store.getters.user.id
    // ログインユーザー名を取得
    this.userName = this.$store.getters.user.user_name
    this.userName = utils.deleteKana(this.userName)

    await this.fetchEstimates()
  },

  methods: {
    /**
     * 見積りステータスで絞込み（ステータスタグ）
     */
    filterEstimates() {
      const self = this

      // 「担当の承認」タグの場合
      if (self.filterStatus == '担当の承認待ち') {
        let list = self.data.filter((d) => {
          return d.approveAuth
        })
        // 古い順に確認できるようにsortItemの昇順に並び替え（承認1待ち：申請日、承認2待ち：承認1の承認日）で比較
        list.sort(function(a, b) {
          return a.sortItem - b.sortItem
        })
        self.filteredData = list

      // メインタスクが未完了
      } else if (self.filterStatus === 'メインタスク未完了') {
        self.filteredIncomplete = self.incompleteData

      // 「担当の承認」タグ以外の場合
      } else {
        self.filteredData = self.data.filter((e) => {
          return !self.filterStatus || self.filterStatus === '全て' || e.estimateStatus === self.filterStatus
        })
      }
    },

    /**
     * データ生成
     */
    async fetchEstimates() {
      if (!this.freeze) {
        this.fetchDate = utils.getToday()

        let ids = []
        // メインタスクのstatusがopenのみ取得
        let main = await requestPostManager.searchByStatus(this, 'open')
        for (let i = 0; i < main.length; i++) {
          ids.push(main[i]._id)
        }
        this.incompleteData = await estimateManager.getWithMainIncomplete(this, searchKeys, this.searchKeyword, this.filterClient, this.filterBuliding, this.filterSubject, this.filterCategoryName,  searchDateKey, this.period, selectFields, ids)
        if (this.incompleteData.error) {
          if (this.incompleteData.msg && this.incompleteData.msg.indexOf('limit') > -1) {
            this.incompleteOverData = true
          }
          this.incompleteData = []
        }

        this.overData = false
        this.data = await estimateManager.searchEstimates(this, searchKeys, this.searchKeyword, this.filterClient, this.filterBuliding, this.filterSubject, this.filterCategoryName, searchDateKey, this.period, selectFields)
        if (this.data.error) {
          if (this.data.msg && this.data.msg.indexOf('limit') > -1) {
            this.overData = true
          }
          this.data = []
        }

        for (let i = 0; i < this.data.length; i++) {
          const es = this.data[i]
          // 承認確認ボタンを活性
          let isApp1 = false
          let isApp2 = false

          // 承認待ち画面では、ソートを変更するためプロパティを統一
          // デフォルトは作成日
          es.sortItem = es.estimateDate.replace(/-/g, '').replace(/:/g, '').replace(/ /g, '')

          for (let j = 0; j < this.data[i].approvalFlow1.length; j++) {

            // ユーザーが承認者1として登録されている場合
            if (this.userId == this.data[i].approvalFlow1[j].userId) {
              // 承認列の表示をする
              this.isApprover = true
              // 承認者1として登録されているか true:されている
              isApp1 = true
              break
            }
          }

          for (let j = 0; j < this.data[i].approvalFlow2.length; j++) {
            // ユーザーが承認者2として登録されている場合
            if (this.userId == this.data[i].approvalFlow2[j].userId) {
              // 承認列の表示をする
              this.isApprover = true
              // 承認者2として登録されているか true:されている
              isApp2 = true
              break
            }
          }

          // 承認者1として登録されている場合
          if (isApp1) {
            // ステータスを確認
            if (this.data[i].estimateStatus == '承認申請中' && this.data[i].approval1 == '承認待ち') {
              
              // 承認画面では、申請日の昇順（古いもが上にくる）
              if (es.approvalPetitionDate) {
                es.sortItem = es.approvalPetitionDate.replace(/-/g, '').replace(/:/g, '').replace(/ /g, '')
              }

              // 自分の承認待ちで止まっている案件なら、承認確認ボタンを活性
                for (let j = 0; j < this.data[i].approvalFlow1.length; j++) {
                  if (this.data[i].approvalFlow1[j].userId == this.userId && this.data[i].approvalFlow1[j].status == '未承認') {
                    this.data[i]['approveAuth'] = true
                    break
                  } else {
                    this.data[i]['approveAuth'] = false
                  }
                }
            } else {
              // それ以外は、承認確認ボタンを非活性
              this.data[i]['approveAuth'] = false
            }
          }

          // 承認者2として登録されている場合
          if (isApp2) {
            // ステータスを確認
            if (this.data[i].estimateStatus == '承認申請中' && this.data[i].approval1 == '承認済み') {

              // 承認2もいる場合、承認1が完了していたら承認1の承認日
              if (es.approvalFlow1 && es.approvalFlow1[0].date && es.approval1 == '承認済み') {
                es.sortItem = es.approvalFlow1[0].date.replace(/-/g, '').replace(/:/g, '').replace(/ /g, '')
              }

              // 自分の承認待ちで止まっている案件なら、承認確認ボタンを活性
                for (let j = 0; j < this.data[i].approvalFlow2.length; j++) {
                  if (this.data[i].approvalFlow2[j].userId == this.userId && this.data[i].approvalFlow2[j].status == '未承認') {
                    this.data[i]['approveAuth'] = true
                    break
                  } else {
                    this.data[i]['approveAuth'] = false
                  }
                }
            } else {
              // それ以外は、承認確認ボタンを非活性
              this.data[i]['approveAuth'] = false
            }
          }
        }

        this.incompleteData.forEach((i) =>{
          i.taskStaff = utils.deleteKana(i.taskStaff)
          i.estimateCreaterName = this.getEstimateCreater(i.estimateCreater, i.userStamp)
          i.approval1Name = this.getApprovalName(i.approvalFlow1, i.approval1, 1)
          i.approval2Name = this.getApprovalName(i.approvalFlow2, i.approval2, 2)
          i.approval1Date = this.getApprovalDate(i.approvalFlow1, i.approval1, 1)
          i.approval2Date = this.getApprovalDate(i.approvalFlow2, i.approval2, 2)
          i.statusIcon = this.getStatus(i.estimateStatus, i.orderStatus)
          i.orderUser = this.getOrderUser(i.estimateStatus, i.orderStatus)
          i.oorderDate = this.getOrderDate(i.estimateStatus, i.orderStatus)
        })
        
        this.data.forEach((d) =>{
          d.taskStaff = utils.deleteKana(d.taskStaff)
          d.estimateCreaterName = this.getEstimateCreater(d.estimateCreater, d.userStamp)
          d.approval1Name = this.getApprovalName(d.approvalFlow1, d.approval1, 1)
          d.approval2Name = this.getApprovalName(d.approvalFlow2, d.approval2, 2)
          d.approval1Date = this.getApprovalDate(d.approvalFlow1, d.approval1, 1)
          d.approval2Date = this.getApprovalDate(d.approvalFlow2, d.approval2, 2)
          d.statusIcon = this.getStatus(d.estimateStatus, d.orderStatus)
          d.orderUser = this.getOrderUser(d.estimateStatus, d.orderStatus)
          d.oorderDate = this.getOrderDate(d.estimateStatus, d.orderStatus)
        })

        this.getFilterUser()
        this.filterEstimates()
        
        this.$emit('search-end', this.data, this.incompleteData, this.isApprover, this.overData, this.incompleteOverData)
      }
    },

    /**
     * ログインユーザーで担当者を絞込み
     */
    getFilterUser() {
      const self = this
      // ログインユーザーで担当者を絞込み
      if (self.filterUser) {
        let list = []
        list = self.data.filter((d) => {
          if (d.estimateCreaterName && d.estimateCreaterName.replace(/\s+/g, '') != '' && d.estimateCreaterName.replace(/\s+/g, '').indexOf(this.userName.replace(/\s+/g, '')) != -1) {
            return true
          }
        })
        
        let list2 = []
        list2 = self.incompleteData.filter((d) => {
          if (d.estimateCreaterName && d.estimateCreaterName.replace(/\s+/g, '') != '' &&  d.estimateCreaterName.replace(/\s+/g, '').indexOf(this.userName.replace(/\s+/g, '')) != -1) {
            return true
          }
        })
        // メインタスク未完了以外
        self.data = list

        // メインタスク未完了
        self.incompleteData = list2
      }
    },

    /**
     * 背景色
     */
    rowClassesFunc(rowData) {
      if (helper.estimateIsExpired(rowData, this.fetchDate)) {
        return ['bg-red-100']
      } else {
        return null
      }
    },

    /**
     * 見積最終作成者
     * @param estimateCreater 作成者
     * @param userStamp 印画像URL
     */
    getEstimateCreater(estimateCreater, userStamp) {
      if (estimateCreater && estimateCreater != '') {
        let name = utils.getUserNameById(estimateCreater, this.userList)
        if (name && name != '') {
          name = utils.deleteKana(name)
        }
        return name

      // estimateCreaterがない場合
      } else {
        if (userStamp && userStamp != '') {
          let result = null
          let num = userStamp.length - 8
          result = userStamp.substr(num, 4)
          let name = utils.getUserNameById(result, this.userList)
          if (name && name != '') {
            name = utils.deleteKana(name)
          }
          return name
        } else {
          return null
        }
      }
    },

    /**
     * 承認者の名前を取得
     * @param flow 承認フロー
     * @param status ステータス
     * @param num 承認1か2か
     */
    getApprovalName(flow, status, num) {
      // 承認申請していない場合は表示しない
      if (status == '---') {
        return null
      } else {
        // 承認1の場合
        if (num == 1) {
          if (flow && flow[0] && flow[0].userName != '' && flow[1] && flow[1].userName != '') {
            return utils.getSurname(flow[0].userName) + ', ' + utils.getSurname(flow[1].userName)
          } else if (flow && flow[0] && flow[0].userName != '') {
            return utils.deleteSpace(utils.deleteKana(flow[0].userName))
          } else {
            return null
          }
          
        // 承認2の場合
        } else {
          if (flow && flow[0] && flow[0].userName != '') {
            return utils.deleteSpace(utils.deleteKana(flow[0].userName))
          } else {
            return null
          }
        }
      }
    },

    /**
     * 承認した日を取得
     * @param flow 承認フロー
     * @param status ステータス
     * @param num 承認1か2か
     */
    getApprovalDate(flow, status, num) {
      // 承認申請していない場合は表示しない
      if (status == '---') {
        return null
      } else {
        // 承認1の場合
        if (num == 1) {
          let res = null
          if (flow && flow[0] && flow[0].date) {
            res = moment(flow[0].date).format('MM/DD HH:mm')
          }
          if (flow && flow[1]) {
            let d1 = ' - '
            if (res) {
              d1 = moment(res).format('M/D')
            }
            if (flow[1].date) {
              res = d1 + ', ' + moment(flow[0].date).format('M/D')
            } else {
              if (res) {
                res = d1 + ', - '
              }
            }
            
          }
          return res
        // 承認2の場合
        } else {
          if (flow && flow[0] && flow[0].date) {
            return moment(flow[0].date).format('MM/DD HH:mm')
          } else {
            return null
          }
        }
      }
    },

    /**
     * 状態を取得
     * @param status 見積りステータス
     * @param order 見積り受注のステータス
     */
    getStatus(status, order) {
      let res = status
      if (status == '受注') {
        if (order) {
          if (order.status) {
            res = order.status
          }
        }
      }
      return res
    },

    /**
     * 受注更新者を取得
     * @param order 見積り受注更新時情報
     */
    getOrderUser(status, order) {
      let res = null
      if (status.indexOf('受注') != -1 && order) {
        if (order.done) {
          res = order.done
        } else if (order.create) {
          res = order.create
        }
      }
      return res
    },

    /**
     * 受注更新日時を取得
     * @param order 見積り受注更新時情報
     */
    getOrderDate(status, order) {
      let res = null
      if (status.indexOf('受注') != -1 && order) {
        if (order.doneDate) {
          res = moment(order.doneDate).format('MM/DD HH:mm')
        } else if (order.createDate) {
          res = moment(order.createDate).format('MM/DD HH:mm')
        }
      }
      return res
    },

    /**
     * カラムの並びを変更
     * @param columnData カラムデータ
     */
    changeColumnSort (columnData) {
      // 左から順番を変更
      let cd = []
      let cd2 = []

      // 左に指定されたもの
      let first = [2, 4, 6]
      // 承認者
      if (this.isApprover) {
        first = [2, 4, 6, 10]
      }

      for (let i = 0; i < columnData.length; i++) {
        const c = columnData[i]

        // 得意先名/物件名、税抜/税込、担当者/作成者、承認 の順（指定）
        if (first.includes(i)) {
          cd.push(c)

      // 上記+「サブタスク/要件」「お問い合わせ内容」以外はその他の順番はお任せなので、そのまま
        } else if (![0, 1, 3].includes(i)) {
          cd2.push(c)
        }
      }
      // 指定カラムの後ろに「見積番号」
      cd.push(columnData[0])
      // その後ろに「お問い合わせ内容」
      cd.push(columnData[3])
      // その後ろに「サブタスク/要件」
      cd.push(columnData[1])

      return cd.concat(cd2)
    }
  }
}
</script>