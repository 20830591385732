import PatrolApp from './PatrolApp.vue'
import PatrolInspection from './pages/PatrolInspection.vue'
import PatrolTerm from './pages/PatrolTerm.vue'
import UnPatrolList from './pages/UnPatrolList.vue'
import ScheduleListByStaff from './pages/ScheduleListByStaff.vue'
import CheckList from './pages/CheckList.vue'
import Download from './pages/Download.vue'
import CheckListTable from './pages/CheckListTable.vue'
import PatrolStaffMaster from './pages/PatrolStaffMaster.vue'
import CountUnPatrolByClient from './pages/CountUnPatrolByClient.vue'
import ClaimUnPatrolList from './pages/ClaimUnPatrolList.vue'
import ClaimCheckList from './pages/ClaimCheckList.vue'
import PatrolCheckItemMaster from './pages/PatrolCheckItemMaster.vue'

export default {
  path: '/Patrol',
  component: PatrolApp,
  children: [
    {
      path: '/PatrolInspection',
      name: 'PatrolInspection',
      component: PatrolInspection,
      meta: { requiresAuth: true, title: 'apollo-RP - 点検項目設定' },
      props: true
    },
    {
      path: '/PatrolCheckItemMaster',
      name: 'PatrolCheckItemMaster',
      component: PatrolCheckItemMaster,
      meta: { requiresAuth: true, title: 'apollo-RP - 確認項目設定' },
      props: true
    },
    {
      path: '/PatrolTerm',
      name: 'PatrolTerm',
      component: PatrolTerm,
      meta: { requiresAuth: true, title: 'apollo-RP - 期間設定' },
      props: true
    },
    {
      path: '/UnPatrolList',
      name: 'UnPatrolList',
      component: UnPatrolList,
      meta: { requiresAuth: true, title: 'apollo-RP - 未点検一覧' },
      props: true
    },
    {
      path: '/ScheduleListByStaff',
      name: 'ScheduleListByStaff',
      component: ScheduleListByStaff,
      meta: { requiresAuth: true, title: 'apollo-RP - 予定一覧' },
      props: true
    },
    {
      path: '/CheckList',
      name: 'CheckList',
      component: CheckList,
      meta: { requiresAuth: true, title: 'apollo-RP - 点検一覧' },
      props: true
    },
    {
      path: '/Download',
      name: 'Download',
      component: Download,
      meta: { requiresAuth: true, title: 'apollo-RP - ダウンロード' },
      props: true
    },
    {
      path: '/CheckListTable',
      name: 'CheckListTable',
      component: CheckListTable,
      meta: { requiresAuth: true, title: 'apollo-RP - 点検表' },
      props: true
    },
    {
      path: '/PatrolStaffMaster',
      name: 'PatrolStaffMaster',
      component: PatrolStaffMaster,
      meta: { requiresAuth: true, title: 'apollo-RP - 巡回スタッフ一覧' },
      props: true
    },
    {
      path: '/CountUnPatrolByClient',
      name: 'CountUnPatrolByClient',
      component: CountUnPatrolByClient,
      meta: { requiresAuth: true, title: 'apollo-RP - 得意先毎の未点検' },
      props: true
    },
    {
      path: '/ClaimUnPatrolList',
      name: 'ClaimUnPatrolList',
      component: ClaimUnPatrolList,
      meta: { requiresAuth: true, title: 'apollo-RP - クレーム未点検' },
      props: true
    },
    {
      path: '/ClaimCheckList',
      name: 'ClaimCheckList',
      component: ClaimCheckList,
      meta: { requiresAuth: true, title: 'apollo-RP - クレーム点検一覧' },
      props: true
    }
  ]
}
