import { render, staticRenderFns } from "./RequestPost.vue?vue&type=template&id=6832e294&"
import script from "./RequestPost.vue?vue&type=script&lang=js&"
export * from "./RequestPost.vue?vue&type=script&lang=js&"
import style0 from "./RequestPost.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports