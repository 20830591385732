<template>
  <div>
    <div v-if="loading" class="w-full h-full flex justify-center items-center loading">
      <p class="text-gray-400 font-bold animate-pulse">Searching...</p>
    </div>

    <PageHeader title="雇い入れ集計" class="list">
      <template #title-header-content>
        <div class="flex-1 pl-2 flex ml-6 justify-end items-center">
          <div class="ml-auto flex button-contents">
            <!-- <PrimaryButton text="検索" @click="search" class="min-w-100px" :buttonContents="'要'" /> -->
            <PrimaryButton text="募集媒体色設定" class="min-w-100px" :buttonContents="'要'" @click="openMediaModal=true" />
          </div>
        </div>
      </template>
      <template #page-header-content>
      </template>
    </PageHeader>

    <main class="absolute top-20 w-screen bottom-4 -mt-2">
      <div class="mx-auto px-2 sm:px-4 lg:px-8 h-full">
        <div class="bg-white rounded-md border border-gray-200 ---overflow-auto h-full">

          <div class="h-full w-full">
            <div v-if="list.length" class="rounded-md border border-gray-200 table-h overflow-auto w-full">
              <table class="table text-sm">
                <thead class="th sticky top-0 z-20 bg-colu">
                  <tr class="text-gray-500 text-xs border border-gray-200">
                    <th class="text-center p-2 bg-white border-r border-gray-300" :rowspan="3">登録月</th>
                    <th class="text-center p-2 bg-colu border-r border-b border-gray-300" :colspan="4">DC</th>
                    <th class="text-center p-2 bg-colu2 border-r border-b border-gray-300" :colspan="5">社員</th>
                  </tr>
                  <tr class="text-gray-500 text-xs border border-gray-200">
                    <th class="text-center p-2 bg-colu border-r border-gray-300" :rowspan="2">入社</th>
                    <th class="text-center p-2 bg-colu border-r border-gray-300" :rowspan="2">退職</th>
                    <th class="text-center p-2 bg-colu border-r border-gray-300 whitespace-pre-line" :rowspan="2">入退社差分</th>
                    <th class="text-center p-2 bg-colu border-r border-gray-300 min-w-400px" :rowspan="2">募集媒体</th>
                    <th class="text-center p-2 bg-colu2 border-r border-b border-gray-300" :colspan="3">面接結果</th>
                    <th class="text-center p-2 bg-colu2 border-r border-gray-300" :rowspan="2">退職</th>
                    <th class="text-center p-2 bg-colu2 min-w-400px" :rowspan="2">募集媒体</th>
                  </tr>
                  <tr class="text-gray-500 text-xs border border-gray-200">
                    <th class="text-center p-2 bg-colu2 border-r border-gray-300">採用</th>
                    <th class="text-center p-2 bg-colu2 border-r border-gray-300">不採用</th>
                    <th class="text-center p-2 bg-colu2 border-r border-gray-300">未決</th>
                  </tr>
                </thead>
                <tbody v-for="(row, i) in list" :key="i">
                  <tr class="hover-color border border-gray-200 bg-white">
                    <!-- 登録月 -->
                    <td class="text-center font-bold border-r td">
                      {{ row.insertedDateForm }}
                    </td>

                    <!-- DC採用 -->
                    <td class="text-right border-r td">
                      {{ row.hire }}
                    </td>

                    <!-- DC退職 -->
                    <td class="text-right border-r td">
                      {{ row.retire }}
                    </td>

                    <!-- DC差分 -->
                    <td class="text-right border-r font-bold td" :class="row.diff>0?'text-blue-700':'text-red-700'">
                      {{ row.diff }}
                    </td>

                    <!-- DC募集媒体 -->
                    <td class="td2 border-r">
                      <div v-if="row.medium && row.medium.length" class="min-w-400px flex">
                        <div v-for="(m, j) in row.medium" :key="'m'+j" v-tooltip="`${m.name} : ${m.rate}％ (${m.count}件)`" :style="getMediumStyle(m)">
                          <div class="p-1 text-center truncate">
                            <div class="font-bold">{{ m.name }}</div>
                            <div v-if="m.rate > 10">
                              {{ m.rate + '％ ' + `(${m.count}件)`}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>

                    <!-- 社員入社 -->
                    <td class="text-right border-r td">
                      {{ row.hireRegular }}
                    </td>

                    <!-- DC不採用 -->
                    <td class="text-right border-r td">
                      {{ row.rejectRegular }}
                    </td>

                    <!-- DC未決 -->
                    <td class="text-right border-r td">
                      {{ row.unresolvedRegular }}
                    </td>

                    <!-- 社員退職 -->
                    <td class="text-right border-r td">
                      {{ row.retireRegular }}
                    </td>

                    <!-- DC募集媒体 -->
                    <td class="td2">
                      <div v-if="row.mediumRegular && row.mediumRegular.length" class="min-w-400px flex">
                        <div v-for="(m, j) in row.mediumRegular" :key="'m'+j" v-tooltip="`${m.name} : ${m.rate}％ (${m.count}件)`" :style="getMediumStyle(m)">
                          <div class="p-1 text-center truncate">
                            <div class="font-bold">{{ m.name }}</div>
                            <div>
                              {{ m.rate + '％ ' + `(${m.count}件)`}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>

                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div v-if="!loading && (!list || !list.length)" class="w-full h-full flex justify-center items-center text-gray-400">
            <EmptyMessage
              iconName="Document"
              message="該当する案件はありません。"
            />
          </div>
        </div>
      </div>
    </main>

    <!-- 募集媒体モーダル -->
    <EmploymentMediaModal
      v-model="openMediaModal"
      :open="openMediaModal"
      @close="closeMediaModal()"
    />
  </div>
</template>

<script>
import PageHeader from '@components/PageHeader.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import EmptyMessage from '@components/EmptyMessage.vue'
import EmploymentMediaModal from '../components/EmploymentMediaModal.vue'
import * as employmentListManager from '@managers/employmentListManager'
import * as moment from 'moment'

export default {
  components: {
    PrimaryButton,
    PageHeader,
    EmptyMessage,
    EmploymentMediaModal,
  },

  data() {
    return {
      loading: false,
      // 集計リスト（画面表示用）
      list: [],
      // DCデータ
      listDC: [],
      // 社員データ
      listRegular: [],
      // 募集媒体リストモーダルopen
      openMediaModal: false
    }
  },

  watch: {
  },

  computed: {
  },

  created() {
    this.getData()
  },

  mounted() {
    
  },

  methods: {
    /**
     * データ取得
     */
    async getData() {
      this.loading = true
      let oldData = []
      let newData = []
      let maxDate = null

      // 過去の集計結果を取得
      oldData = await employmentListManager.getOldList(this)

      // 保存された集計結果があった場合
      if (oldData && oldData.length) {
        // MAXの登録日を取得 (YYYY-MMの形)
        maxDate = oldData[0].insertedDate
      }

      // MAXの登録日の次月分以降を集計
      let start = null
      if (maxDate) {
        start = moment(maxDate + '-01 00:00:00').add(1, 'M').format('YYYY-MM-DD HH:mm:ss')
        // console.log(start)
      }
      // 保存されていない登録月から現在までのデータを集計
      newData = await employmentListManager.getNewList(this, start)
      this.saveOldData(newData)

      if (newData && newData.length && oldData && oldData.length) {
        this.list = newData.concat(oldData)
      } else if (newData && newData.length) {
        this.list = newData
      } else if (oldData && oldData.length) {
        this.list = oldData
      } else {
        this.list = []
      }
      
      this.loading = false
    },

    /**
     * 過去の集計を保存
     * @param newData 新たに集計したデータ
     */
    async saveOldData(newData) {
      let saveData = []
      // 比較しやすくするため、数字にする（年月）
      let end = Number(moment().add(-6, 'M').format('YYYYMM'))

      for (let i = 0; i < newData.length; i++) {
        const n = newData[i]
        // 登録月を数字にする
        const tar = Number(n.insertedDate.replace('-', ''))
        // 半年以上前のデータは保存する
        if (tar <= end) {
          saveData.push(n)
        }
      }
      if (saveData && saveData.length) {
        for (let i = 0; i < saveData.length; i++) {
          const s = saveData[i]
          await employmentListManager.register(this, s)
        }
      }
    },

    /**
     * 募集媒体スタイルセット
     * @param m 対象データ
     */
    getMediumStyle(m) {
      let res = `width: ${m.rate}%; `
      if (m.bgColor) {
        res += `background: ${m.bgColor}; `
      } else {
        res += 'border: 1px solid gray; '
      }
      if (m.color) {
        res += `color: ${m.color}; `
      }
      return res
    },

    /**
     * 募集媒体モダール閉じる
     */
    closeMediaModal() {
      this.openMediaModal = false
      this.getData()
    }
  }
}
</script>

<style scoped>

.table-h {
  max-height: calc(100% - 40px);
}

table {
  width: 100%;
}

.bg-colu {
  background: #ebf0fb;
}

.bg-colu2 {
  background: #ebfbf3;
}

.td {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  min-width: 75px;
}

.td2 {
  padding: 2px;
}

.hover-color:hover {
  background: rgba(163, 231, 228, 0.67) !important;
}

</style>