
<template>
  <div>
    <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
      <template #action>
        <div class="content-container-app overflow-auto">
          <div class="flex justify-between">
            <div class="text-lg text-blue-800 font-bold">{{ type==1 || type == 4?'期間設定':'施工月設定' }} </div>
          </div>

          <div class="mt-5 ml-12 mr-12 mb-12">

            <div class="text-gray-600 font-bold mr-auto">
              得意先 :  {{ list.clientName1 }}
              <span v-if="list.clientName2" class="text-gray-400 text-sm ml-3">({{ list.clientName2 }})</span>
            </div>
            <div class="text-gray-600 font-bold mr-auto mt-5">
              物件名 :  {{ list.siteName }}
              <span v-if="list.ridgeCode" class="text-red-700 ml-3">（{{list.ridgeName}}）</span>
            </div>
            <div v-if="type==1" class="mr-auto mt-5 flex">
              <div class="text-gray-600 font-bold">担当エリア : </div>
              <div class="border border-blue-500 ml-5">
                <select
                  name="departmentArea"
                  v-model="list.departmentArea"
                  class="text-center focus:bg-yellow-100 border-gray-300 rounded focus:ring-indigo-500 focus:border-indigo-500 block w-full text-xs drop"
                >
                  <option
                    v-for="dep in departmentList" :key='dep.id'
                    :value="dep.id"
                    class="bg-white">
                    {{ dep.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="mr-auto mt-5 flex">
              <div class="text-gray-600 font-bold">Weight : </div>
              <div class="border border-blue-500 ml-5">
                <NumericTextInput
                  name="weight"
                  v-model="list.weight"
                  class="text-right w-24 py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-transparent" 
                />
              </div>
            </div>
            <div class="mr-auto mt-5 flex">
              <div class="text-gray-600 font-bold">人数 : </div>
              <div class="border border-blue-500 ml-5">
                <NumericTextInput
                  name="people"
                  v-model="list.people"
                  class="text-right w-24 py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-transparent" 
                />
              </div>
            </div>

            <div class="text-gray-600 font-bold mt-5 mr-auto flex">
              <div>期間</div>
              <div class="text-lg text-blue-800 font-bold ml-5">※3，6，9，12月以外にしたい、もしくは戻したい場合のみ設定してください。</div>
            </div>
            <div class="border border-blue-200 p-1">
              <div class="m-3">
                <input type="checkbox" id="month_check" value="1" v-model="termMonth">
                <label for="month_check" class="month"> 月ごと</label>
              </div>
              <div class="flex">
                <PrimaryButton text="1月" size="normal" class="w-full mr-1 ml-1" :class="monthes.includes(1)?'':'bg-gray-400'" @click="setMonthes(1)" />
                <PrimaryButton text="2月" size="normal" class="w-full mr-1" :class="monthes.includes(2)?'':'bg-gray-400'" @click="setMonthes(2)" />
                <PrimaryButton text="3月" size="normal" class="w-full mr-1" :class="monthes.includes(3)?'':'bg-gray-400'" @click="setMonthes(3)" />
                <PrimaryButton text="4月" size="normal" class="w-full mr-1" :class="monthes.includes(4)?'':'bg-gray-400'" @click="setMonthes(4)" />
                <PrimaryButton text="5月" size="normal" class="w-full mr-1" :class="monthes.includes(5)?'':'bg-gray-400'" @click="setMonthes(5)" />
                <PrimaryButton text="6月" size="normal" class="w-full mr-1" :class="monthes.includes(6)?'':'bg-gray-400'" @click="setMonthes(6)" />
                <PrimaryButton text="7月" size="normal" class="w-full mr-1" :class="monthes.includes(7)?'':'bg-gray-400'" @click="setMonthes(7)" />
                <PrimaryButton text="8月" size="normal" class="w-full mr-1" :class="monthes.includes(8)?'':'bg-gray-400'" @click="setMonthes(8)" />
                <PrimaryButton text="9月" size="normal" class="w-full mr-1" :class="monthes.includes(9)?'':'bg-gray-400'" @click="setMonthes(9)" />
                <PrimaryButton text="10月" size="normal" class="w-full mr-1" :class="monthes.includes(10)?'':'bg-gray-400'" @click="setMonthes(10)" />
                <PrimaryButton text="11月" size="normal" class="w-full mr-1" :class="monthes.includes(11)?'':'bg-gray-400'" @click="setMonthes(11)" />
                <PrimaryButton text="12月" size="normal" class="w-full mr-1" :class="monthes.includes(12)?'':'bg-gray-400'" @click="setMonthes(12)" />
              </div>
            </div>
            <div class="border border-blue-200 mt-5 p-1">
              <div class="m-3">
                <input type="checkbox" id="week_check" value="1" v-model="termWeek">
                <label for="week_check" class="week"> 週ごと</label>
              </div>
              <div class="flex">
                <PrimaryButton text="月" size="normal" class="w-full mr-1 ml-1" :class="week=='月'?'':'bg-gray-400'" @click="setWeek('月')" />
                <PrimaryButton text="火" size="normal" class="w-full mr-1" :class="week=='火'?'':'bg-gray-400'" @click="setWeek('火')" />
                <PrimaryButton text="水" size="normal" class="w-full mr-1" :class="week=='水'?'':'bg-gray-400'" @click="setWeek('水')" />
                <PrimaryButton text="木" size="normal" class="w-full mr-1" :class="week=='木'?'':'bg-gray-400'" @click="setWeek('木')" />
                <PrimaryButton text="金" size="normal" class="w-full mr-1" :class="week=='金'?'':'bg-gray-400'" @click="setWeek('金')" />
                <PrimaryButton text="土" size="normal" class="w-full mr-1" :class="week=='土'?'':'bg-gray-400'" @click="setWeek('土')" />
                <PrimaryButton text="日" size="normal" class="w-full mr-1" :class="week=='日'?'':'bg-gray-400'" @click="setWeek('日')" />
              </div>
            </div>
          </div>
          <PrimaryButton text="登録" size="normal" class="w-full" @click="save()" />
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>

import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import NumericTextInput from '@components/NumericTextInput.vue'
import * as utils from '@libs/utils'
import * as dialogs from '@libs/dialogs'
import * as backend from '@libs/backend'
import * as logManager from '@managers/logManager'

const claim = false

export default {
  components: {
    Modal,
    PrimaryButton,
    NumericTextInput,
  },

  props: {
    target: {
      type: Object
    },
    // タイトル名
    typeName: {
      type: String,
      default: null
    },
    // 区分（1：品質巡回、2：巡回清掃、3：定期清掃、4：設備点検）
    type: {
      type: Number,
      default: null
    },
    open: {
      type: Number,
      default: null
    },
  },

  data() {
    return {
      // 表示データ
      list: { id: null, siteCode: null, weight: null, people: null, clientName1: '', siteName: '' },
      monthes: [],
      week: '',
      termMonth: null,
      termWeek: null,
      // 品質巡回のみ、担当エリアリスト
      departmentList: []
    }
  },

  computed: {
  },

  watch: {
    open() {
      this.getTarget()
    },

    termMonth() {
      if (this.termMonth == '1') {
        this.termWeek = null
        this.week = null
      }
    },

    termWeek() {
      if (this.termWeek == '1') {
        this.termMonth = null
        this.monthes = []
      }
    }
  },

  methods: {
    /**
     * 表示するデータ取得
     */
    async getTarget() {
      if (this.target && Object.keys(this.target).length) {
        this.list = utils.clone(this.target)
        // 該当する期間データを取得
        let result = await backend.searchData('patrolTerm/getBySiteCode', { siteCode: this.list.siteCode, claim, type: this.type, ridgeCode: this.list.ridgeCode })
        if (result.data && result.data.data.length) {
          const rd = result.data.data[0]
          this.list.id = rd.id
          if (rd.monthes) {
            this.termMonth = '1'
            let montesNo = rd.monthes.split(',').map((rm) => {
              return Number(rm)
            })
            this.monthes = montesNo
          } else {
            this.termMonth = null
          }
          if (rd.week || rd.week == '0') {
            this.termWeek = '1'
            this.week = utils.toDayOfWeek(rd.week)
          } else {
            this.termWeek = null
          }
        }
        // 品質巡回のみ、担当エリアを取得
        if (this.type == 1) {
          let result2 = await backend.searchData('departmentSub/getForPatrol')
          if (result2.data && result2.data.data) {
            this.departmentList = [{ name: null, id: null }].concat(result2.data.data)
          }
        }
      }
    },

    /**
     * 初期化
     */
    closed() {
      
      // 表示データ
      this.list = {},
      this.monthes = [],
      this.week = '',
      this.termMonth = null,
      this.termWeek = null
    },

    /**
     * 登録処理
     */
    async save() {
      if (!this.list.weight || isNaN(this.list.weight)) {
        await dialogs.showErrorDialog('Wightは必須です', 'Weightを入力してください。')
        return
      }
      if (!this.list.people || isNaN(this.list.people)) {
        await dialogs.showErrorDialog('人数は必須です', '人数を入力してください。')
        return
      }

      // 月の値をソート
      if (this.monthes.length) {
        this.monthes.sort(function (a, b) {
          return a - b
        })

        // 期間がデフォルトと同じなら期間はnullで保存
        if (this.monthes.length == 4) {
          if (this.monthes.join(',') == [3,6,9,12].join(',')) {
            this.monthes = []
            this.termMonth = null
          }
        }
      } else {
        this.termMonth = null
      }
      // 週の期間判定
      if (!this.week || this.week == '') {
        this.termWeek = null
      }

      // 月の場合
      let save = []
      if (this.termMonth) {
        for (let i = 0; i < this.monthes.length; i++) {
          const m = this.monthes[i]
          let startMonthes = null
          const endMonthes = m
          // 期間ごとの開始月
          if (i == 0) {
            startMonthes = this.monthes[this.monthes.length-1] + 1
            if (startMonthes == 13) {
              startMonthes = 1
            }
          } else {
            startMonthes = this.monthes[i-1] + 1
          }
          let saveData = { id: this.list.id, siteCode: this.list.siteCode, weight: this.list.weight, termMonth: true, termWeek: false, startMonthes: startMonthes, endMonthes: endMonthes, week: null, insertUser: this.$store.getters.user.id }
          saveData = this.setSaveData(saveData)
          save.push(saveData)
        }
        await backend.postData('patrolTerm/save', save)

      // 週の場合
      } else {
        let saveData = { id: this.list.id, siteCode: this.list.siteCode, weight: this.list.weight, termMonth: false, termWeek: this.termWeek?true:false, startMonthes: null, endMonthes: null, week: this.week!='' ? utils.toWeekNumber(this.week) : null, insertUser: this.$store.getters.user.id }
        saveData = this.setSaveData(saveData)
        if (this.termWeek) {
          await backend.postData('patrolTerm/save', [saveData])
        }
      }
      // デフォルトに戻る場合
      if (!this.termMonth && !this.termWeek) {
        let saveData = { id: this.list.id, siteCode: this.list.siteCode, weight: this.list.weight, insertUser: this.$store.getters.user.id }
        saveData = this.setSaveData(saveData)
        await backend.postData('patrolTerm/save', [saveData])
      }
      //ログ出力
      await logManager.recordingByRegist(this, false, this.typeName, this.typeName + '（物件名：' + this.target.siteName + '、得意先名：' + this.target.clientName1 + '）')
      this.$emit('modalClose')
      this.closed()
    },

    /**
     * 保存するデータを生成
     * @param saveData 保存データ
     */
    setSaveData(saveData) {
      saveData.clientCode = this.list.clientCode
      saveData.people = this.list.people
      saveData.claim = claim
      saveData.type = this.type
      // 品質巡回の場合、担当エリアも保存
      if (Number(this.type == 1)) {
        saveData.departmentArea = this.list.departmentArea
      }
      // 定期清掃の場合、棟も保存
      else if (Number(this.type == 3) && this.list.ridgeCode) {
        saveData.ridgeCode = this.list.ridgeCode
      }
      return saveData
    },

    setMonthes(value) {
      if (this.monthes.includes(value)) {
        let m = this.monthes.filter((mo => {
          return mo != value
        }))
        this.monthes = m
      } else {
        this.monthes.push(value)
        this.termMonth = '1'
      }
    },

    /**
     * 曜日選択イベント
     * @param value 曜日
     */
    setWeek(value) {
      this.week = value
      this.termWeek = '1'
    }
  }
}
</script>
<style scoped>
.content-container-app {
  width: 930px;
  overflow: auto;
  padding: 10px;
}

.red {
  background: rgb(242, 70, 70);
}

.blue {
  background: rgb(86, 86, 235);
}

.max-w-lg {
  max-width: none !important;
}

.col-no {
  width: 70px;
}

.hover-color:hover {
  color: rgb(11, 118, 200);
}
@media screen and (max-width: 910px) {
  .content-container-app {
    width: 900px !important;
    height: calc(100vh - 90px);
    overflow: auto;
  }

}
</style>