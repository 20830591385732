import PhotoReportApp from './PhotoReportApp.vue'
import PhotoReport from './pages/PhotoReport.vue'
import PhotoReportCounting from './pages/PhotoReportCounting.vue'
import PhotoReportItemSetting from './pages/PhotoReportItemSetting.vue'
import PhotoReportList from './pages/PhotoReportList.vue'

export default {
  path: '/PhotoReport',
  component: PhotoReportApp,
  children: [
    {
      path: '/PhotoReport',
      name: 'PhotoReport',
      component: PhotoReport,
      meta: { requiresAuth: true, title: 'apollo-RP - 報告書' },
      props: true
    },
    {
      path: '/PhotoReportCounting',
      name: 'PhotoReportCounting',
      component: PhotoReportCounting,
      meta: { requiresAuth: true, title: 'apollo-RP - 写真報告集計' },
      props: true
    },
    {
      path: '/PhotoReportItemSetting',
      name: 'PhotoReportItemSetting',
      component: PhotoReportItemSetting,
      meta: { requiresAuth: true, title: 'apollo-RP - 項目設定' },
      props: true
    },
    {
      path: '/PhotoReportList',
      name: 'PhotoReportList',
      component: PhotoReportList,
      meta: { requiresAuth: true, title: 'apollo-RP - 写真報告一覧' },
      props: true
    },
  ]
}
