<template>
  <div>
    <PageHeader title="評価シート" class="list">
      <template #title-header-content>
        <div class="flex-1 flex justify-around items-center">
          <div></div>
          
          <!-- 評価対象者 -->
          <div class="m-auto font-bold w-fit text-gray-50 text-xl flex">
          <div class="mr-3">
            {{ year }} 年度
            <span class="">{{ period==1?'（上期）':'（下期）' }}</span>
          </div>
            {{ userName }}
          </div>

          <!-- 一覧 -->
          <div class="min-w-fit">
            <PrimaryButton class="bg-green-600 mr-2" text="一覧" @click="$router.push({ name: 'EvaluationList' })">
              <Icon iconName="ViewGrid" slot="before" class="h-4 w-4" />
            </PrimaryButton>
          </div>
        </div>
      </template>
    </PageHeader>
    <main class="absolute top-20 overflow-auto bg-white rounded mx-auto w-full">
      <div class="mx-auto px-2 input-form">
        <div class="mt-5 p-2 border-2 border-yellow-400 rounded">
          <div class="font-bold mb-4">
            {{ year }} 年度
            <span class="pl-3">{{ period==1?'上期（2～7月）':'下期（8～1月）' }}</span>
          </div>
          <div class="flex">
            <!-- 入社日 -->
            <div class="flex mb-1 w-1/2 mr-1">
              <div class="py-2 bg-gray-300 items-stretch w-28 text-center rounded-l">
                入社日
              </div>
              <div class="w-full">
                <input
                  type="date"
                  v-model="list.startDate"
                  class="w-full rounded-r border-gray-300"
                  :class="!list.startDate?'bg-red-300':''"
                  @change="saveInfo('startDate')"
                >
              </div>
            </div>
            <!-- 部署名 -->
            <div class="flex mb-1 w-1/2 ml-1">
              <div class="py-2 bg-gray-300 items-stretch w-28 text-center rounded-l">
                部署名
              </div>
              <select
                name="departmentName"
                v-model="list.departmentName"
                :class="!list.departmentName?'bg-red-300':''"
                class="border-gray-300 rounded-r leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900 font-bold w-full"
                @change="changeDepartment()"
              >
                <option
                  v-for="(d, i) in departmentList" :key="'department' + i"
                  :value="d.name"
                  class="bg-white">
                  {{ d.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="flex">
            <!-- 等級 -->
            <div class="flex mb-1 w-1/2 mr-1">
              <div class="py-2 bg-gray-300 items-stretch w-28 text-center rounded-l">
                等&emsp;級
              </div>
              <select
                name="grade"
                v-model="list.grade"
                class="border-gray-300 rounded-r leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900 font-bold w-full"
                :class="!list.grade?'bg-red-300':''"
                @change="saveInfo('grade')"
              >
                <option
                  v-for="(d, i) in [1, 2, 3, 4]" :key="'gra' + i"
                  :value="d"
                  class="bg-white">
                  {{ d }}
                </option>
              </select>
            </div>
            <!-- グループ -->
            <div class="flex mb-1 w-1/2 ml-1">
              <div class="py-2 bg-gray-300 items-stretch w-28 text-center rounded-l">
                グループ
              </div>
              <select
                name="group"
                v-model="list.groupNum"
                class="border-gray-300 rounded-r leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900 font-bold w-full"
                :class="!list.groupNum?'bg-red-300':''"
                @change="saveInfo('groupNum')"
              >
                <option
                  v-for="(d, i) in [1, 2, 3, 4, 5, 6, 7]" :key="'gro' + i"
                  :value="d"
                  class="bg-white">
                  {{ d }}
                </option>
              </select>
            </div>
          </div>

          <div class="py-3 mx-auto">
            <table>
              <thead>
                <tr>
                  <th scope="col" v-for="(col, i) in ['', '前年同期実績', '当期実績', '前年比']" :key="'col0' + i" class="px-2 text-sm">
                    {{ col }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-sm font-bold px-2 whitespace-nowrap">
                    1. 会社売上
                  </td>
                  <td class="px-2 w-1/4">
                    <div class="w-full">
                      <NumericTextInput
                        name="lastYearSales"
                        v-model="list.lastYearSales"
                        class="text-right w-full h-full focus:bg-yellow-100 sm:text-sm border-green-300 rounded"
                        @change="saveInfo('lastYearSales')"
                      />
                    </div>
                  </td>
                  <td class="px-2 w-1/4">
                    <div class="w-full">
                      <NumericTextInput
                        name="sales"
                        v-model="list.sales"
                        class="text-right w-full h-full focus:bg-yellow-100 sm:text-sm border-yellow-300 rounded"
                        @change="saveInfo('sales')"
                      />
                    </div>
                  </td>
                  <td class="px-2 w-1/4">
                    <div class="">
                      <input
                        type="text"
                        :disabled="true"
                        :value="getRateWork(list.lastYearSales, list.sales)"
                        class="w-full border border-gray-300 rounded text-sm text-right"
                      >
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="text-sm font-bold px-2 whitespace-nowrap">
                    2. 部門粗利益
                  </td>
                  <td class="px-2 w-1/4">
                    <div class="w-full">
                      <NumericTextInput
                        name="lastYearGrossProfit"
                        v-model="list.lastYearGrossProfit"
                        class="text-right w-full h-full focus:bg-yellow-100 sm:text-sm border-green-300 rounded"
                        @change="saveInfo('lastYearGrossProfit')"
                      />
                    </div>
                  </td>
                  <td class="px-2 w-1/4">
                    <div class="w-full">
                      <NumericTextInput
                        name="grossProfit"
                        v-model="list.grossProfit"
                        class="text-right w-full h-full focus:bg-yellow-100 sm:text-sm border-yellow-300 rounded"
                        @change="saveInfo('grossProfit')"
                      />
                    </div>
                  </td>
                  <td class="px-2 w-1/4">
                    <div class="">
                      <input
                        type="text"
                        :disabled="true"
                        :value="getRateWork(list.lastYearGrossProfit, list.grossProfit)"
                        class="w-full border border-gray-300 rounded text-sm text-right"
                      >
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="text-sm font-bold px-2 whitespace-nowrap">
                    3. 部門営業利益
                  </td>
                  <td class="px-2 w-1/4">
                    <div class="w-full">
                      <NumericTextInput
                        name="lastYearProfit"
                        v-model="list.lastYearProfit"
                        class="text-right w-full h-full focus:bg-yellow-100 sm:text-sm border-green-300 rounded"
                        @change="saveInfo('lastYearProfit')"
                      />
                    </div>
                  </td>
                  <td class="px-2 w-1/4">
                    <div class="w-full">
                      <NumericTextInput
                        name="profit"
                        v-model="list.profit"
                        class="text-right w-full h-full focus:bg-yellow-100 sm:text-sm border-yellow-300 rounded"
                        @change="saveInfo('profit')"
                      />
                    </div>
                  </td>
                  <td class="px-2 w-1/4">
                    <div class="">
                      <input
                        type="text"
                        :disabled="true"
                        :value="getRateWork(list.lastYearProfit, list.profit)"
                        class="w-full border border-gray-300 rounded text-sm text-right"
                      >
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          
          <div class="flex mt-5">
            <!-- 始末書 -->
            <div class="flex mb-1 mr-1 w-full">
              <div class="w-20 text-xs h-full whitespace-nowrap font-bold py-1 px-2 my-auto bg-gray-200 items-stretch text-center rounded-l">
                始末書<div class="font-normal">（枚数）</div>
              </div>
              <div class="w-full">
                <input
                  type="number"
                  v-model="list.apology"
                  class="text-right w-full h-full focus:bg-yellow-100 sm:text-sm border-gray-300 rounded-r"
                  @change="saveInfo('apology')"
                />
              </div>
            </div>
            <!-- 禁煙 -->
            <div class="flex mb-1 ml-1 w-full">
              <div class="w-20 text-xs h-full whitespace-nowrap font-bold py-3 px-2 my-auto bg-gray-200 items-stretch text-center rounded-l">
                禁煙
              </div>
              <select
                name="noSmoking"
                v-model="list.noSmoking"
                class="border-gray-300 rounded-r leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900 w-full"
                @change="saveInfo('noSmoking')"
              >
                <option
                  v-for="(d, i) in [{val:true, text:'している'}, {val:false, text:'できていない'}]" :key="'smo' + i"
                  :value="d.val"
                  class="bg-white">
                  {{ d.text }}
                </option>
              </select>
            </div>
            <!-- 事故違反 -->
            <div class="flex mb-1 ml-1 w-full">
              <div class="w-20 text-xs h-full whitespace-nowrap font-bold py-3 px-2 my-auto bg-gray-200 items-stretch text-center rounded-l">
                事故違反
              </div>
              <select
                name="violation"
                v-model="list.violation"
                class="border-gray-300 rounded-r leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900 w-full"
                @change="saveInfo('violation')"
              >
                <option
                  v-for="(d, i) in [{val:true, text:'有り'}, {val:false, text:'無し'}]" :key="'smo' + i"
                  :value="d.val"
                  class="bg-white">
                  {{ d.text }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <!-- プロセス評価 -->
        <div class="mt-5 border-2 border-blue-600 rounded pt-2">
          <div class="font-bold mb-3 px-2">プロセス評価 <span class="text-sm text-gray-600">（５：極めて優れている&emsp;４：優れている&emsp;３：標準&emsp;２：劣る&emsp;１：非常に劣る）</span></div>
          <div id="process-form" class="overflow-auto">
            <table class="w-full">
              <thead>
                <tr>
                  <th scope="col" v-for="(col, i) in columns" :key="'col'+i" class="whitespace-nowrap bg-white p-2" :class="i==0?'sticky left-0':''" :rowspan="i==0?2:1" :colspan="i!=0?2:1">
                    {{ col.val }}
                  </th>
                </tr>
                <tr>
                  <th scope="col" v-for="(col, i) in columns1" :key="'col1'+i" class="whitespace-nowrap bg-white p-2">
                    {{ col.val }}
                  </th>
                </tr>
              </thead>
              <tbody v-for="(row, i) in list.item" :key="'row'+i" class="border-t border-blue-400">
                <tr class="hover-color">
                  <!-- 項目 -->
                  <td class="p-2 text-sm whitespace-pre font-bold text-blue-700 sticky left-0 bg-white">
                    {{ row.item }}
                  </td>

                  <td class="pl-2 text-sm" id="pro-1">
                    <input
                      type="number"
                      class="w-14 text-right border border-gray-300 rounded leading-5 focus:bg-yellow-100 focus:ring-white focus:text-gray-900 font-bold"
                      v-model="row.valueSelf1"
                      :disabled="boss"
                      @change="saveProcess($event.target.value, row, 1)"
                    />
                  </td>
                  <td class="pl-1 pr-2 text-sm border-r border-blue-400">
                    <input
                      type="number"
                      class="w-14 text-blue-700 text-right border border-gray-300 rounded leading-5 focus:bg-yellow-100 focus:ring-white focus:text-gray-900 font-bold"
                      v-model="row.valueBoss1"
                      :disabled="!boss"
                      @change="saveProcess($event.target.value, row, 1)"
                    />
                  </td>
                  <td class="pl-2 text-sm" id="pro-2">
                    <input
                      type="number"
                      class="w-14 text-right border border-gray-300 rounded leading-5 focus:bg-yellow-100 focus:ring-white focus:text-gray-900 font-bold"
                      :class="setBgColor(2)"
                      v-model="row.valueSelf2"
                      :disabled="boss||setBgColor(2)!=''"
                      @change="saveProcess($event.target.value, row, 2)"
                    />
                  </td>
                  <td class="pl-1 pr-2 text-sm border-r border-blue-400">
                    <input
                      type="number"
                      class="w-14 text-blue-700 text-right border border-gray-300 rounded leading-5 focus:bg-yellow-100 focus:ring-white focus:text-gray-900 font-bold"
                      :class="setBgColor(2)"
                      v-model="row.valueBoss2"
                      :disabled="!boss||setBgColor(2)!=''"
                      @change="saveProcess($event.target.value, row, 2)"
                    />
                  </td>
                  <td class="pl-2 text-sm" id="pro-3">
                    <input
                      type="number"
                      class="w-14 text-right border border-gray-300 rounded leading-5 focus:bg-yellow-100 focus:ring-white focus:text-gray-900 font-bold"
                      :class="setBgColor(3)"
                      v-model="row.valueSelf3"
                      :disabled="boss||setBgColor(3)!=''"
                      @change="saveProcess($event.target.value, row, 3)"
                    />
                  </td>
                  <td class="pl-1 pr-2 text-sm border-r border-blue-400">
                    <input
                      type="number"
                      class="w-14 text-blue-700 text-right border border-gray-300 rounded leading-5 focus:bg-yellow-100 focus:ring-white focus:text-gray-900 font-bold"
                      :class="setBgColor(3)"
                      v-model="row.valueBoss3"
                      :disabled="!boss||setBgColor(3)!=''"
                      @change="saveProcess($event.target.value, row, 3)"
                    />
                  </td>
                  <td class="pl-2 text-sm" id="pro-4">
                    <input
                      type="number"
                      class="w-14 text-right border border-gray-300 rounded leading-5 focus:bg-yellow-100 focus:ring-white focus:text-gray-900 font-bold"
                      :class="setBgColor(4)"
                      v-model="row.valueSelf4"
                      :disabled="boss||setBgColor(4)!=''"
                      @change="saveProcess($event.target.value, row, 4)"
                    />
                  </td>
                  <td class="pl-1 pr-2 text-sm border-r border-blue-400">
                    <input
                      type="number"
                      class="w-14 text-blue-700 text-right border border-gray-300 rounded leading-5 focus:bg-yellow-100 focus:ring-white focus:text-gray-900 font-bold"
                      :class="setBgColor(4)"
                      v-model="row.valueBoss4"
                      :disabled="!boss||setBgColor(4)!=''"
                      @change="saveProcess($event.target.value, row, 4)"
                    />
                  </td>
                  <td class="pl-2 text-sm" id="pro-5">
                    <input
                      type="number"
                      class="w-14 text-right border border-gray-300 rounded leading-5 focus:bg-yellow-100 focus:ring-white focus:text-gray-900 font-bold"
                      :class="setBgColor(5)"
                      v-model="row.valueSelf5"
                      :disabled="boss||setBgColor(5)!=''"
                      @change="saveProcess($event.target.value, row, 5)"
                    />
                  </td>
                  <td class="pl-1 pr-2 text-sm border-r border-blue-400">
                    <input
                      type="number"
                      class="w-14 text-blue-700 text-right border border-gray-300 rounded leading-5 focus:bg-yellow-100 focus:ring-white focus:text-gray-900 font-bold"
                      :class="setBgColor(5)"
                      v-model="row.valueBoss5"
                      :disabled="!boss||setBgColor(5)!=''"
                      @change="saveProcess($event.target.value, row, 5)"
                    />
                  </td>
                  <td class="pl-2 text-sm">
                    <input
                      type="number"
                      class="w-14 text-right border border-gray-300 rounded leading-5 focus:bg-yellow-100 focus:ring-white focus:text-gray-900 font-bold"
                      :class="setBgColor(6)"
                      v-model="row.valueSelf6"
                      :disabled="boss||setBgColor(6)!=''"
                      @change="saveProcess($event.target.value, row, 6)"
                    />
                  </td>
                  <td class="pl-1 pr-2 text-sm border-r border-blue-400">
                    <input
                      type="number"
                      class="w-14 text-blue-700 text-right border border-gray-300 rounded leading-5 focus:bg-yellow-100 focus:ring-white focus:text-gray-900 font-bold"
                      :class="setBgColor(6)"
                      v-model="row.valueBoss6"
                      :disabled="!boss||setBgColor(6)!=''"
                      @change="saveProcess($event.target.value, row, 6)"
                    />
                  </td>

                  <!-- 自己平均 -->
                  <td class="p-2 text-sm whitespace-nowrap text-right">
                    {{ getAve(row, 'valueSelf') }}
                  </td>
                  <!-- 上司平均 -->
                  <td class="p-2 text-sm whitespace-nowrap text-right font-bold text-blue-700">
                    {{ getAve(row, 'valueBoss') }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- 方針共有点 -->
        <div class="mt-5 border-2 border-pink-500 rounded pt-2">
          <div class="font-bold mb-3 px-2">方針共有点</div>
          <div id="policy-form" class="overflow-auto">
            <table class="w-full">
              <thead>
                <tr>
                  <th scope="col" v-for="(col, i) in columns2" :key="'col2'+i" class="whitespace-nowrap bg-white p-2 text-center" :class="i==0?'sticky left-0':i==1?'sticky left-44':''">
                    {{ col.val }}
                  </th>
                </tr>
              </thead>
              <tbody v-for="(row, i) in list.item2" :key="'row'+i" class="border-pink-300" :class="row.sortNo==8?'':'border-t'">
                <tr class="hover-color2">
                  <!-- 項目 -->
                  <td class="p-2 text-sm whitespace-pre font-bold sticky left-0 bg-white" :class="row.sortNo==8?'text-gray-700':'text-pink-700'">
                    {{ row.item }}
                  </td>
                  <!-- 目標 -->
                  <td class="p-2 text-sm whitespace-nowrap font-bold text-gray-500 sticky left-44 bg-white">
                    {{ row.sortNo==2?goalThanks:row.goal }} {{ row.unitName }}
                  </td>

                  <td class="p-2 text-sm">
                    <input
                      v-if="row.sortNo!=8"
                      :type="[7].includes(row.sortNo)?'tel':'number'"
                      class="w-20 text-right border border-gray-300 rounded leading-5 focus:bg-yellow-100 focus:ring-white focus:text-gray-900 font-bold"
                      :class="isDisabled(1, row)?'bg-gray-200':''"
                      :disabled="isDisabled(1, row)"
                      v-model="row.value1"
                      @change="savePolicy(row, 1)"
                    />

                    <select
                      v-else
                      name="departmentName"
                      v-model="row.value1"
                      class="border-gray-300 focus:ring-white w-20 rounded text-xs px-1 select-disp"
                      @change="changePolicyDepartment(row, 1)"
                    >
                      <option
                        v-for="(d, i) in departmentList" :key="'depa1' + i"
                        :value="d.name"
                      >
                        {{ d.name }}
                      </option>
                    </select>
                  </td>
                  <td class="p-2 text-sm">
                    <input
                      v-if="row.sortNo!=8"
                      :type="[7].includes(row.sortNo)?'tel':'number'"
                      class="w-20 text-right border border-gray-300 rounded leading-5 focus:bg-yellow-100 focus:ring-white focus:text-gray-900 font-bold"
                      :class="isDisabled(2, row)?'bg-gray-200':''"
                      :disabled="isDisabled(2, row)"
                      v-model="row.value2"
                      @change="savePolicy(row, 2)"
                    />

                    <select
                      v-else
                      name="departmentName"
                      v-model="row.value2"
                      class="border-gray-300 focus:ring-white w-20 rounded text-xs px-1 select-disp"
                      @change="changePolicyDepartment(row, 2)"
                    >
                      <option
                        v-for="(d, i) in departmentList" :key="'depa1' + i"
                        :value="d.name"
                      >
                        {{ d.name }}
                      </option>
                    </select>
                  </td>
                  <td class="p-2 text-sm">
                    <input
                      v-if="row.sortNo!=8"
                      :type="[7].includes(row.sortNo)?'tel':'number'"
                      class="w-20 text-right border border-gray-300 rounded leading-5 focus:bg-yellow-100 focus:ring-white focus:text-gray-900 font-bold"
                      :class="isDisabled(3, row)?'bg-gray-200':''"
                      :disabled="isDisabled(3, row)"
                      v-model="row.value3"
                      @change="savePolicy(row, 3)"
                    />

                    <select
                      v-else
                      name="departmentName"
                      v-model="row.value3"
                      class="border-gray-300 focus:ring-white w-20 rounded text-xs px-1 select-disp"
                      @change="changePolicyDepartment(row, 3)"
                    >
                      <option
                        v-for="(d, i) in departmentList" :key="'depa1' + i"
                        :value="d.name"
                      >
                        {{ d.name }}
                      </option>
                    </select>
                  </td>
                  <td class="p-2 text-sm">
                    <input
                      v-if="row.sortNo!=8"
                      :type="[7].includes(row.sortNo)?'tel':'number'"
                      class="w-20 text-right border border-gray-300 rounded leading-5 focus:bg-yellow-100 focus:ring-white focus:text-gray-900 font-bold"
                      :class="isDisabled(4, row)?'bg-gray-200':''"
                      :disabled="isDisabled(4, row)"
                      v-model="row.value4"
                      @change="savePolicy(row, 4)"
                    />

                    <select
                      v-else
                      name="departmentName"
                      v-model="row.value4"
                      class="border-gray-300 focus:ring-white w-20 rounded text-xs px-1 select-disp"
                      @change="changePolicyDepartment(row, 4)"
                    >
                      <option
                        v-for="(d, i) in departmentList" :key="'depa1' + i"
                        :value="d.name"
                      >
                        {{ d.name }}
                      </option>
                    </select>
                  </td>
                  <td class="p-2 text-sm">
                    <input
                      v-if="row.sortNo!=8"
                      :type="[7].includes(row.sortNo)?'tel':'number'"
                      class="w-20 text-right border border-gray-300 rounded leading-5 focus:bg-yellow-100 focus:ring-white focus:text-gray-900 font-bold"
                      :class="isDisabled(5, row)?'bg-gray-200':''"
                      :disabled="isDisabled(5, row)"
                      v-model="row.value5"
                      @change="savePolicy(row, 5)"
                    />

                    <select
                      v-else
                      name="departmentName"
                      v-model="row.value5"
                      class="border-gray-300 focus:ring-white w-20 rounded text-xs px-1 select-disp"
                      @change="changePolicyDepartment(row, 5)"
                    >
                      <option
                        v-for="(d, i) in departmentList" :key="'depa1' + i"
                        :value="d.name"
                      >
                        {{ d.name }}
                      </option>
                    </select>
                  </td>
                  <td class="p-2 text-sm">
                    <input
                      v-if="row.sortNo!=8"
                      :type="[7].includes(row.sortNo)?'tel':'number'"
                      class="w-20 text-right border border-gray-300 rounded leading-5 focus:bg-yellow-100 focus:ring-white focus:text-gray-900 font-bold"
                      :class="isDisabled(6, row)?'bg-gray-200':''"
                      :disabled="isDisabled(6, row)"
                      v-model="row.value6"
                      @change="savePolicy(row, 6)"
                    />

                    <select
                      v-else
                      name="departmentName"
                      v-model="row.value6"
                      class="border-gray-300 focus:ring-white w-20 rounded text-xs px-1 select-disp"
                      @change="changePolicyDepartment(row, 6)"
                    >
                      <option
                        v-for="(d, i) in departmentList" :key="'depa1' + i"
                        :value="d.name"
                      >
                        {{ d.name }}
                      </option>
                    </select>
                  </td>

                  <!-- 合計 -->
                  <td class="p-2 text-sm whitespace-nowrap text-right">
                    <div>{{ getTotal(row) }}</div>
                  </td>
                  <!-- 達成率 -->
                  <td class="p-2 text-sm whitespace-nowrap text-right text-gray-600">
                    <div v-if="row.goal">{{ getRate(row) }} ％</div>
                  </td>
                  <!-- 評価点 -->
                  <td class="p-2 text-sm whitespace-nowrap text-right">
                    <div v-if="row.goal" class="font-bold text-pink-700">{{ getPoint(row) }} 点</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="h-10"></div>
        <div class="text-transparent">{{reload}}</div>
      </div>
    </main>
  </div>
</template>
<script>

import Icon from '@components/Icon.vue'
import PageHeader from '@components/PageHeader.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import NumericTextInput from '@components/NumericTextInput.vue'
import * as dialogs from '@libs/dialogs'
import * as utils from '@libs/utils'
import * as backend from '@libs/backend'
import * as moment from 'moment'

const ROW = {
  // プロセス評価
  item: [{}],
  // 方針共有
  item2: [{}]
}

export default {
  components: {
    Icon,
    PageHeader,
    PrimaryButton,
    NumericTextInput
    // TrashIcon,
    // PlusCircleIcon
  },

  props: ['target'],

  data() {
    return {
      reload: 0,

      list: utils.clone(ROW),

      // プロセスのカラム
      columns: [
        { val: '項目' },
      ],
      columns1: [],

      // 方針共有点のカラム
      columns2: [
        { val: '' },
        { val: '目標' }
      ],

      // 表示している期間
      year: null, // 年度
      period: null, // 上期：1、下期：2

      // 評価対象者
      userName: '',
      userId: null,

      // 部署リスト
      departmentList: [],

      // 上司
      boss: false,

      // 編集不可
      notEdit: false
    }
  },

  async created() {
    // 部署リスト取得
    this.departmentList = utils.clone(this.$store.getters.departmentList)
    // 上司フラグ
    const user = this.$store.getters.loginUserData
    this.boss = user.boss

    // データ取得
    await this.getData()
  },

  computed: {
    /**
     * スマホ
     */
    isMobileDevice() {
      return utils.deviceInfo.isMobile
    },

    /**
     * 業績
     */
    getRateWork() {
      return (last, now) => {
        if (last && now && !isNaN(last) && !isNaN(now)) {
          let res = Math.floor(Number(now) * 100 / Number(last))
          return res + '％'
        }
        return ''
      }
    },

    /**
     * 来月以降場合、背景色をグレーにする
     */
    setBgColor() {
      return (val) => {
        if (this.columns && this.columns.length) {
          // 今年
          const thisYear = Number(moment().format('YYYY'))
          // 今月
          const mm = Number(moment().format('M'))
          if (mm == 1) {
            if (thisYear - 1 != this.year) {
              return 'bg-gray-200'
            }
          } else {
            if (mm == 2) {
              if (thisYear - 1 != this.year && thisYear != this.year) {
                return 'bg-gray-200'
              }
            } else if (thisYear != this.year) {
              return 'bg-gray-200'
            }
            // 来月以降はグレー
            let tar = mm - 1
            if (this.period == 2) {
              tar = mm - 7
            }
            if (val > tar) {
              return 'bg-gray-200'
            }
          }
        }
        return ''
      }
    },

    /**
     * 方針共有非活性判定
     */
    isDisabled() {
      return (i, row) => {
        if (row.sortNo == 5) {
          if (this.list.groupNum <= 3) {
            return true
          }
        }
        if (this.setBgColor(i)) {
          return true
        }
        return false
      }
    },

    /**
     * プロセス評価の平均
     */
    getAve() {
      return (row, tar) => {
        let num = 0
        let total = 0
        for (let i = 1; i <= 6; i++) {
          const t = tar + i
          if (row[t] && !isNaN(row[t])) {
            num++
            total += Number(row[t])
          }
        }
        if (num && total) {
          return Math.round(total * 10 / num) / 10
        }
        return 0
      }
    },

    /**
     * 方針共有の合計
     */
    getTotal() {
      return (row) => {
        if (row) {
          if (row.sortNo == 8) {
            return null
          }
          let res = 0
          for (let j = 1; j <= 6; j++) {
            const tar = 'value' + j
            if (row[tar] && !isNaN(row[tar])) {
              res += Number(row[tar])
            }
          }
          return res
        } else {
          return 0
        }
      }
    },

    /**
     * 方針共有の達成率
     */
    getRate() {
      return (row) => {
        if (row) {
          if (row.sortNo == 2) {
            if (isNaN(this.goalThanks)) {
              return 0
            }
          } else if (!row.goal || isNaN(row.goal)) {
            return 0
          }
          const val = this.getTotal(row)
          if (!val) {
            return 0
          }

          let res = 0
          if (row.sortNo == 2) {
            res = val * 100 / Number(this.goalThanks)
          } else {
            res = val * 100 / Number(row.goal)
          }
          res = Math.floor(res)
          
          return res
        }
        return 0
      }
    },

    /**
     * 方針共有の評価点
     */
    getPoint() {
      return (row) => {
        if (row) {
          if (row.sortNo == 2) {
            if (isNaN(this.goalThanks)) {
              return 0
            }
          } else if (!row.goal || isNaN(row.goal)) {
            return 0
          }
          const val = this.getRate(row)
          if (!val) {
            return 0
          }

          if (val < 20) {
            return 1
          } else if (val < 40) {
            return 2
          } else if (val < 60) {
            return 4
          } else if (val < 80) {
            return 6
          } else if (val < 100) {
            return 8
          } else {
            return 10
          }
        }
        return 0
      }
    },

    /**
     * サンクスカードの目標
     */
    goalThanks() {
      if (this.list.groupNum) {
        if (this.list.groupNum >= 3) {
          return '120'
        } else {
          return '60'
        }
      }
      return '60・120'
    }
  },

  methods: {
    /**
     * データ取得
     */
    async getData() {
      this.loading = true
      window.scroll({ top: 0 })
      this.list = utils.clone(ROW)
      let periodId = null
      let startDay = ''
      let endDay = ''
      let res

      // カラムを生成
      const mm = Number(moment().format('M'))
      // 上半期
      if (mm >= 2 && mm <= 7) {
        for (let i = 0; i < 6; i++) {
          this.columns.push({ val: (2 + i) + '月' })
          this.columns1.push({ val: '自己' })
          this.columns1.push({ val: '上司' })
          this.columns2.push({ val: (2 + i) + '月' })
        }
      // 下半期
      } else {
        for (let i = 0; i < 5; i++) {
          this.columns.push({ val: (8 + i) + '月' })
          this.columns1.push({ val: '自己' })
          this.columns1.push({ val: '上司' })
          this.columns2.push({ val: (8 + i) + '月' })
        }
        this.columns.push({ val: '1月' })
        this.columns1.push({ val: '自己' })
        this.columns1.push({ val: '上司' })

        this.columns2.push({ val: '1月' })
      }
      this.columns.push({ val: '平均' })
      this.columns1.push({ val: '自己' })
      this.columns1.push({ val: '上司' })
      this.columns2.push({ val: '合計' })
      this.columns2.push({ val: '達成率' })
      this.columns2.push({ val: '評価点' })

      // 編集の場合
      if (this.$route.query.id) {
        periodId = this.$route.query.id
        // 評価対象者
        this.userName = this.target.userName
        this.userId = this.target.userId
        // 年度
        this.year = this.target.year
        // 期
        this.period = this.target.period

      // 新規
      } else {
        // 基本情報の期
        const mm = Number(moment().format('M'))
        if (mm >= 2 && mm <= 7) {
          this.period = 1
        } else {
          this.period = 2
        }
        // 基本情報の年度
        this.year = Number(moment().format('YYYY'))
        if (mm == 1) {
          this.year--
        }
        // 評価対象者
        this.userName = utils.deleteKana(this.$store.getters.user.user_name)
        this.userId = this.$store.getters.user.id
        // 上司フラグ解除
        this.boss = false
      }

      startDay = this.year + '-' + ('00' + this.columns[1].val.replace('月', '')).slice(-2) + '-01'
      const endM = ('00' + this.columns[6].val.replace('月', '')).slice(-2)
      if (endM == '01') {
        endDay = (this.year + 1) + '-' + endM + '-01'
      } else {
        endDay = this.year + '-' + endM + '-01'
      }

      res = await backend.searchData('Evaluation/getData', { periodId, year: this.year, period: this.period, userName: this.userName, userId: this.userId, startDay, endDay })
      if (res.data.data) {
        this.list = res.data.data

        // 期ごとのデータが未登録の場合
        if (!this.list.id) {
          // 所属部署を初期値で入れる
          const userInfo = this.$store.getters.loginUserData
          this.list.departmentId = userInfo.departmentId
          this.list.departmentName = userInfo.departmentName
          if (!this.list.departmentName) {
            this.list.departmentName = ''
            this.list.departmentId = '0'
          }
          this.saveInfo('departmentId')
        }
      }
      await this.setNowDepartment()

      await utils.wait(500)

      // 2，3，8，9月以外は右にスクロールする
      if (![2, 3, 8, 9].includes(mm)) {
        const processForm = document.getElementById('process-form')
        let left = 0
        if ([4, 10].includes(mm)) {
          left = 180
        } else if ([5, 11].includes(mm)) {
          left = 360
        } else if ([6, 12].includes(mm)) {
          left = 540
        } else {
          left = 720
        }

        if (processForm) {
          processForm.scrollLeft = left
        }
      }

      // 6,7,12,1月の場合、右にスクロールする
      if ([6, 7, 12, 1].includes(mm)) {
        const policyForm = document.getElementById('policy-form')

        if (policyForm) {
          policyForm.scrollLeft = 300
        }
      }

      if (this.boss) {
        // 自分の分なら上司フラグ解除
        if (this.userName == utils.deleteKana(this.$store.getters.user.user_name) || this.$store.getters.user.id == this.userId) {
          this.boss = false
        }

        // 大野さん以外
        if (this.$store.getters.user.id != '6244') {
          // 評価対象と部署が違う場合
          const userInfo = this.$store.getters.loginUserData
          if (userInfo.departmentName != this.list.departmentName) {
            alert('部署が違います。\r\n' + `${this.userName}さんの評価シートの部署は${this.list.departmentName}で登録しています。\r\nあなたの部署は${userInfo.departmentName}なので、更新はできません。`)
            this.notEdit = true
          }
        }
      }
      
      this.loading = false
    },
    
    /**
     * 基本情報変更イベント
     * @param tar 対象プロパティ名
     */
    async saveInfo(tar) {
      if (this.notEdit) {
        alert('対象者と部署が違うため、入力値の保存はしていません。')
        return
      }
      if (tar == 'grade' && !this.list.groupNum) {
        this.list.groupNum = this.list.grade
        this.saveInfo('groupNum')
      }
      const saveData = this.commonSaveData()
      const list = utils.clone(this.list)
      delete list.item
      delete list.item2

      try {
        const res = await backend.postData('evaluation/saveEvaluationPeriod', { ...saveData, ...list, tar })
        if (!this.list.id) {
          if (res.data.data) {
            this.list.id = res.data.data
          }
        }
      } catch (error) {
        alert('エラーが発生しました\r\n' + error)
      }
      this.reload++
    },

    /**
     * 早めに記入してほしい箇所を警告
     */
    isAlert() {
      const l = this.list
      // 本人入力の場合
      if (!this.boss) {
        const check = [
          { name: '入社日', tar: l.startDate, msg: '入力' },
          { name: '部署', tar: l.departmentId, msg: '選択' },
          { name: '等級', tar: l.grade, msg: '選択' },
          { name: 'グループ', tar: l.groupNum, msg: '選択' }
        ]
        for (let i = 0; i < check.length; i++) {
          const c = check[i]
          if (!c.tar) {
            alert(`${c.name}が未${c.msg}です。${c.msg}してください。`)
            return
          }
        }
      }
    },

    /**
     * プロセス評価保存
     * @param row 保存対象の行
     * @param i 保存対象の列番号
     */
    async saveProcess(e, row, i) {
      if (this.notEdit) {
        alert('対象者と部署が違うため、入力値の保存はしていません。')
        return
      }
      this.isAlert()
      if (!e || e == 0 || e > 5) {
        alert('不正な値です。プロセス評価は１～５で判定してください。')
        if (this.boss) {
          row['valueBoss' + i] = null
        } else {
          row['valueSelf' + i] = null
        }
        return
      }
      const saveData = this.commonSaveData('item', row, i)
      if (this.boss) {
        saveData.boss = this.boss
        saveData.bossName = saveData.insertUserName
      }
      try {
        const res = await backend.postData('evaluation/saveProcess', saveData)
        if (res.data.data) {
          if (res.data.data.periodId && !this.list.id) {
            this.list.id = res.data.data.periodId
          }
        }
      } catch (error) {
        alert('エラーが発生しました\r\n' + error)
      }
    },

    /**
     * 方針共有点保存
     * @param row 保存対象の行
     * @param i 保存対象の列番号
     */
    async savePolicy(row, i) {
      if (this.notEdit) {
        alert('対象者と部署が違うため、入力値の保存はしていません。')
        return
      }
      this.isAlert()
      if (row.sortNo == 7) {
        row['value' + i] = utils.hankaku(row['value' + i])

        if (row['value' + i] == '/') {  
          for (let j = 0; j < this.list.item2.length; j++) {
            const item = this.list.item2[j]
            if (item.sortNo == 8) {
              if (!item['value' + i]) {
                alert('「/」はラウンド管理部、もしくは品質管理部のみ記載できます。下の所属部門から該当する部門を選択してください。')
                row['value' + i] = 0
                return
              } else if (item['value' + i].indexOf('ラウンド') == -1 && item['value' + i].indexOf('品質管理') == -1) {
                alert('「/」は下の所属部門がラウンド管理部、もしくは品質管理部のみ記載できます。')
                row['value' + i] = 0
                return
              }
            }
          }
        }
      }
      const saveData = this.commonSaveData('item2', row, i)

      try {
        const res = await backend.postData('evaluation/savePolicy', saveData)
        if (res.data.data) {
          if (res.data.data.periodId && !this.list.id) {
            this.list.is = res.data.data.periodId
          }
        }
      } catch (error) {
        alert('エラーが発生しました\r\n' + error)
      }
    },

    /**
     * 保存時の共通項目
     * @param type 保存対象の種類（プロセス評価：item、方針共有：item2、他はnull）
     * @param row 保存対象の行
     * @param i 保存対象の列番号
     */
    commonSaveData(type, row, i) {
      // 期ごとのデータID
      let periodId = this.list.id
      // 年度
      let year = this.year
      // 月
      let month
      // 対象日（年度ではなく、当年。日付は1日で統一）
      let day
      // プロセス評価、方針共有の保存対象の値
      let value
      // 該当月が全て完了した場合、そのプロパティー名
      let target = { tar: '', res: true  }
      // ソート番号
      let sortNo
      // サブソート番号
      let subSortNo

      const saveData = utils.clone(this.list)
      delete saveData.item1
      delete saveData.item2

      // プロセス評価、方針共有の場合
      if (type) {
        // 上期の場合
        let num = 1
        // 下期の場合
        if (this.period == 2) {
          num = 7
        }
        num += i
        // 13の場合
        if (num == 13) {
          // 1月にする
          num = 1
        }

        // 月をゼロパディング表記
        month = ('00' + num).slice(-2)

        // 1月の場合、年を当年にするためプラス1
        if (num == 1) {
          day = (year + 1) + '-' + month + '-01'
        } else {
          day = year + '-' + month + '-01'
        }

        sortNo = row.sortNo
        subSortNo = row.subSortNo

        // プロセス評価の場合
        if (type == 'item') {
          // 自己評価の場合
          if (!this.boss) {
            // 保存する値
            value = row['valueSelf' + i]
            // 該当月が全て環境したか判定
            target = this.isComplete(type, 'valueSelf', i, 'process_self')

          // 上司入力の場合
          } else {
            // 保存する値
            value = row['valueBoss' + i]
            // 該当月が全て環境したか判定
            target = this.isComplete(type, 'valueBoss', i, 'process_boss')
          }
        } else {
          // 保存する値
          value = row['value' + i]
          // 該当月が全て環境したか判定
          target = this.isComplete(type, 'value', i, 'policy')
        }
      }

      return {
        year,
        period: this.period,
        periodId,
        insertUserName: utils.deleteKana(this.$store.getters.user.user_name),
        insertUser: this.$store.getters.user.id,
        userName: this.userName,
        userId: this.userId,
        day,
        sortNo,
        subSortNo,
        value,
        target
      }
    },

    /**
     * プロセス評価、方針共有で該当月が全て完了したか判定
     * @param type 種別
     * @param valueName 値のプロパティー名
     * @param i 行番号
     * @param tar 完了フラグ対象プロパティー名
     */
    isComplete(type, valueName, i, tar) {
      // 同月全て入力済みか確認
      let res = true
      // プロセス評価、もしくはグループ4以上の場合
      if (type == 'item' || this.list.groupNum >= 4) {
        res = !this.list[type].some((it) => {
          return !it[valueName + i]
        })
      } else {
        for (let j = 0; j < this.list[type].length; j++) {
          const it = this.list[type][j]
          if (it.sortNo != 5) {
            if (!it[valueName + i]) {
              res = false
              break
            }
          }
        }
      }

      return { tar: tar + i, res }

    },

    /**
     * 部署選択イベント
     */
    changeDepartment() {
      const id = this.departmentList.find((d) => {
        return this.list.departmentName == d.name
      }).departmentId
      this.list.departmentId = id
      this.saveInfo('departmentId')
    },

    /**
     * 共有方針部署選択イベント
     */
    changePolicyDepartment(row, i) {
      this.savePolicy(row, i)
      // RLSもしくは品管の場合
      if (row['value' + i] && (row['value' + i].indexOf('ラウンド管理部') > -1 || row['value' + i].indexOf('品質管理') > -1)) {
        for (let j = 0; j < this.list.item2.length; j++) {
          const item = this.list.item2[j]
          if (item.sortNo == 7) {
            if (!item['value' + i] && item['value' + i] != 0) {
              item['value' + i] = '/'
              this.savePolicy(item, i)
              break
            }
          }
        }
      // それ以外の部署の場合、/が記載されていたら、空にする
      } else {
        for (let j = 0; j < this.list.item2.length; j++) {
          const item = this.list.item2[j]
          if (item.sortNo == 7) {
            if (item['value' + i] == '/') {
              item['value' + i] = null
              this.savePolicy(item, i)
              break
            }
          }
        }
      }
    },

    /**
     * 現在の部署判定
     */
    async setNowDepartment() {
      // 評価対象者がログインユーザーの場合のみ
      const name = utils.deleteKana(this.$store.getters.user.user_name)
      if (this.userName != name) {
        return
      }
      // 本日の年度、期が同じ場合のみ
      let yy = Number(moment().format('YYYY'))
      const mm = Number(moment().format('M'))
      let period = null
      let i
      if (mm == 1) {
        yy--
        period = 2
        i = 6
      } else {
        if (mm <= 7) {
          period = 1
          i = mm - 1
        } else {
          period = 2
          i = mm - 7
        }
      }
      if (yy != this.year || period != this.period) {
        return
      }

      // ユーザーの現在の所属部署
      const userInfo = this.$store.getters.loginUserData
      const depName = userInfo.departmentName

      // 共有方針が空であればセット
      let row = {} // 所属部署

      for (let i = 0; i < this.list.item2.length; i++) {
        const e = this.list.item2[i]
        if (e.sortNo == 8) {
          row = e
        }
      }

      if (!row['value' + i]) {
        row['value' + i] = depName
        this.changePolicyDepartment(row, i)
      }

      // 編集の場合
      if (this.$route.query.id) {
        if (this.list.departmentName) {
          if (this.list.departmentName != depName) {
            const res = await dialogs.showConfirmDialog('部署が変わりましたか？', depName + 'に変わったのであれば、登録している『部署名』を変更します。')
            if (res == 'YES') {
              this.list.departmentName = depName
              this.changeDepartment()
            }
          }
        }
      }
    },
  }
}
</script>

<style scoped>

.input-form {
  min-width: 760px;
  max-width: 1310px;
  height: calc(100vh - 105px);
}

td {
  height: 60px;
}

.hover-color:hover {
  background-color: rgb(219, 251, 255);
}

.hover-color2:hover {
  background-color: rgba(255, 238, 240);
}

.select-disp {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: white;
}

</style>