<template>
  <div>
    <PageHeader :title="!isMobileDevice?'apollo-RP - ' + typeName + ' 予定一覧':typeName" class="">
      <template #title-header-content>
        <div class="flex-1 pl-2 flex ml-6 justify-end items-center">
          <PrimaryButton
            v-if="type==3"
            text="未登録の物件"
            class="button-contents ml-auto bg-blue-500"
            :size="!isMobileDevice?'normal':'xs'"
            @click="openNotRegisterdModal=true"
          >
          </PrimaryButton>

          <PrimaryButton
            v-if="!openScheduled"
            text="検索"
            class="button-contents"
            :size="!isMobileDevice?'normal':'xs'"
            @click="getData"
          >
          </PrimaryButton>

          <h1 v-if="!openScheduled" class="text-lg font-bold text-white flex items-center">
            <Icon iconName="" class="w-7 h-7 mr-2" /> <span class="mt-0 ">{{ list.length }} 件</span>
          </h1>

          <div v-if="openScheduled" class="flex">
            <!-- 地図有無ボタン -->
            <div class="">
              <PrimaryButton text="地図" size="normal px-2 py-2 btn app-btn-w"
                :class="isMap ? 'clickButtonColor2' : 'normalButtonColor'"
                @click="isMap=!isMap" />
            </div>
            <!-- 戻るボタン -->
            <PrimaryButton text="一覧に戻る" size="normal px-2 py-2 ml-2 btn " @click="close()" />
          </div>
          <div v-if="!openScheduled" class="flex">
            <!-- 本日のみ -->
            <div class="ml-2">
              <PrimaryButton :text="isMobileDevice||isTabletDevice?'本日のみ':''" :class="filterToday==0 ? 'clickButtonColor' : 'normalButtonColor'" :size="!isMobileDevice?'normal':'xs'" v-tooltip="'本日のみ'" @click="changeFilterToday(0)" :buttonContents="'要'">
                <Icon v-if="!isMobileDevice&&!isTabletDevice" slot="before" iconName="Clock" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
              </PrimaryButton>
            </div>

            <!-- 本日以降 -->
            <div class="ml-2">
              <PrimaryButton :text="isMobileDevice||isTabletDevice?'本日以降':''" :size="!isMobileDevice?'py-2 px-2 xs':'xs'" :class="filterToday==1 ? 'clickButtonColor' : 'normalButtonColor'"  v-tooltip="'本日以降'" @click="changeFilterToday(1)" :buttonContents="'要'">
                <!-- <div class="flex"> -->
                  <Icon v-if="!isMobileDevice&&!isTabletDevice" slot="before" iconName="Clock" iconType="outline" :strokeWidth="2" class="h-5 w-5 inline" />
                  <Icon v-if="!isMobileDevice&&!isTabletDevice" slot="before" iconName="Plus" iconType="outline" :strokeWidth="2" class="h-4 w-4 inline pb-1" />
                <!-- </div> -->
              </PrimaryButton>
            </div>

            <PrimaryButton text="クリア" class="normalButtonColor flex ml-2" :size="!isMobileDevice?'normal':'xs'" @click="clear" :buttonContents="'要'" />

            <Icon 
              iconName="Refresh" 
              :clickable="false" 
              :class="{'animate-spin': loading}" 
              class="text-white hover:text-gray-300 active:text-gray-400 ml-4 cursor-pointer"
              @click.native="refresh"
            />
          </div>
        </div>
      </template>

      <template #page-header-content>
        <div v-if="!openScheduled" class="pl-4 flex ">
          <h1 class="text-lg font-bold text-white flex items-center">            
            <Icon iconName="ClipboardCheck" class="w-7 h-7 mr-2" />
            <span v-if="!openScheduled && !isMobileDevice" class="mt-0">予定を担当者・日ごとに表示</span>
          </h1>
          <div v-if="filterToday == 1" class="ml-auto mr-2" id="filterDate">
            <WmsDateInput
              name="filterDate"
              v-model="filterDate"
              :min="minLimitDate"
              placeholder="予定日"
              />
          </div>
          <div class="mr-2" :class="filterToday==0?'ml-auto':''">
            <input
              v-tooltip="'スペースは無視します'"
              type="search"
              name="filterSite" 
              placeholder="物件名 検索"
              class="py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900" 
              v-model="filterSite"
            /> 
          </div>
          <div v-if="filterToday == 1" class="">
            <input
              v-tooltip="!partTimer?'スペースは無視します':'変更不可'"
              type="search"
              name="filterStaff" 
              placeholder="担当者 検索"
              class=" py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900" 
              v-model="filterStaff"
              :disabled="partTimer"
              :class="partTimer?'cursor-not-allowed bg-gray-200':''"
            />
          </div>
          <div v-else-if="filterToday == 0" class="">
            <input
              v-tooltip="!partTimer?'スペースは無視します':'変更不可'"
              type="search"
              name="filterStaff" 
              placeholder="担当者 検索"
              class="w-full py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900" 
              v-model="filterStaff"
              :disabled="partTimer"
              :class="partTimer?'cursor-not-allowed bg-gray-200':''"
            /> 
          </div>
        </div>
        <div v-else class="pl-4 flex justify-between">
          <h1 class="text-lg font-bold text-white flex items-center">            
            <Icon iconName="ClipboardCheck" class="w-7 h-7 mr-2" />
            <span class="mt-0">詳細</span>
          </h1>
        </div>
      </template>
    </PageHeader>

    <main class="absolute top-32 bottom-4 -mt-2 overflow-auto w-full">
      <div class="mx-auto px-2 sm:px-4 lg:px-8 h-full">
        <div class="bg-white rounded-md border border-gray-200 overflow-auto h-full">
          <!-- 一覧 -->
          
          <div v-if="loading && !openScheduled" class="w-full h-full flex justify-center items-center">
            <p class="text-gray-400 font-bold animate-pulse">Searching...</p>
          </div>

          <div v-show="!loading && !openScheduled">
            <div class="rounded">
              <table class="w-full table-auto list overflow-auto mb-5">
                  <thead class="">
                    <tr v-if="list && list.length" class="border border-gray-200">
                      <th v-for="(c, index) in columns" :key="'cu2'+index" scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider bg-colu" :class="c.class">
                        {{c.title}}
                      </th>
                    </tr>
                  </thead>

                <tr v-for="(row, i) in list" :key="'l'+i" class="border border-gray-200 bg-white tracking-wider hover-color" @click="open(row)">
                  <!-- 詳細openボタン -->
                  <td class="text-center" @click="open(row)">
                    <div class="flex justify-center">
                      <Icon slot="before" iconName="ChevronDoubleRight" iconType="solid" :strokeWidth="2" class="h-7 w-7 p-1 bg-indigo-700 text-white rounded-full cursor-pointer hover:bg-indigo-800" />
                    </div>
                  </td>

                  <!-- No -->
                  <td class="b-top text-left px-2 py-3 whitespace-nowrap text-sm font-medium text-gray-500 col-no">
                    {{ i + 1 }}.
                  </td>

                  <!-- 予定日 -->
                  <td class="px-2 py-3 whitespace-prerap text-sm text-gray-700 min-300">
                    {{ row.scheduledDate }}
                  </td>

                  <!-- 担当者 -->
                  <td class="px-2 py-3 whitespace-nowrap text-sm text-gray-700 min-300">
                    {{ row.staff }}
                  </td>

                  <!-- 点検数 -->
                  <td class="px-2 py-3 whitespace-nowrap text-sm text-gray-700 min-300 text-center">
                    {{ row.completion || 0 }} / {{ row.total }} 件中
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <!-- 詳細一覧 -->
          <div class="rounded w-11/12 ml-auto mr-auto" v-if="openScheduled && schedule && schedule.length">
            <!-- 地図 -->
            <div v-if="isMap" id="map" ref="map"></div>
            
            <div class="w-11/12 mr-auto mt-10 mb-4">
              <div class="text-gray-600 font-bold">
                日付 : {{ scheduledDate }}
              </div>
              <div class="text-gray-600 font-bold mt-5">
                <div class="">
                  担当者 : {{ staff }}
                </div>
              </div>
            </div>

            <div class="flex">
              <!-- 予定日変更ボタン -->
              <div v-if="!changeDateFlag">
                <PrimaryButton text="予定日変更" size="normal px-2 py-2 btn " class="bg-light-blue-700 text-white" @click="changeDateBtn()" />
              </div>

              <div class="flex" v-if="changeDateFlag">
                <!-- 予定日変更決定ボタン -->
                <div>
                  <PrimaryButton text="決定" size="normal px-2 py-2 btn" class="text-white w-86px" :class="!changeDate?'bg-gray-400':'bg-light-blue-700'" :disabled="!changeDate" @click="changeSave()" />
                </div>

                <!-- 変更日 -->
                <div class="flex mx-3">
                  <div class="mr-2 w-100px" id="filterDate">
                    <WmsDateInput
                      name="changeDate"
                      v-model="changeDate"
                      :min="minLimitDate"
                      placeholder="変更日"
                      />
                  </div>
                  <div class="text-xs text-gray-600 mt-auto mb-1">
                    に変更
                  </div>
                </div>
                
                <!-- 予定日変更キャンセルボタン -->
                <div>
                  <PrimaryButton text="キャンセル" size="normal px-2 py-2 btn" class="bg-light-blue-100 text-light-blue-700 border-light-blue-700" @click="changeCancel()" />
                </div>
              </div>
            </div>

            <div class="toggle flex items-center mt-3">
              <span class="text-gray-500 font-bold text-xs mr-2">並べ替え</span>
              <Toggle v-model="sortable" size="small" />
              <PrimaryButton text="並べ替え保存" size="normal px-2 py-2" class="ml-2" :class="!isChangeSort?'bg-gray-300':'bg-teal-600'" :disabled="!isChangeSort" @click="saveSort()" />
            </div>

            <table class="w-full table-auto mt-4 overflow-auto mb-5">
              <thead class="">
                <tr class="border border-gray-200">
                  <th v-for="(c, index) in columns2" :key="'c2'+index" scope="col" class="px-2 py-3 text-xs font-medium text-gray-500 tracking-wider bg-colu" :class="c.class">
                    <div v-if="changeDateFlag && index==0" class="text-blue-800 cursor-pointer" @click="checkAll()">
                      全選択
                    </div>
                    <div v-else>
                      {{c.title}}
                    </div>
                  </th>
                </tr>
              </thead>

              <draggable 
                key="schedule"
                :list="schedule"
                direction="vertical"
                handle=".row-drag-handle"
                tag="tbody"
                class="bg-white"
                @change="changeSort"
              >

                <tr v-for="(row, i) in schedule" :key="'l'+i" class="border border-gray-200 tracking-wider hover-color" :class="row.claim?'bg-lgr':''">
                  
                  <td>
                    <!-- 予定日変更の場合チェックボックス -->
                    <div v-if="changeDateFlag && !row.completionDate" class="flex justify-center items-center cursor-pointer col-no">
                      <input 
                        type="checkbox"
                        :id="row.siteCode"
                        class="h-4 w-4 my-2 border-gray-400 rounded cursor-pointer" 
                        v-model="row.change"
                      />
                    </div>

                    <!-- 並べ替え -->
                    <div v-else-if="sortable && !row.completionDate" class="flex justify-center items-center row-drag-handle prasp col-no">
                      <MenuIcon class="text-gray-400" />
                    </div>

                    <!-- 予定日変更・並べ替えの場合、完了済は何も表示しない -->
                    <div v-else-if="(changeDateFlag || sortable) && row.completionDate"></div>
                    
                    <!-- それ以外は点検表openボタン -->
                    <div v-else class="text-center col-no" @click="open2(row)">
                      <div class="flex justify-center">
                        <Icon slot="before" iconName="ChevronDoubleRight" iconType="solid" :strokeWidth="2" class="h-7 w-7 p-1 bg-indigo-700 text-white rounded-full cursor-pointer hover:bg-indigo-800" />
                      </div>
                    </div>
                  </td>

                  <!-- No -->
                  <td class="text-center text-gray-500 col-no" @click="open2(row)">
                    {{ i + 1 }}
                  </td>

                  <!-- 完了アイコン -->
                  <td class="px-2 py-1 whitespace-pre-line text-sm text-gray-700 col-no">
                    <Icon v-if="row.completionDate" slot="before" iconName="CheckCircle" iconType="solid" :strokeWidth="2" class="h-7 w-7 icon-green" />
                  </td>

                  <!-- 物件名 -->
                  <td class="px-2 py-3 whitespace-pre-line text-sm text-gray-700 min-200" @click="open2(row)">
                    <div v-if="row.claim" class="text-xs text-green-800 bg-green-50 border border-green-800 rounded-lg w-fit px-2 mb-1">クレーム対応</div>
                    <div>
                      {{ row.siteName }}
                      <!-- 棟 -->
                      <span v-if="row.ridgeCode" class="text-red-700 font-bold">（{{ row.ridgeName }}）</span>
                      <!-- 連日作業何日目 -->
                      <span v-if="type==3&&row.subCount&&row.subCount!='0'" class="text-indigo-600 font-bold"> （{{ row.subCount }}日目）</span>
                    </div>
                  </td>

                  <!-- 開始時間 -->
                  <td class="px-2 py-3 whitespace-pre-line text-sm text-gray-700 w-24 text-center" @click="open2(row)">
                    {{ row.startTime }}
                  </td>

                  <!-- ～ -->
                  <td class="px-2 py-3 whitespace-pre-line text-sm text-gray-700 w-16 text-center" @click="open2(row)">
                    <div v-if="row.startTime">～</div>
                  </td>

                  <!-- 終了時間 -->
                  <td class="px-2 py-3 whitespace-pre-line text-sm text-gray-700 w-24 text-center" @click="open2(row)">
                    {{ row.endTime }}
                  </td>

                  <!-- Weight -->
                  <td class="px-2 py-3 whitespace-pre-line text-sm text-gray-700 col-no" @click="open2(row)">
                    {{ row.weight }}
                  </td>

                  <!-- ルートボタン -->
                  <td class="px-2 py-0.5 text-center text-sm font-medium border-gray w-fit">
                    <a :href="route(row)" target="_blank" class="cursor-pointer">
                      <PrimaryButton text="GoogleMap"/>
                    </a>
                  </td>

                  <!-- 削除ボタン -->
                  <td class="px-2 py-0.5 text-center text-sm font-medium border-gray col-no">
                    <div class="flex justify-center">
                      <a v-if="!row.completionDate" href="#" @click="deleteRow(i)">
                        <TrashIcon class="text-gray-400 cursor-pointer hover:text-gray-500 active:text-gray-600 w-5 h-5" />
                      </a>
                    </div>
                  </td>
                </tr>
              </draggable>
            </table>
          </div>
          <div v-else-if="openScheduled" class="ml-10 font-bold text-gray-800 text-lg mt-10 mr-auto w-fitcon animate-pulse">
            {{ scheduledDate }}には予定がありません。
          </div>
        </div>
      </div>
    </main>
    <!-- 未登録の新規物件報告書 -->
    <NotRegisteredNewSiteModal
      v-model="openNotRegisterdModal"
      :open="openNotRegisterdModal"
      :isInfo="false"
      @closed="closed()"
    />
  </div>
</template>

<script async src="https://maps.googleapis.com/maps/api/js?key=AIzaSyAH_sBOh9GMJIZA9TuhVu6b6fyKvKWi_RI&callback=initMap&v=weekly&libraries=marker" defer></script>
<script>
import Icon from '@components/Icon.vue'
import PageHeader from '@components/PageHeader.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import { TrashIcon, MenuIcon } from '@vue-hero-icons/solid'
import WmsDateInput from '@wmscomponents/WmsDateInput.vue'
import NotRegisteredNewSiteModal from '../../FixedClean/components/NotRegisteredNewSiteModal.vue'
import * as dialogs from '@libs/dialogs'
import * as backend from '@libs/backend'
import * as utils from '@libs/utils'
import * as moment from 'moment'
import * as logManager from '@managers/logManager'
import * as requestPostManager from '@managers/requestPostManager'
import * as taskRequestManager from '@managers/taskRequestManager'
import Toggle from '@components/Toggle.vue'
import draggable from "vuedraggable"
// import { MarkerClusterer } from "@googlemaps/markerclusterer"
var map

export default {
  components: {
    Icon,
    PrimaryButton,
    PageHeader,
    TrashIcon,
    MenuIcon,
    WmsDateInput,
    NotRegisteredNewSiteModal,
    Toggle,
    draggable
  },

  data() {
    return {
      loading: false,
      
      //検索保持用
      searchCondition: {
        filterToday : 0,
        filterStaff : '',
        filterSite: '',
        filterDate : false,
      },
      // カラム
      columns: [
          {title: ''},
          {title: 'No.', class: ''},
          {title: '予定日', class: ''},
          {title: '担当者', class: ''},
          {title: '完了件数 / 合計件数', class: 'text-center'}
      ],
      // 予定日絞込み
      filterDate: '',
      // 最少日
      minLimitDate: moment().format('YYYY-MM-DD'),
      // 予定日絞込み
      filterToday: 0,
      // 担当者絞込み
      filterStaff: '',
      // 物件名絞り込み
      filterSite: '',
      // 予定一覧データ
      list: [],
      // ダイアログopen
      openScheduled: false,
      // 詳細一覧
      schedule: [],
      // 詳細画面の日付
      scheduledDate: '',
      // 詳細画面の担当者
      staff: '',
      // 予定日変更フラグ
      changeDateFlag: false,
      // 予定変更日
      changeDate: '',
      // スケジュール カラム
      columns2: [
          {title: '', class: 'text-left'},
          {title: 'No.', class: 'text-center w-90px'},
          {title: '完了', class: 'text-left'},
          {title: '物件名', class: 'text-left'},
          {title: '開始', class: 'text-center'},
          {title: '', class: 'text-left'},
          {title: '終了', class: 'text-center'},
          {title: 'Weight', class: 'text-left'},
          {title: '', class: 'text-left'},
          {title: '', class: 'text-left'},
      ],
      // チェックリスト
      checkList: {clientName: '', siteName: '', date: '', staff: ''},
      // モーダルopen
      openModal: 0,
      // パートさん
      partTimer: false,
      // 地図の有無
      isMap: false,
      // 並べ替え
      sortable: false,
      // 並べ替え実行フラグ
      isChangeSort: false,
      // GPS許容範囲
      gpsDiff: 0,
      // 未登録新規物件モーダルopen
      openNotRegisterdModal: false
    }
  },

  props: {
    // タイトル名
    typeName: {
      type: String,
      default: null
    },
    // 区分
    type: {
      type: Number,
      default: null
    },
    // セッションストレージ名
    settionStrageName: {
      type: String,
      default: null
    },
    // 点検表から遷移した際の予定日
    checkListDate: {
      type: String,
      default: null
    },
    // 点検表から遷移した際のユーザーID
    userId: {
      type: Number,
      default: null
    },
    // 点検表から遷移した際のユーザー名
    name: {
      type: String,
      default: null
    },
    // ルート名
    routerName: {
      type: String,
      default: null
    },
  },

  computed: {
    
    /**
     * スマホ
     */
    isMobileDevice() {
      return utils.deviceInfo.isMobile
    },
    
    /**
     * タブレット
     */
    isTabletDevice() {
      return utils.deviceInfo.isTablet
    }
  },

  async created() {
    // 点検表から遷移した場合
    if (this.$route.query.checkList && this.$route.query.checkList == 'true' && this.checkListDate) {
      await this.open({scheduledDate: moment(this.checkListDate).format('YYYY/MM/DD'), userId: this.userId, staff: this.name})
    } else {
      this.getCondition()
      this.getData()
    }
  },

  watch: {
    /**
     * 地図の切り替え
     */
    isMap() {
      this.initMap()
    },

    /**
     * 並べ替え
     */
    sortable() {
      if (this.sortable) {
        // 予定変更ボタンはfalseにする
        this.changeDateFlag = false
      }
    }
  },

  methods: {
    /**
     * クリアボタンイベント
     */
    clear(){
      this.loading = true
      window.sessionStorage.clear()
      this.$router.go({path: this.$router.currentRoute.path, force: true})
    },

    /**
     * セッションストレージデータ取得
     */
    getCondition(){
      const searchJson = sessionStorage.getItem(this.settionStrageName)
      const getItem = JSON.parse(searchJson)

      if(getItem !== null && getItem !== undefined){
        this.filterToday = getItem.filterToday
        this.filterSite = getItem.filterSite ? getItem.filterSite : ''
        this.filterStaff = getItem.filterStaff
        this.filterDate = getItem.filterDate
      }
    },
    /**
     * 予定一覧取得
     */
    async getData() {
      this.loading = true

      // パートさんの場合は、自分のタスクのみ表示
      const auth = this.$store.getters.auth
      this.partTimer = auth.partTimer
      if (this.partTimer) {
        this.filterStaff = this.$store.getters.user.user_name
      }
      // セッションストレージ保存用
      this.searchCondition.filterToday = this.filterToday
      this.searchCondition.filterSite = this.filterSite
      this.searchCondition.filterStaff = this.filterStaff
      this.searchCondition.filterDate = this.filterDate 
      // セッションストレージ保存
      this.setSessionStorage(this.searchCondition,this.settionStrageName)
      // 取得
      const result = await backend.searchData('patrolSchedule/getPatrolList', {today: utils.getToday(), ...this.searchCondition, type: this.type})
      if (result.data && result.data.data) {
        this.list = result.data.data
      }
      this.gpsDiff = result.data.gpsDiff
      this.loading = false
    },

    /**
     * 検索条件をセッションストレージへ保存
     * @param {Object} searchCondition 
     * @param {String} storageName 
     */
    setSessionStorage(searchCondition,storageName) {
      const searchStr = JSON.stringify(searchCondition)
      sessionStorage.setItem(storageName, searchStr)
    },

    /**
     * 当日ボタンイベント
     */
    async changeFilterToday(value) {
      if (this.filterToday == value) {
        return
      }
      this.loading = true
      this.filterToday = value
      if (value == 0) {
        // 予定日の検索をなくす
        this.filterDate = ''
      }
      await this.getData()
    },

    /**
     * 詳細一覧
     * @param row 対象行
     */
    async open(row) {
      this.loading = true
      this.schedule = []
      this.scheduledDate = moment(row.scheduledDate).format('YYYY/MM/DD')
      this.staff = row.staff
      
      // 詳細データ取得
      const result = await backend.searchData('patrolSchedule/getDetailList', {scheduledDate: moment(row.scheduledDate).format('YYYY-MM-DD'), userId: row.userId, siteCode: row.siteCode, type: this.type})
      if (result.data && result.data.data) {
        this.schedule = result.data.data
      }
      this.initMap()
      this.openScheduled = true
      this.loading = false
    },

    /**
     * 点検表open
     * @param row 対象行
     */
    open2(row) {
      // paramにスケジュールIDを渡す
      let scheduleId = row.id
      // 点検表に遷移
      this.$router.push({ 
        name: this.routerName,
        query: { scheduleId, claim: row.claim, sub: row.subCount },
        params: { scheduledDate: this.scheduledDate, siteLocation: { lat: row.latitude, lng: row.longitude, gpsDiff: this.gpsDiff } }
      })
    },

    /**
     * 詳細close
     */
    async close() {
      await this.getData()
      this.openScheduled = false
      if (this.changeDateFlag) {
        this.changeCancel()
      }
    },

    /**
     * 削除ボタン
     * @param i index
     */
    async deleteRow(i) {
      // 連日作業（2日目以降）
      if (this.type == 3 && this.schedule[i].subCount && this.schedule[i].subCount!='0') {
      const subR = await dialogs.showConfirmDialog('予定を削除', `「${this.schedule[i].siteName}」  ${this.schedule[i].subCount}日目の作業です。削除してよろしいでしょうか？`)
      if (subR != 'YES') {
        return
      }
        await backend.deleteData('patrolSubDate/delete', { id: this.schedule[i].id, scheduledDate: moment(this.scheduledDate).format('YYYY-MM-DD') })
        logManager.recording(this, logManager.Loglevel.INFO, this.typeName, '予定を削除',  `${this.scheduledDate}の「${this.schedule[i].siteName}」（${this.schedule[i].subCount}日目の作業）を削除しました`)
      
      // 通常（連日作業の初日）
      } else {
      const delR = await dialogs.showConfirmDialog('予定を削除', `「${this.schedule[i].siteName}」は予定を組み直しになりますが、よろしいでしょうか？`)
      if (delR != 'YES') {
        return
      }
        await backend.deleteData('patrolSchedule/delete', { id: this.schedule[i].id, type: this.type })
        
        logManager.recording(this, logManager.Loglevel.INFO, this.typeName, '予定を削除',  `${this.scheduledDate}の「${this.schedule[i].siteName}」を削除しました`)

        // 定期清掃の場合
        if (this.type == 3) {
          // 張り紙メインタスクがある場合
          if (this.schedule[i].requestPostId) {
            // メインタスクが未完了の場合
            const main = await requestPostManager.getRequestPost(this, this.schedule[i].requestPostId)
            if (main.status == 'open') {
              await this.deletePosterTask(this.schedule[i].requestPostId)
            }
          }
        }
      }
      this.schedule.splice(i, 1)
    },

    /**
     * googlemapボタン
     * @param i インデックス
     */
    route(row) {
      let map = 'https://www.google.com/maps/dir/?api=1&destination=' + row.siteAddress + '&travelmode=driving&hl=ja&gl=jp'
      return map
    },

    /**
     * リフレッシュアイコン
     */
    async refresh() {
      this.loading = true
      await utils.wait(500)
      await this.getData()
      await utils.wait(500)
      this.loading = false
    },

    /**
     * 予定日変更ボタン
     */
    changeDateBtn() {
      this.changeDateFlag = true
      this.sortable = false
    },

    /**
     * 予定日変更決定ボタン
     */
    async changeSave() {
      if (this.scheduledDate == moment(this.changeDate).format('YYYY/MM/DD')) {
        await dialogs.showErrorDialog('変更日を変えてください', '変更日と現在の予定日が同じです。\r\n変更日を別の日に変えてください。')
        return
      }
      let save = []
      let subSave = []
      let idArr = []
      let subArr = []
      for (let i = 0; i < this.schedule.length; i++) {
        const s = this.schedule[i]
        // 未完了の場合
        if (!s.completionDate) {
          // 変更チェックがついている場合
          if (s.change) {
            // ソート番号に100をプラス（変更先の既存予定の後にするため）
            let sortNo = Number(s.sortNo) + 100

            // 連日作業の場合
            if (s.subCount && s.subCount != '0') {
              subSave.push({
                id: s.id,
                sortNo,
                scheduledDate: moment(this.changeDate).format('YYYY-MM-DD'),
                sub: s.subCount,
                date: moment(s.scheduledDate).format('YYYY-MM-DD'),
                staff: s.staff,
                userId: s.userId,
                siteName: s.siteName,
                requestPostId: s.requestPostId,
                insertUser: this.$store.getters.user.id
              })
              subArr.push(Number(s.id))
            // 通常
            } else {
              save.push({
                id: s.id,
                sortNo,
                scheduledDate: moment(this.changeDate).format('YYYY-MM-DD'),
                staff: s.staff,
                userId: s.userId,
                siteName: s.siteName,
                requestPostId: s.requestPostId,
                insertUser: this.$store.getters.user.id
              })
              idArr.push(Number(s.id))
            }
          }
        }
      }

      if ((!idArr || !idArr.length) && (!subArr || !subArr.length)) {
        await dialogs.showErrorDialog('変更対象データなし', '変更するデータにチェックをつけてください。')
        return
      }

      let er = []
      // 連日作業の場合、初日よりも後を選択しているか、他の連日作業日と重なっていないか確認
      if (subArr && subArr.length) {
        let subRes = await backend.postData('patrolSubDate/checkDate', subSave)
        if (subRes && subRes.data.data && subRes.data.data.length) {
          for (let s = 0; s < subRes.data.data.length; s++) {
            const sd = subRes.data.data[s]
            er.push(sd)
          }
        }
      }

      // 定期清掃の場合、連日と同じ作業日でないか、連日よりも後にしていないか確認
      if (this.type == 3 && idArr && idArr.length) {
        let mainRes = await backend.postData('patrolSubDate/checkDateForMain', save)
        if (mainRes && mainRes.data.data && mainRes.data.data.length) {
          for (let s = 0; s < mainRes.data.data.length; s++) {
            const md = mainRes.data.data[s]
            er.push(md)
          }
        }
      }
      if (er.length) {
        await dialogs.showErrorDialog('その日に変更できません', `${er.join('\r\n')}`)
        return
      }

      let isSave = []
      let isSubSave = []
      let site = []
      let subSite = []
      // 途中保存しているデータを取得
      if (idArr && idArr.length) {
        let res = await backend.searchData('patrolTemporarySaving/isSaving', { patrolScheduleId: idArr.join(',')})
        if (res && res.data.data && res.data.data.length) {
          res.data.data.forEach((r) => {
            isSave.push(r.patrolScheduleId)
          })

          this.schedule.forEach((s) => {
            if (isSave.includes(Number(s.id))) {
              site.push(s.siteName)
            }
          })
        }
      }
      
      // 途中保存しているデータを取得(連日作業)
      if (subArr && subArr.length) {
        let resSub = await backend.searchData('patrolTemporarySaving/isSubSaving', { patrolScheduleId: subArr.join(',')})
        if (resSub && resSub.data.data && resSub.data.data.length) {
          resSub.data.data.forEach((rs) => {
            isSubSave.push(rs.patrolScheduleId)
          })

          this.schedule.forEach((s) => {
            if (isSubSave.includes(Number(s.id))) {
              subSite.push(s.siteName)
            }
          })
        }
      }

      // 連日作業（2日目以降）で現在進行中の作業があった場合は保存できない（どの作業日の途中保存かが分からない）
      if (subSite.length) {
        await dialogs.showWarningDialog('連日作業物件で途中保存のデータあり', `${subSite.join(', ')}は連日作業の物件です。\r\n本日作業途中のデータがあるため日付を変更できません。\r\n作業を終了した後、もしくは途中保存を削除してから日付を変更してください。`)
        return
      }
      // 途中保存しているデータがあった場合
      if (site.length) {
        let dialogsRes = await dialogs.showConfirmDialog('途中保存のデータあり', `${site.join(', ')}は途中保存のデータがありますが、削除されます。\r\n予定日を変更してもよろしいでしょうか？`)
        if (dialogsRes != 'YES') {
          return
        }
      } else {
        let dialogsRes = await dialogs.showConfirmDialog('変更します', '予定日を ' + moment(this.changeDate).format('YYYY/MM/DD') + ' に変更します。\r\nよろしいでしょうか？')
        if (dialogsRes != 'YES') {
          return
        }
      }

      // 変更を保存
      let posterTask = ''
      if (save && save.length) {
        await backend.postData('patrolSchedule/changeDate', { change: save, isSave })

        // 定期清掃の場合、メインタスク更新orそのまま
        posterTask = await this.mainTask(save)
      }
      if (subSave && subSave.length) {
        await backend.postData('patrolSubDate/changeDate', { change: subSave })

        // 定期清掃の場合、メインタスク更新orそのまま
        posterTask = posterTask + await this.mainTask(subSave)
      }

      // 張り紙タスク保存結果
      if (posterTask) {
        await dialogs.showInfoDialog('張り紙タスク登録', '張り紙タスク登録内容は以下の通りです。\r\n' + posterTask)
      }

      // ログ登録
      logManager.recording(this, logManager.Loglevel.INFO, `${this.typeName} 予定変更`, '更新', `${this.typeName}（担当者：${this.staff}、予定日を${this.scheduledDate}から${moment(this.changeDate).format('YYYY/MM/DD')}へ${save.length}件）の予定を変更しました。`)  

      // 画面を更新
      await this.open(this.schedule[0])
    },

    /**
     * 予定日変更キャンセルボタン
     */
    changeCancel() {
      this.changeDateFlag = false
      this.changeDate = ''
      for (let i = 0; i < this.schedule.length; i++) {
        const s = this.schedule[i]
        if (s.change) {
          s.change = false
        }
      }
    },

    /**
     * 予定日変更 全選択
     */
    checkAll() {
      for (let i = 0; i < this.schedule.length; i++) {
        const s = this.schedule[i]
        if (!s.completionDate) {
          this.$set(this.schedule[i], 'change', true)
        }
      }
    },

    /**
     * 張り紙タスクのメイン、サブ削除
     */
    async deletePosterTask(id) {
      if (!id) {
        return
      }
      // メインタスク削除（論理削除）
      await requestPostManager.deleteMain(this, id)
      // サブも削除（物理削除）
      const subTask = await taskRequestManager.getByRequestPostId(this, id, null)
      if (subTask && subTask.length) {
        for (let j = 0; j < subTask.length; j++) {
          const s = subTask[j]
          if (s.subTaskNo) {
            await taskRequestManager.deleteTask2(this, s.subTaskNo)
          }
        }
      }
    },

    /**
     * 張り紙タスク生成
     * @param tar 対象予定データ
     */
    async createMainTask(tar) {
      // メインタスクに登録する期限日
      let limit = ''
      // 初日の予定日
      let schedule = ''

      // 連日作業の場合
      if (tar.sub && tar.sub != '0') {
        // 初日の作業日を取得（引数にtypeなし！）
        const main = await backend.searchData('patrolSchedule/getById', { id: tar.id })
        if (main && main.data.data && main.data.data.length) {
          schedule = main.data.data[0].scheduledDate
          schedule = moment(schedule).format('YYYY-MM-DD')
          limit = this.limitDateRP(schedule)
          schedule = moment(schedule).format('YYYY/MM/DD')
        }
      
      // 初日の場合
      } else {
        schedule = moment(tar.scheduledDate).format('YYYY-MM-DD')
        limit = this.limitDateRP(schedule)
        schedule = moment(schedule).format('YYYY/MM/DD')
      }

      // 部署
      const dep = await this.getDepartments(tar.userId)

      // 張り紙タスク
      let rp = requestPostManager.create()
      rp.type = 'apollo_request_post'
      rp.category2 = ['社内']
      rp.category = ['張り紙']
      // タイトル
      rp.inquiry_title = schedule + '  ' + tar.siteName
      // 担当者
      rp.toStaffName = utils.getUserNameById(tar.userId, this.$store.getters.userList)
      rp.toStaffId = String(tar.userId)
      // 依頼者
      rp.byUser = this.$store.getters.user.user_name
      rp.byUserId = this.$store.getters.user.id
      // 部署
      rp.toDepartment = dep.departmentName
      rp.toDepartmentId = dep.departmentId
      // 依頼日
      rp.requestDate = moment().format('YYYY-MM-DD')
      // 期限日
      rp.limitDate = limit

      // RPメイン登録
      const res = await requestPostManager.register(this, rp)
      if (res && res.id) {
        // 予定のデータも更新
        tar.requestPostId = res.id
        await backend.postData('patrolSchedule/changeRequestPostId', { ...tar })
        return res
      } else {
        return null
      }
    },

    /**
     * 所属部署 取得
     * @param userId 対象ユーザーID
     */
    async getDepartments(userId) {
      let dep = await backend.searchData('user/getAllDataByUser', {day: utils.getToday(), userId})
      if (dep.data.data && dep.data.data.departmentId) {
        return { departmentId: dep.data.data.departmentId, departmentName: dep.data.data.departmentName }
      } else {
        return { departmentId: '', departmentName: 'なし' }
      }
    },

    /**
     * RPメインの期限日判定
     */
    limitDateRP(schedule) {
      // 予定日を数値に変換
      const max = Number(moment(schedule).format('YYYYMMDD').replace(/-/g, ''))
      // 本日より2週間後
      const after2week = moment().add(14, 'days').format('YYYY-MM-DD')
      // 数値に変換
      const target = Number(after2week.replace(/-/g, ''))
      // 本日を数値に変換
      const today = Number(moment().format('YYYYMMDD'))

      // 予定日が2週間後より未来の場合、2週間後
      if (max > target) {
        return after2week
      // 予定日が本日の場合、本日
      } else if (max == today) {
        return moment().format('YYYY-MM-DD')
      // それ以外は、予定より1日前
      } else {
        return moment(schedule).add(-1, 'days').format('YYYY-MM-DD')
      }
    },

    /**
     * 張り紙メインタスク
     * @param list 予定データ
     */
    async mainTask(list) {
      // 定期清掃以外
      if (this.type != 3) {
        return null
      }
      let task = ''
      for (let i = 0; i < list.length; i++) {
        const l = list[i]
        // 張り紙タスクが作成されているもの
        if (l.requestPostId) {
          const main = await requestPostManager.getRequestPost(this, l.requestPostId)
          if (main && main.status == 'open') {
            const log = await dialogs.showConfirmDialog('張り紙タスクはいかが致しますか？', l.siteName + ' の張り紙タスクは未完了です。\r\n削除し、新しく作り替えますか？\r\nそれとも、そのままにしますか？', '作り替え', 'そのまま')
            // 作り替える場合
            if (log == 'YES') {
              // 削除
              await this.deletePosterTask(l.requestPostId)
              // 作成
              const res1 = await this.createMainTask(l)
              if (res1) {
                task = task + `物件名：${l.siteName}、お問い合わせ番号：${res1.requestNo}\r\n`
              }
            }
          } else if (main && main.status == 'done') {
            const log2 = await dialogs.showConfirmDialog('張り紙タスクはいかが致しますか？',  l.siteName + 'の張り紙タスクは完了しているため、削除しません。\r\n再度、新しく作りますか？', '作る', '作らない')
            // 再度新しく作る
            if (log2 == 'YES') {
              // 作成
              const res2 = await this.createMainTask(l)
              if (res2) {
                task = task + `物件名：${l.siteName}、お問い合わせ番号：${res2.requestNo}\r\n`
              }
            }
          }
        }
      }
      return task
    },
    /**
     * マップ生成
     */
    async initMap() {
      if (!this.isMap) {
        return
      }
      const locations = []

      var addressMap = []

      let idx = 0
      let maCnt = 0
      let centLat = 0
      let centLng = 0

      for await (const row of this.schedule) {
        var address = row.siteAddress 
        // 検索後同物件数カウント
        var count =  this.schedule.filter(row => row.siteAddress == address).length
        // 同物件数
        row.count = count
        
        if(count > 1) addressMap = locations.map(item => item.address)
        
        // 同住所非マーカー用条件
        if(!addressMap.includes(row.siteAddress)){
          if (row.latitude && row.longitude) {
            maCnt ++
            centLat += Number(row.latitude)
            centLng += Number(row.longitude)

            locations.push(
              {
                name: row.siteName,
                ridge: row.ridgeName,
                address: row.siteAddress,
                count: row.count,
                position: {
                  lat: Number(row.latitude),
                  lng: Number(row.longitude)
                },
                index: idx, 
                row: row,
              }
            )
          }
        }
        idx ++
      }

      if (maCnt > 0) {
        centLat = centLat / maCnt
        centLng = centLng / maCnt
      } else {
       //検索0の場合 
        centLat += 35.624315
        centLng += 139.579387
      }
      let zoomVal = 11
      let centVal = { lat: centLat, lng: centLng }
  
      map = new window.google.maps.Map(this.$refs.map, {
        zoom: zoomVal,
        center: centVal
      })
      const infoWindow = new window.google.maps.InfoWindow({
        content: "",
        disableAutoPan: true,
      })
      
      const markers = locations.map((pos, i) => {
        let blueMarker = this.setMarker(pos)
        let marker = new window.google.maps.Marker({
          map,
          name: pos.name,
          ridge: pos.ridge,
          position: pos.position,
          index: pos.index,
          row: pos.row,
          icon: blueMarker
        })

        marker.addListener("click", async () => {
          let name = marker.name
          if (marker.ridge) {
            name = name + ' （' + marker.ridge + '）'
          }
          await dialogs.showInfoDialog(name, marker.row.siteAddress)
        })
        return marker
      })
      // new MarkerClusterer({ map, markers })
    },

    /**
     * ピンのマーカーをセット
     * @param pos 対象データ
     */
    setMarker(pos) {
      let color = 'red'
      // 完了したら、青
      if (pos.row.completionDate) {
        color = 'blue'
      }
      // 図マーカーSVGデータ
      let iconUrl = `data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="-20 0 40 40">
                      <!-- カスタムアイコンのSVGパス -->
                      <path d="M0 0q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z" fill="${color}" fill-opacity="0.8" stroke-width="0"/> 
                      <text x="0%" y="22%" text-anchor="middle" fill="white" font-size="10" dy=".3em">${ pos.index + 1 }</text>
                      </svg>`
                    
      // マーカー（ピン）
      return {
        url: iconUrl,
        fillOpacity: 0.7,
        scaledSize: new google.maps.Size(80, 80),
        anchor: new google.maps.Point(40, 40),
      }
    },

    /**
     * 並べ替え変更
     * @param arg イベント
     */
    changeSort(arg) {
      if (arg.moved) {
        this.isChangeSort = true
        this.initMap()
      }
    },

    /**
     * 並べ替え保存
     */
    async saveSort() {
      await backend.postData('patrolSchedule/upDateSort', { list: this.schedule, user: this.$store.getters.user.id })
      await dialogs.showSuccessDialog('保存完了', '現在の並びでデータを保存しました。')
      this.isChangeSort = false
      this.sortable = false
    },

    /**
     * 未登録の新規物件モーダルclose
     */
    closed() {
      this.openNotRegisterdModal = false
      // this.getData()
    }
  },
}
</script>
<style scoped>
/* .main {
  min-width: 100%;
} */

.bg-colu {
  background: #ebf0fb;
}

.col-no {
    width: 70px;  
}

.min-200 {
    min-width: 200px;
}

.min-100 {
    min-width: 100px;
}

.hover-color:hover {
  background: rgba(163, 231, 228, 0.67) !important;
  cursor: pointer;
}

.border-b {
  border: solid 1px gray ;
}

.icon-green {
  color: darkcyan;
}

#map {
  height: 750px;
}

#filterDate >>> input[type=text] {
  height: 37.8px;
  margin-top: -3.2px;
  border-radius: 0.375rem !important;
}

#filterDate >>> input::placeholder {
  font-size: 16px;
}
</style>