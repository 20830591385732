import RequestPostApp from './RequestPostApp.vue'
import RequestPost from './pages/RequestPost.vue'
import RequestPostList from './pages/RequestPostList.vue'
import TaskRequest from './pages/TaskRequest.vue'
import RequestPostProgressList from './pages/RequestPostProgressList.vue'
import PurchaseMail from './pages/PurchaseMail.vue'
import EmploymentList from './pages/EmploymentList.vue'

export default {
  path: '/RequestPost',
  component: RequestPostApp,
  children: [
    {
      path: '/RequestPost',
      name: 'RequestPost',
      component: RequestPost,
      meta: { requiresAuth: true, title: 'RP - メインタスク' },
      props: true
    },
    {
      path: '/RequestPostList',
      name: 'RequestPostList',
      component: RequestPostList,
      meta: { requiresAuth: true, title: 'RP - メインタスク一覧' },
      props: true
    },
    {
      path: '/taskRequest',
      name: 'TaskRequest',
      component: TaskRequest,
      meta: { requiresAuth: true, title: 'RP - サブタスク登録' },
      props: true
    },
    {
      path: '/RequestPostProgressList',
      name: 'RequestPostProgressList',
      component: RequestPostProgressList,
      meta: { requiresAuth: true, title: 'RP - サブタスク進捗一覧' },
      props: true
    },
    {
      path: '/PurchaseMail',
      name: 'PurchaseMail',
      component: PurchaseMail,
      meta: { requiresAuth: true, title: 'RP - 発注処理' }
    },
    {
      path: '/EmploymentList',
      name: 'EmploymentList',
      component: EmploymentList,
      meta: { requiresAuth: true, title: 'RP - 雇い入れ集計' }
    }
  ]
}
