<template>
  <div>
    <div v-if="downloading" class="flex justify-center items-center loading-dialog3">
      <p class="text-gray-600 font-bold animate-pulse" style="font-size: 30px">Downloading...</p>
    </div>
    <PageHeader :title="`apollo-RP - ${typeName}${claim?' クレーム対応' : ''}`" class="list">
      <template #title-header-content>
        <div class="flex-1 flex ml-2 justify-end items-center">
          
          <!-- サイクル期限 -->
          <div class=" mr-auto">
            <div class=" text-white items-center text-xs">
              {{ type==1||type==4?'サイクル期限':'施工月' }}
            </div>
            <div class="flex">
              <input
                type="number"
                name="termYearFilter" 
                placeholder="期限"
                v-tooltip="alertYear?'4桁で入力してください':''"
                class="w-16 py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900 inline" 
                v-model="termYearFilter"
              />
              <div class="ml-1 mr-2 my-auto text-white">年</div>
              <input
                type="text"
                name="termMonthFilter" 
                placeholder="期限（月）"
                v-tooltip="alertMonth?'不正な値が入っています':'スペースで複数月検索可能'"
                class="w-36 py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900 inline" 
                v-model="termMonthFilter"
              />
              <div class="ml-1 mr-1 my-auto text-white">月</div>
              <div class="my-auto text-xs">
                <PrimaryButton text="サイクル期限削除" size="xs" class="bg-green-600 hover:bg-green-700" @click="clearTerm()" :buttonContents="'要'" />
              </div>
            </div>
          </div>

          <PrimaryButton v-if="type==2" text="選択したものをダウンロード" @click="download(null, 'check')" :buttonContents="'要'" class="mr-3 bg-blue-600">
            <Icon slot="after" iconName="Download" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
          </PrimaryButton>

          <!-- 検索ボタン -->
          <PrimaryButton
            text="検索"
            class="flex button-contents"
            @click="getData()"
            :buttonContents="'要'"
          >
          </PrimaryButton>

          <h1 class="text-lg font-bold text-white flex items-center ml-2">
            <Icon iconName="" class="w-7 h-7 mr-2" /> <span class="mt-0 ">{{ list.length }} 件</span>
          </h1>

          <div class="ml-2 flex">
            <PrimaryButton text="クリア" size="normal px-2" class="normalButtonColor" @click="clear" :buttonContents="'要'" />
          </div>

          <Icon 
            iconName="Refresh" 
            :clickable="false" 
            :class="{'animate-spin': loading}" 
            class="text-white hover:text-gray-300 active:text-gray-400 ml-4 cursor-pointer"
            @click.native="refresh"
          />
        </div>
      </template>
      <template #page-header-content>
        <div class="pl-4 flex justify-end">
          <h1 v-if="!(type==1&&!claim&&isMobileDevice)" class="text-lg font-bold text-white flex items-center mr-auto">
            <Icon iconName="" class="w-7 h-7 mr-2" />
            <span class="mt-0">{{ type==1||type==4?'点検済':'作業済' }}</span>
          </h1>

          <!-- エリア・チーム名 -->
          <div v-if="type==1&&!claim" class="mt-auto mr-2">
            <select
              v-model="filterArea"
              class="rounded-md border-gray-400 py-1.5"
            >
              <option v-for="(area, index) in areaList" :value="area.id" :key="'area' + index">
                {{ area.name }}
              </option>
            </select>
          </div>
          <!-- 点検日 -->
          <div class="mt-5 mr-2">
            <DateSelect
              id="filterDate"
              v-if="true"
              ref="dateSelect"
              :presetName="periodPresetName"
              :period="period"
              :none="true"
              @change="periodChange"
              :titleText="type==1||type==4?'点検日:':'作業日:'"
            />
          </div>
          <!-- 得意先 -->
          <div class="mr-2 mt-5">
            <input
              v-tooltip="'記号・スペースは無視します'"
              type="search"
              name="filterClient" 
              placeholder="得意先 検索"
              class="w-full py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900" 
              v-model="filterClient"
            />
          </div>
          <!-- 物件名 -->
          <div class="mt-5 mr-2">
            <input
              v-tooltip="'記号・スペースは無視します'"
              type="search"
              name="filterSite" 
              placeholder="物件名 検索"
              class="w-full py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900" 
              v-model="filterSite"
            />
          </div>
          <!-- 担当者 -->
          <div class="mt-5">
            <input
              v-tooltip="'スペースは無視します'"
              type="search"
              name="filterStaff" 
              placeholder="担当者 検索"
              class="w-full py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900" 
              v-model="filterStaff"
            />
          </div>
        </div>     
      </template>
    </PageHeader>

    <main class="absolute top-32 w-screen bottom-4 mt-5 list">
      <div class="mx-auto px-2 sm:px-4 lg:px-8 h-full">
        <div class="bg-white rounded-md border border-gray-200 overflow-auto h-full">
          <!-- 一覧 -->
          
          <div v-if="loading" class="w-full h-full flex justify-center items-center">
            <p class="text-gray-400 font-bold animate-pulse">Searching...</p>
          </div>

          <div v-show="!loading">
            <div class="rounded main-height">
              <table class="main table-auto overflow-auto">
                  <thead class="main sticky top-0">
                    <tr v-if="list && list.length" class="border border-gray-200">
                      <th v-for="(c, index) in columns" :key="'cu2'+index" scope="col" class="px-3 py-3 text-xs font-medium text-gray-500 tracking-wider bg-colu" :class="c.class" @click="c.title=='選択解除'?checkedId=[]:null">
                        {{c.title}}
                      </th>
                    </tr>
                  </thead>

                <tr v-for="(row, i) in list" :key="'l'+i" class="border border-gray-200 bg-white tracking-wider hover-color">

                  <!-- No -->
                  <td class="b-top text-left px-3 py-3 whitespace-nowrap text-sm font-medium text-gray-500 col-no cursor-not-allowed" @click="open(row)">
                    {{ i + 1 }}.
                  </td>
                  <!-- サイクル -->
                  <td class="px-3 py-3 whitespace-prerap text-sm text-gray-700 " @click="open(row)">
                    {{ row.termDate }}
                  </td>
                  <!-- チーム名（担当エリアのこと） -->
                  <td v-if="type==1&&!claim" class="px-3 py-3 whitespace-prerap text-sm text-gray-700 " @click="open(row)">
                    {{ row.area }}
                  </td>
                  <!-- 得意先 -->
                  <td class="px-3 py-3 whitespace-prerap text-sm text-gray-700 " v-tooltip="`得意先コード：${row.clientCode}`" @click="open(row)">
                    {{ row.clientName1 }}
                  </td>
                  <!-- 物件名 -->
                  <td class="px-3 py-3 whitespace-prerap text-sm text-gray-700 " v-tooltip="`物件コード：${row.siteCode}`" @click="open(row)">
                    {{ row.siteName }}
                    <span v-if="row.ridgeCode" class="text-red-700 font-bold">（{{ row.ridgeName }}）</span>
                  </td>
                  <!-- 点検日 -->
                  <td class="px-3 py-3 whitespace-prerap text-sm text-gray-700 min-100" @click="open(row)">
                    {{ row.completionDate }}
                    <span v-if="type == 3 && row.incomplete"  class="text-xs p-1 bg-green-50 text-green-700 rounded">複数日未完了</span>
                  </td>
                  <!-- 天気 -->
                  <td v-if="type==3" class="px-3 py-3 whitespace-prerap text-sm text-gray-700 min-100" @click="open(row)">
                    {{ row.weather }}
                  </td>
                  <!-- 担当者 -->
                  <td class="px-3 py-3 whitespace-nowrap text-sm text-gray-700 " @click="open(row)">
                    {{ row.staff }}
                  </td>
                  <!-- 点数 -->
                  <td v-if="type==1" class="text-center px-3 py-3 whitespace-prerap text-sm text-gray-700 w-36" @click="open(row)">
                    {{ row.getPoint || 0 }} / {{ row.totalPoint || 0}}
                  </td>
                  <!-- 平均 -->
                  <td class="text-right px-3 py-3 whitespace-prerap text-sm text-gray-700 w-36" @click="open(row)">
                    {{ row.times }}
                  </td>
                  <!-- ダウンロード -->
                  <td class="text-center w-24" v-if="claim || type == 2 || type == 3 || type == 4">
                    <span v-if="type == 3 && !row.main"  class="text-xs p-1 bg-blue-50 text-blue-700 font-bold rounded">{{row.count}}日目</span>
                    <PrimaryButton v-tooltip="'ダウンロード'" @click="download(row, null)" :buttonContents="'要'" v-else-if="claim || type == 2 || (type == 3 && !row.incomplete && row.main) || type == 4">
                      <Icon slot="before" iconName="Download" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
                    </PrimaryButton>
                  </td>
                  <!-- ダウンロード（1・2回目まとめる） -->
                  <td class="text-center w-28" v-if="type == 2">
                    <span v-if="Number(row.counting)>1"  class="text-xs p-1 bg-blue-50 text-blue-700 font-bold rounded">{{row.counting}}回目</span>
                    <PrimaryButton v-else v-tooltip="'ダウンロード'" @click="download(row, 'wrap')" :buttonContents="'要'">
                      <Icon slot="before" iconName="Download" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
                    </PrimaryButton>
                  </td>
                  <!-- ダウンロード（選択） -->
                  <td class="text-center w-24" v-if="type == 2">
                    <input type="checkbox" class="p-2" :id="row.patrolScheduleId" :value="row.patrolScheduleId" v-model="checkedId">
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Icon from '@components/Icon.vue'
import PageHeader from '@components/PageHeader.vue'
import DateSelect from '@components/DateSelect.vue'
import * as moment from 'moment'
import * as utils from '@libs/utils'
import * as backend from '@libs/backend'
import * as dialogs from '@libs/dialogs'
import PrimaryButton from '@components/PrimaryButton.vue'

export default {
  components: {
    Icon,
    // WmsDateInput,
    PageHeader,
    DateSelect,
    PrimaryButton
  },

  props: {
    // タイトル名
    typeName: {
      type: String,
      default: null
    },
    // 区分
    type: {
      type: Number,
      default: null
    },
    // クレームか否か
    claim: {
      type: Boolean,
      default: false
    },
    // セッションストレージ名
    settionStrageName: {
      type: String,
      default: null
    },
  },

  data() {
    return {
      loading: false,
      downloading: false,
      
       //検索保持用
      searchCondition: {
        termYearFilter:'',
        termMonthFilter: '',
        filterClient : '',
        filterSite : '',
        filterStaff: '',
        period: {},
        periodPresetName: '',
        filterArea: null
      },
      // カラム
      columns: [
          {title: 'No.', class: 'text-left'},
          {title: 'サイクル期限', class: 'text-left'},
          {title: '得意先', class: 'text-left'},
          {title: '物件名', class: 'text-left'},
          {title: '点検日', class: 'text-left'},
          {title: '担当者', class: 'text-left'},
          {title: '点数', class: 'text-center'},
          {title: '平均/日', class: 'text-right'},
          {title: 'ダウンロード', class: 'text-righ'},
      ],
      // サイクル絞込み
      termYearFilter: moment().format('YYYY'),
      alertYear: false,
      termMonthFilter:'',
      alertMonth: false,
      // 点検日
      period: {
        start: '',
        end: ''
      },
      filterArea: null,
      periodPresetName: 'custom',
      // 得意先絞込み
      filterClient: '',
      // 物件名絞込み
      filterSite: '',
      // 担当者絞込み
      filterStaff: '',
      // 表示するデータ
      list: [],
      // チェックリスト
      checkList: {clientName: '', siteName: '', date: '', staff: ''},
      // モーダルopen
      openModal: 0,
      // termMonthFilter初期値
      tmf:'',
      // 巡回清掃チェックしたID
      checkedId: [],
      // 品質巡回のみ、担当エリアリスト
      areaList: []
    }
  },

  computed: {
    /**
     * スマホ
     */
    isMobileDevice() {
      return utils.deviceInfo.isMobile
    },
  },

  watch: {    
    /**
     *  サイクル期限（年）桁数
     */
    termYearFilter() {
      if (this.termYearFilter.length && this.termYearFilter.length != 4) {
        this.alertYear = true
      } else {
        this.alertYear = false
      }
    },

    /**
     * サイクル期限（月）半角変換
     */
    termMonthFilter() {
      if (this.termMonthFilter) {
        this.termMonthFilter = utils.hankaku(this.termMonthFilter)
        let tm = this.termMonthFilter
        if (tm == '') {
          this.alertMonth = false
          return
        }
        tm = tm.trim()
        let arr = tm.split(' ')
        for (let i = 0; i < arr.length; i++) {
          const a = arr[i]
          if (isNaN(a) || a < 1 || a > 12) {
            this.alertMonth = true
            return
          }
        }
      }
      this.alertMonth = false
    }
  },

  async created() {
    // 巡回清掃でも定期清掃でも設備点検でもクレーム対応でない場合、カラムからダウンロードを削除
    if (!this.claim && this.type != 2 && this.type != 3 && this.type != 4) {
      this.columns.splice(8, 1)
    }
    // 品質巡回以外の場合、点数を削除
    if (this.type != 1) {
      this.columns.splice(6, 1)
    }
    // 品質巡回以外の場合
    if (this.type == 1 && !this.claim) {
      this.columns.splice(2, 0, {title: '担当エリア名', class: 'text-left'})
    }
    // 品質巡回以外はカラムの文言を変更
    if (this.type == 2 || this.type == 3) {
      this.columns[1].title = '施工月'
      this.columns[4].title = '作業日'
    }
    // 巡回清掃
    if (this.type == 2) {
      this.columns.push({title: '1・2回まとめて', class: 'text-center'})
      this.columns.push({title: '選択解除', class: 'text-center rounded bg-red-200 text-white cursor-pointer'})

    // 定期清掃
    } else if (this.type === 3) {
      this.columns.splice(5, 0, {title: '天候', class: 'text-left'})
    }
    const d = moment().format('MM')
    switch (d) {
      case '01':
      case '02':
      case '03':
        this.tmf = '1 2 3'
        break;

      case '04':
      case '05':
      case '06':
        this.tmf = '4 5 6'
        break;

      case '07':
      case '08':
      case '09':
        this.tmf = '7 8 9'
        break;

      case '10':
      case '11':
      case '12':
        this.tmf = '10 11 12'
        break;
    }
    this.termMonthFilter = this.tmf
    // 条件取得
    this.getCondition()
    
    await this.getData()
    await this.getAreaList()
  },

  methods: {
    /**
     *  条件取得
     */
    getCondition(){
      const searchJson = sessionStorage.getItem(this.settionStrageName)
      const getItem = JSON.parse(searchJson)

      if (getItem !== null && getItem !== undefined) {
        this.termYearFilter = getItem.termYearFilter
        this.termMonthFilter = getItem.termMonthFilter
        this.filterClient = getItem.filterClient
        this.filterSite = getItem.filterSite
        this.filterStaff = getItem.filterStaff
        this.period = getItem.period || this.period
        this.periodPresetName = getItem.periodPresetName || this.periodPresetName
        this.filterArea = getItem.filterArea || this.filterArea
      }
    },

    /**
     * データ取得
     */
    async getData() {
      // サイクル期限チェック
      if (this.alertYear) {
        alert('サイクル期限（年）は4桁で入力してください。')
        return
      }
      if (this.alertMonth) {
        alert('サイクル期限（月）の値が不正です。')
        return
      }

      this.loading = true
      this.list = []
      let termMonthFilter = ''

      // サイクル期限の月が入力されている場合
      if (this.termMonthFilter) {
        this.termMonthFilter = this.termMonthFilter.trim()
        let monthAr = this.termMonthFilter.split(' ')
        if (monthAr && monthAr.length) {
          for (let i = 0; i < monthAr.length; i++) {
            monthAr[i] = ('00' + monthAr[i]).slice(-2)
          }
          termMonthFilter = monthAr.join('\',\'')
        }
      }

      // セッションストレージ保存用
      this.searchCondition.termYearFilter = this.termYearFilter
      this.searchCondition.termMonthFilter = this.termMonthFilter
      this.searchCondition.filterClient = this.filterClient
      this.searchCondition.filterSite = this.filterSite
      this.searchCondition.period = this.period
      this.searchCondition.periodPresetName = this.periodPresetName
      this.searchCondition.filterStaff = this.filterStaff
      this.searchCondition.filterArea = this.filterArea
      
      this.setSessionStorage(this.searchCondition,this.settionStrageName)

      const checkR = await backend.postData('patrolResult/getByTerm', { termYearFilter: this.termYearFilter, termMonthFilter, filterSite: this.filterSite, filterClient: this.filterClient, period: this.period, filterStaff: this.filterStaff, filterArea: this.filterArea, type: this.type, claim: this.claim })
      if (checkR.data && checkR.data.data.length) {
        this.list = this.forSubDate(checkR.data.data)
      }
      this.loading = false
    },

    /**
     * 担当エリア候補を取得
     */
    async getAreaList() {
      this.areaList = []
      // 品質巡回の場合のみ
      if (this.type == 1 && !this.claim) {
        const res = await backend.searchData('patrolDepartmentArea/getGroupByArea')
        if (res.data && res.data.data && res.data.data.length) {
          for (let i = 0; i < res.data.data.length; i++) {
            const d = res.data.data[i]
            this.areaList.push({ id: d.name, name: d.name })
          }
          const msg = [{ id: null, name: 'エリア名検索' }]
          this.areaList = msg.concat(this.areaList)
        }
      }
    },

    /**
     * 定期清掃の連日があるデータを成形
     */
    forSubDate(tar) {
      // 定期清掃の場合、連日作業の行を修正
      if (this.type == 3) {
        let count = 1
        for (let i = 0; i < tar.length; i++) {
          const t = tar[i]
          if (!t.main) {
            count++
            t.count = count
          } else {
            count = 1
          }
        }
      }
      return tar
    },

    /**
     * 点検日検索 変更
     */
    periodChange(range, presetName) {
      this.period = range
      this.periodPresetName = presetName
    },

    /**
     * 検索条件をセッションストレージへ保存
     * @param {Object} searchCondition 
     * @param {String} storageName 
     */
    setSessionStorage(searchCondition,storageName) {
      const searchStr = JSON.stringify(searchCondition)
      sessionStorage.setItem(storageName, searchStr)
    },

    /**
     * 点検表open
     * @param row 対象行
     */
    open(row) {
      // paramにスケジュールIDを渡す
      let scheduleId = row.patrolScheduleId
      // 品質巡回
      if (this.type == 1) {
        // 点検表に遷移
        this.$router.push({ 
          name: 'CheckListTable',
          query: { scheduleId, claim: false, sub: -1 },
          params: { scheduledDate: row.scheduledDate, routerName: 'CheckList' }
        })
      // 巡回清掃
      } else if (this.type == 2) {
        // 点検表に遷移
        this.$router.push({ 
          name: 'RoundCheckListTable',
          query: { scheduleId, claim: false, sub: -1 },
          params: { scheduledDate: row.scheduledDate, routerName: 'RoundCheckList' }
        })
      // 定期清掃
      } else if (this.type == 3) {
        // 点検表に遷移
        this.$router.push({ 
          name: 'FixedCheckListTable',
          query: { scheduleId, claim: false, sub: -1 },
          params: { scheduledDate: row.scheduledDate, routerName: 'FixedCheckList' }
        })
      // 設備点検
      } else if (this.type == 4) {
        // 点検表に遷移
        this.$router.push({ 
          name: 'FacilityCheckListTable',
          query: { scheduleId, claim: false, sub: -1 },
          params: { routerName: 'FacilityCheckList' }
        })
      }
    },

    async refresh(){
      this.loading = true
      await utils.wait(500)
      await this.getData()
      await utils.wait(500)
      this.loading = false
    },

    // 検索条件をクリア
    clear() {
      window.sessionStorage.clear()
      this.$router.go({path: this.$router.currentRoute.path, force: true})
    },

    // サイクル期限を削除する
    clearTerm() {
      this.termYearFilter = ''
      this.termMonthFilter = ''
    },

    /**
     * ダウンロードイベント
     * @param row 対象行
     * @param output 巡回清掃の出力方法
     */
    async download(row, output) {
      this.downloading = true
      let param = {}
      let res
      let rls = []

      if (this.type != 2 || !output) {
        param = { patrolScheduleId: row.patrolScheduleId }
      }
      // 巡回清掃
      if (this.type == 2) {
        param.output = output

        // 1・2回目をまとめて
        if (output == 'wrap') {
          param.termDate = row.termDate.replace(/\//g, '-')
          param.siteCode = row.siteCode
        // チェックがついたもの
        } else if (output == 'check') {
          if (!this.checkedId || !this.checkedId.length) {
            this.downloading = false
            await dialogs.showErrorDialog('未選択', '選択されたデータがありません。')
            return
          }
          param.checkedId = this.checkedId
        }
        res = await backend.postBlob('patrolDownload/downloadRoundClean', param)
        // ファイル名生成のため、データを取得
        rls = await backend.searchData('patrolResult/getDownloadData', param)

      // 定期清掃
      } else if (this.type == 3) {
        param.completionDate = row.completionDate
        res = await backend.postBlob('patrolDownload/downloadFixedClean', param)

      // 設備点検
      } else if (this.type == 4) {
        res = await backend.postBlob('patrolDownload/downloadFacility', param)

      // クレーム
      } else {
        param = { patrolScheduleId: row.patrolScheduleId, claim: true }
        res = await backend.postBlob('patrolDownload/downloadByClient', param)
      }
      try {
        const uint8Array = res.data
        const blob = new Blob([uint8Array], { "Content-Type" : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
        const a = document.createElement("a")
        a.href = (window.URL || window.webkitURL).createObjectURL(blob)
        let name = ''
        if (row && row.clientName1) {
          name = row.clientName1.replace('株式会社', '') + '様'
        }
        if (row && row.siteName) {
          let ridge = ''
          if (row.ridgeCode) {
            ridge = '_' + row.ridgeName
          }
          name += '【' + row.siteName + ridge + '】'
        }
        if (this.type == 1) {
          name += '品質巡回報告書'
        } else if (this.type == 2) {
          name = '巡回清掃'
          const nameList = []
          if (rls.data && rls.data.data) {
            for (let i = 0; i < rls.data.data.length; i++) {
              name = ''
              const r = rls.data.data[i]
              // 作業日
              if (r.completionDate) {
                name += moment(r.completionDate).format('YYYYMM') + '_'
              }
              // 物件番号（アートアベニューのみ）
              if (r.siteNo) {
                name += r.siteNo + '_'
              }
              // 物件名
              name += r.siteName

              // 選択ダウンロード以外
              if (output != 'check') {
                nameList.push(name)
                break
              } else if (!nameList.includes(name)) {
                nameList.push(name)
              }
            }
          }
          if (nameList.length) {
            name = nameList.join(',')
          }
        } else if (this.type == 3) {
          if (row && row.siteName) {
            name += '定期清掃報告書'
          }
        } else if (this.type == 4) {
          name += '設備点検報告書'
        }

        // 巡回清掃のみファイル名指定あり
        if (this.type == 2) {
          a.download = name + '.xlsx'
        } else {
          a.download = name + moment().format('YYYYMMDD') + '.xlsx'
        }
        a.click();
        a.remove();
        this.downloading = false
      } catch (e) {
        console.log(e)
        alert(e)
        this.downloading = false
      }
    },
  },
}
</script>
<style scoped>

.list {
  min-width: 1250px !important;
  /* bottom: auto !important; */
}

.main {
  width: 100%;
}

.bg-colu {
    background: #ebf0fb;
}

.col-no {
    width: 70px;  
}

.min-200 {
    min-width: 200px;
}

.min-100 {
    min-width: 100px;
}

.hover-color:hover {
  background: rgba(163, 231, 228, 0.67) !important;
  cursor: pointer;
}

.border-b {
  border: solid 1px gray ;
}

#filterDate {
  width: 256px !important;
}

#filterDate >>> div {
  color: rgb(70, 70, 70) !important;
}

#filterDate >>> input[type=text] {
  background: white !important;
}
</style>