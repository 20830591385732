import EvaluationApp from './EvaluationApp.vue'
import EvaluationInput from './pages/EvaluationInput.vue'
import EvaluationList from './pages/EvaluationList.vue'

export default {
  path: '/Evaluation',
  component: EvaluationApp,
  children: [
    {
      path: '/EvaluationInput',
      name: 'EvaluationInput',
      component: EvaluationInput,
      meta: { requiresAuth: true, title: 'apollo-RP - 評価シート' },
      props: true
    },
    {
      path: '/EvaluationList',
      name: 'EvaluationList',
      component: EvaluationList,
      meta: { requiresAuth: true, title: 'apollo-RP - 評価一覧' },
      props: true
    },
  ]
}
