/* eslint-disable no-irregular-whitespace */

import Sugar from 'sugar'
import 'sugar-language'
import * as moment from 'moment'
import * as constants from '@libs/constants'
import { REGULAR_STAFF_AUTH } from '@/config'
import * as backend from '@libs/backend'
import EXIF from "exif-js";

export * as deviceInfo from 'mobile-device-detect'

require('sugar/locales/ja');

// Sugar.Date.setLocale('ja')

const DATE_FORMAT = '{yyyy}-{MM}-{dd}'

const FormatterTable = {
  'number': new Intl.NumberFormat(),
  'currency': new Intl.NumberFormat({ style: 'currency', currency: 'JPY' })
}

export function formatNumber(value, currency) {
  const formatter = currency ? FormatterTable['currency'] : FormatterTable['number']
  if (formatter) {
    return formatter.format(Number(value))
  } else {
    return value
  }
}

export function hankaku(text) {
  if (!text) {
    return text
  }
  return Sugar.String.hankaku(text)
}

export function zenkaku(text) {
  return Sugar.String.zenkaku(text)
}

export function sanitizeStringForNumber(text) {
  const sanitized = Sugar.String.toNumber(text)
  if (isNaN(sanitized)) {
    return ''
  } else {
    return String(sanitized)
  }
}

export function getToday() {
  return getDate('today')
}

export function getDate(date) {
  if (!date) {
    return ''
  }
  const d = Sugar.Date.create(date)
  const dateStriing = Sugar.Date.format(d, DATE_FORMAT)
  return dateStriing
}

export function addDays(date, num) {
  const d = Sugar.Date.addDays(Sugar.Date.create(date), num)
  const dateStriing = Sugar.Date.format(d, DATE_FORMAT)
  return dateStriing
}

export function addMonths(date, num) {
  const d = Sugar.Date.addMonths(Sugar.Date.create(date), num)
  const dateStriing = Sugar.Date.format(d, DATE_FORMAT)
  return dateStriing
}

export function addYears(date, num) {
  const d = Sugar.Date.addYears(Sugar.Date.create(date), num)
  const dateStriing = Sugar.Date.format(d, DATE_FORMAT)
  return dateStriing
}

export function formatDate(d, format) {
  return Sugar.Date.format(d, format, 'ja')
}

export function formatDateString(s, format) {
  const d = stringToDate(s)
  return formatDate(d, format)
}

export function stringToDate(dateString) {
  return Sugar.Date.create(dateString)
}

export function dateToString(date) {
  return getDate(date)
}

export function isAfter(d, margin) {
  const date = stringToDate(d)
  return Sugar.Date.isAfter(date, margin)
}

export function floor(num, precision) {
  return Sugar.Number.floor(Number(num), precision)
}

export function format(num, place) {
  return Sugar.Number.format(Number(num), place)
}

export function round(num, precision) {
  return Sugar.Number.round(Number(num), precision)
}

export function ceil(num, precision) {
  return Sugar.Number.ceil(num, precision)
}

export function objectValues(obj) {
  return Sugar.Object.values(obj)
}

export function every(target, search) {
  if (isArray(target)) {
    return Sugar.Array.every(target, search)
  } else if (isObject(target)) {
    return Sugar.Object.every(target)
  } else {
    return false
  }
}

export function toPlainObject(obj) {
  return JSON.parse(JSON.stringify(obj))
}

export function isObject(value) {
  return Sugar.Object.isObject(value)
}

export function isArray(value) {
  return Array.isArray(value)
}

export function addToArray(array, item) {
  Sugar.Array.append(array, item)
}

export function removeFromArray(array, searchFn) {
  Sugar.Array.remove(array, searchFn)
}

export function mergeObject(src, dest) {
  return Sugar.Object.merge(src, dest, { deep:true })
}

export function addObject(src, dest) {
  return Sugar.Object.add(src, dest, { deep: true })
}

export function unique(arr, fn) {
  return Sugar.Array.unique(arr, fn)
}

export function count(arr, search) {
  return Sugar.Array.count(arr, search)
}

export function clone(val) {
  return JSON.parse(JSON.stringify(val))
}

export function clone2(val) {
  return Sugar.Object.clone(val, true)
}

export function compact(arr) {
  return Sugar.Array.compact(arr, true)
}

export function evaluate(context, expression) {

  const func = new Function(
    ...Object.keys(context),
    'return ' + expression
  )

  const result = func(...Object.values(context))
  
  return result
}

export function isEquals(val1, val2) {
  return Sugar.Object.isEqual(val1, val2)
}

export function swapArray(arr, fromIndex, toIndex) {
  arr.splice(toIndex, 0, arr.splice(fromIndex, 1)[0])
}

export function formatString(format, args) {
  return Sugar.String.format(format, args)
}

export function formatBytes(bytes) {
  if(bytes < 1024) return bytes + " Bytes"
  else if(bytes < 1048576) return(bytes / 1024).toFixed(0) + " KB"
  else if(bytes < 1073741824) return(bytes / 1048576).toFixed(0) + " MB"
  else return(bytes / 1073741824).toFixed(0) + " GB"
}

export async function fileToDataUrl(file) {
  const dataUrl = await new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
  return dataUrl
}

export async function dataUrlToBlob(dataUrl) {
  const blob = await (await fetch(dataUrl)).blob()
  return blob 
}

export async function dataUrlToFile(dataUrl, filename, mimetype) {
  if (!mimetype) {
    mimetype = dataUrl.substring(dataUrl.indexOf(":") + 1, dataUrl.indexOf(";"))
  }
  const blob = await (await fetch(dataUrl)).blob()
  const file = new File([blob], filename, { type: mimetype, lastModified:new Date() })
  return file

  // const mimetype = dataUrl.substring(dataUrl.indexOf(":") + 1, dataUrl.indexOf(";"))
  // const binary = atob(dataUrl.split(',')[1]);
  // const array = [];
  // for(let i = 0; i < binary.length; i++) {
  //   array.push(binary.charCodeAt(i));
  // }
  // return new Blob([new Uint8Array(array)], {type: mimetype});
}

export async function urlToFile(url, filename) {
  const blob = await fetch(url).then((r) => r.blob())
  const dataUrl = await new Promise((resolve) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
  const file = await dataUrlToFile(dataUrl, filename)
  return file
}

export function toQueryString(obj) {
  return Sugar.Object.toQueryString(obj)
}

export function flatten(arr) {
  return Sugar.Array.flatten(arr)
}

export function getSystemOrigin() {
  return window.document.location.origin
}

export function pad(num, digits) {
  return Sugar.Number.pad(num, digits)
}

export function toNumber(text, defaultValue) {
  if (!text) {
    return defaultValue
  } else {
    return Sugar.String.toNumber(text) || 1
  }
}


export function buildMongoQuery(searchText, searchKeys) {
  searchText = searchText.trim()
  if (searchText === '') {
    return null
  } else {
    const keywords = searchText.split(/\s+/g)
    const condition = { $and: [] }
    keywords.forEach(function(keyword) {
      const query = { $or: [] }
      searchKeys.forEach(function(searchKey) {
        const expression = {}
        expression[searchKey] = { $regex: keyword, $options: 'i'}
        query.$or.push(expression)
      });
      condition.$and.push(query);
    });
    return condition;
  }
}

export function sortBy(arr, mapFn, desc) {
  Sugar.Array.sortBy(arr, mapFn, desc)
}

export function groupBy(arr, mapFn, groupFn) {
  return Sugar.Array.groupBy(arr, mapFn, groupFn)
}

export function sum(arr, fn) {
  return Sugar.Array.sum(arr, fn)
}

export function exists(arr, fn) {
  const found = Sugar.Array.find(arr, fn)
  if (found) {
    return true
  } else {
    return false
  }
}

export function generateUuid() {
  // https://github.com/GoogleChrome/chrome-platform-analytics/blob/master/src/internal/identifier.js
  // const FORMAT: string = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx";
  let chars = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".split("");
  for (let i = 0, len = chars.length; i < len; i++) {
    switch (chars[i]) {
      case "x":
        chars[i] = Math.floor(Math.random() * 16).toString(16);
        break;
      case "y":
        chars[i] = (Math.floor(Math.random() * 4) + 8).toString(16);
        break;
    }
  }
  return chars.join("");
}

export async function wait(ms) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, ms)
  })
}

/**
 * 配列より、対象となる要素を取得し、指定プロパティを返却する
 * @param {*} arr:any[] 配列
 * @param {*} searchProp:string 検索条件となるプロパティ
 * @param {*} searchValue:Object 検索条件の値
 * @param {*} getProp:string 取得するプロパティ
 * @returns 対象の指定プロパティ値
 */
export function getArrTargetProp(arr, searchProp, searchValue, getProp) {
  if (arr == null || arr == undefined) return null

  let ret = null
  let targets = arr.filter((item) => {
    return item[searchProp] == searchValue
  })
  if (targets.length > 0) {
    ret = targets[0][getProp]
  }
  return ret
}

/**
 * 配列より、対象となる要素を取得
 * @param {*} arr:any[] 配列
 * @param {*} searchProp:string 検索条件となるプロパティ
 * @param {*} searchValue:Object 検索条件の値
 * @returns 対象の指定プロパティ値
 */
export function getArrTarget(arr, searchProp, searchValue) {
  if (arr == null || arr == undefined) return null

  let ret = null
  let targets = arr.filter((item) => {
    return item[searchProp] == searchValue
  })
  if (targets.length > 0) {
    ret = targets[0]
  }
  return ret
}

/**
 * 「yyyy年MM月dd日」日付書式の文字列へ変換
 * @param {*} dateString 日付文字列
 * @returns 
 */
export function formatDateJa(dateString) {
  if (!dateString) {
    return '---'
  } else {
    const d = stringToDate(dateString)
    return formatDate(d, '{yyyy}年{MM}月{dd}日')
  }
}

/**
 * 時間(時分)チェック
 * @param {*} str 
 * @returns 
 */
export function isTime(str) {
  return str.match(/^([01]?[0-9]|2[0-3]):([0-5][0-9])$/) !== null;
}

/**
 * 送り仮名を削除するためにカッコから後ろを削除
 * @param {*} str 
 */
export function deleteKana(str) {
  if (str.indexOf('（') != -1) {
    str = str.substring(0,  str.indexOf('（'))
  } else if (str.indexOf('(') != -1) {
    str = str.substring(0,  str.indexOf('('))
  }
  return str
}

/**
 * chatworkAccountIdを取得
 * @param {*} userId ユーザーID
 * @param {*} user ユーザーリスト
 */
export function getChatworkAccountId(userId, user) {
  if (userId && userId != '') {
    let result = user.find((u) => {
      return u.id == userId
    })
    if (result && result.chatwork_account_id && result.chatwork_account_id != '') {
      return result.chatwork_account_id
    } else {
      return null
    }
  } else {
    console.log('ユーザーIDがないので、chatworkAccountIdが取得できませんでした')
    return null
  }
}

/**
 * chatworkAccountIdを取得
 * @param {*} name ユーザー名
 * @param {*} user ユーザーリスト
 */
export function getChatworkAccountIdByName(name, user) {
  if (name && name != '') {
    let result = user.find((u) => {
      return deleteKana(u.user_name) == deleteKana(name)
    })
    if (result && result.chatwork_account_id && result.chatwork_account_id != '') {
      return result.chatwork_account_id
    } else {
      return null
    }
  } else {
    console.log('ユーザー名がないので、chatworkAccountIdが取得できませんでした')
    return null
  }
}

/**
 * chatworkRoomId,chatworkApiTokenを取得
 * @param {*} name 部署名
 * @param {*} dep 部署リスト
 */
export function getChatInfo(name, dep) {
  if (name && name != '') {
    let result = dep.find((d) => {
      return d.name == name
    })
    if (result && result.chatworkRoomId && result.chatworkApiToken) {
      return {chatworkRoomId: result.chatwork_account_id, chatworkApiToken: result.chatworkApiToken}
    } else {
      return null
    }
  } else {
    console.log('部署名がないので、chatwork情報が取得できませんでした')
    return null
  }
}

/**
 * ユーザーIDを取得
 * @param {*} name 名前
 * @param {*} list ユーザーリスト
 */
export function getUserIdByName(name, list) {
  if (name && name != '') {
    let result = list.find((u) => {
      return deleteSpace(deleteKana(u.user_name)) == deleteSpace(deleteKana(name))
    })
    if (result && result.id) {
      return result.id
    } else {
      return null
    }
  } else {
    console.log('名前がないので、ユーザーIDが取得できませんでした')
    return null
  }
}

/**
 * 全角半角スペース全削除
 * @param {*} str 対象
 * @returns 
 */
export function deleteSpace (str) {
  if (str != null && str != undefined && str != '') {
    str = str.replace(/　/g, '')
    str = str.replace(/ /g, '')
  }
  return str
}

/**
 * 曜日に変換
 * @param {*} num 数字
 * @returns 
 */
export function toDayOfWeek (num) {
  if (num || num == 0) {
    let week
    switch (Number(num)) {
      case 0:
        week = '日'
        break;
      case 1:
        week = '月'
        break;
      case 2:
        week = '火'
        break;
      case 3:
        week = '水'
        break;
      case 4:
        week = '木'
        break;
      case 5:
        week = '金'
        break;
      case 6:
        week = '土'
        break;
    }
    return week
  }
  return null
}
/**
 * 曜日にを番号に変換
 * @param {*} str 文字
 * @returns 
 */
export function toWeekNumber (str) {
  if (str != null && str != undefined && str != '') {
    let week
    switch (str) {
      case '日':
        week = 0
        break;
      case '月':
        week = 1
        break;
      case '火':
        week = 2
        break;
      case '水':
        week = 3
        break;
      case '木':
        week = 4
        break;
      case '金':
        week = 5
        break;
      case '土':
        week = 6
        break;
    }
    return week
  }
  return null
}

/**
 * 現在位置 住所 取得
 * @returns 
 * 	code: 1:成功　5:端末非対応　6:位置情報取得エラー　7:住所取得エラー,
 *  address: 住所
 */
export async function getLocation() {
  let ret = {
    code: 1,
    address: '',
    lat: null,
    lng: null
  }

  // オプション(省略可)
  const option = {
    "enableHighAccuracy": true, // 精度を上げる
    "timeout": 5000,
    "maximumAge": 0, // 0:キャッシュを使わない
  };

  try {
    if (navigator.geolocation) {
      let watchID
      // 現在位置を取得する
      let position = await new Promise((resolve, reject) => {
        watchID = navigator.geolocation.watchPosition(resolve, reject, option)
      })
      // GPS取得終了
      navigator.geolocation.clearWatch( watchID )
  
      if (!position) {
        ret.code = 6
      } else {
        let data = position.coords
        // 緯度経度取得
        let lat = data.latitude
        let lng = data.longitude
        //Google Maps
        let geocoder = new window.google.maps.Geocoder()
        let latlng = new window.google.maps.LatLng(lat, lng)

        try {
          await geocoder.geocode({ 'latLng': latlng }, function (results, status) {
            if (status == window.google.maps.GeocoderStatus.OK) {
              const addrss = results[0].formatted_address
              const arrAddrsss = addrss.split(' ')
              const arrAddress = arrAddrsss.filter((ad) => {
                return ad.indexOf('都') > -1 || ad.indexOf('道') > -1 || ad.indexOf('府') > -1 || ad.indexOf('県') > -1
              })
              if (arrAddress.length > 0) {
                ret.code = 1
                ret.address = arrAddress[0]
                ret.lat = lat
                ret.lng = lng
              } else {
                ret.code = 7
              }
            } else {
              ret.code = 7
            }
          });
        } catch (ex) {
          ret.code = 7
        }
      }
    } else {
      ret.code = 5
    }
  } catch (ex) {
    ret.code = 6
  }


  // // リトライ回数
  // let retry = 7

  // // 位置取得もしくはリトライが終わるまでループ
  // for (let rt = 0; rt < retry; rt++) {
  //   try {
  //     if (navigator.geolocation) {
  //       // 現在位置を取得する
  //       let position = await new Promise((resolve, reject) => {
  //         navigator.geolocation.getCurrentPosition(resolve, reject, option)
  //       })
    
  //       if (!position) {
  //         ret.code = 6
  //       } else {
  //         let data = position.coords
  //         // 緯度経度取得
  //         let lat = data.latitude
  //         let lng = data.longitude
  //         //Google Maps
  //         let geocoder = new window.google.maps.Geocoder()
  //         let latlng = new window.google.maps.LatLng(lat, lng)
  
  //         try {
  //           await geocoder.geocode({ 'latLng': latlng }, function (results, status) {
  //             if (status == window.google.maps.GeocoderStatus.OK) {
  //               const addrss = results[0].formatted_address
  //               const arrAddrsss = addrss.split(' ')
  //               const arrAddress = arrAddrsss.filter((ad) => {
  //                 return ad.indexOf('都') > -1 || ad.indexOf('道') > -1 || ad.indexOf('府') > -1 || ad.indexOf('県') > -1
  //               })
  //               if (arrAddress.length > 0) {
  //                 ret.code = 1
  //                 ret.address = arrAddress[0]
  //                 ret.lat = lat
  //                 ret.lng = lng
  //                 rt = retry    // 終了
  //               } else {
  //                 ret.code = 7
  //               }
  //             } else {
  //               ret.code = 7
  //             }
  //           });
  //         } catch (ex) {
  //           ret.code = 7
  //         }
  //       }
  //     } else {
  //       ret.code = 5
  //       break
  //     }
  //   } catch (ex) {
  //     ret.code = 6
  //   }
  // }

  return ret
}

/**
 * 巡回関係の報告書で現在地と物件の位置を比較する
 * @param site 対象物件位置情報
 * @returns 許容範囲か否か
 */
export async function patrolLocation(site) {
  let err = ''
  let msg1 = 'システム改修後、現在地が対象物件の近くでなければ、報告書が作成できなくなります。'
  // 現在地取得
  const current = await getLocation()
  site.staffLat = current.lat
  site.staffLng = current.lng

  // 登録されている物件住所がない場合（定期清掃の未登録物件がこれに該当）
  if (!site.siteAddress) {
    if (current && current.address) {
      return current.address
    }
    return ''
  }

  // 位置情報取得不可
  if (current.code != 1) {
    err = '現在の位置情報（GPS）が取得できません。'
    alert(err + '\r\n' + msg1 + '\r\n【GPS取得できない原因】\r\n・現在地を取得しない設定にしている。\r\n・GPS電波の悪い場所にいる。\r\n・省電力モードにしている。\r\n※GPS許可した場合は『キャンセル』して、再度試してください。')
    saveOutOfGPS(err, site)
  } else {
    // 物件の位置情報がない場合
    if (!site.lat || !site.lng) {
      // 再度取得＆データ保存
      const res = await upDateSitePosition(site, msg1)
      if (res) {
        site.lat = res.lat
        site.lng = res.lng
      } else {
        return ''
      }
    }
    // 緯度経度が許容範囲の場合
    if (await isOutOfGPS(site, current)) {
      return current.address
    // 許容範囲外の場合
    } else {
      // 再度、物件の位置情報を取得（ビルメンが変更された場合に備えて）
      const res = await upDateSitePosition(site, msg1)
      if (res) {
        site.lat = res.lat
        site.lng = res.lng
        // 緯度経度が許容範囲の場合
        if (await isOutOfGPS(site, current)) {
          return current.address
        // それでも範囲外の場合、再度現在地を取得
        } else {
          // 3回トライ
          for (let i = 0; i < 4; i++) {
            // 現在地取得
            const current = await getLocation()
            site.staffLat = current.lat
            site.staffLng = current.lng
            if (await isOutOfGPS(site, current)) {
              return current.address
            }
          }
        }
      }

      // 許容範囲外の場合
      const range = site.gpsDiff * 100000
      err = '現在地と対象物件が' + range + 'ｍ以上離れています。'
      alert(err + '\r\n' + msg1 + '\r\n登録されている物件住所が正しいかご確認ください。\r\n' + `●登録されている住所：${site.siteAddress}\r\n●現在地：${current.address}`)
      saveOutOfGPS(err, site)
    }
  }
  return ''
}

/**
 * GPS許容範囲以内か確認
 * @param {*} site 物件情報
 * @param {*} current 現在地情報
 * @returns 範囲内：true
 */
async function isOutOfGPS(site, current) {
  // 許容の値が0の場合、再取得する
  if (!site.gpsDiff || isNaN(site.gpsDiff)) {
    const res = await backend.searchData('patrolSchedule/getGpsDiff', { type: site.type })
    if (res.data.gpsDiff) {
      site.gpsDiff = res.data.gpsDiff
    } else {
      site.gpsDiff = 0.001
      console.log('GPS許容範囲取得不可')
    }
  }

  // 現在地と比較
  const diff1 = Math.abs(site.lat - current.lat)
  const diff2 = Math.abs(site.lng - current.lng)

  // 緯度経度が許容範囲の場合
  if (diff1 <= site.gpsDiff && diff2 <= site.gpsDiff) {
    return true
  }
  return false
}

/**
 * 物件の住所から緯度経度取得し、更新する
 * @param {*} site 物件情報
 * @returns 
 */
async function upDateSitePosition(site, msg1) {
  const res = await backend.searchData('patrolSchedule/updatePosition', { siteCode: site.siteCode, address: site.siteAddress })
  // 緯度経度取得できた場合
  if (res.data.data) {
    return res.data.data
  } else {
    const err = '物件の住所から緯度経度が取得できませんでした。'
    alert(err + '\r\n' + msg1 + '\r\n' + site.siteName + 'の住所が正しいかご確認ください。\r\n' + `●登録されている住所：${site.siteAddress}`)
    site.siteLat = null
    site.siteLng = null
    saveOutOfGPS(err, site)
    return null
  }
}

/**
 * 報告書作成NGの場合、調査用に保存
 * @param {*} err エラー内容
 * @param {*} site 物件情報
 */
async function saveOutOfGPS(err, site) {
  await backend.postData('patrolSchedule/saveOutOfGps', { err, ...site })
}

/**
 * 巡回関係の報告書で現在地を取得する
 * @returns 現在地
 */
// export async function patrolLocation() {
//   // 現在地取得
//   const ulocation = await getLocation()
//   let location = ''
//   switch (ulocation.code) {
//     case 1:
//       location = ulocation.address
//       break
//     case 5:
//       alert("この端末では、現在位置を取得できません。\r\n「位置情報」は空欄にします。")
//       break
//     case 6:
//       alert("位置情報が取得できませんでした。\r\nキャンセルボタンを押して、再度開始ボタンを押してください。")
//       break
//     case 7:
//       alert("住所の取得に失敗しました。\r\nキャンセルボタンを押して、再度開始ボタンを押してください。")
//       break
//   }
//   return location
// }

/**
 * ユーザーIDからユーザー名を取得
 * @param {*} id id
 * @param {*} list ユーザーリスト
 */
export function getUserNameById(id, list) {
  if (id && id != '') {
    let result = list.find((u) => {
      return id == u.id
    })
    if (result && result.user_name) {
      return result.user_name
    } else {
      return ''
    }
  } else {
    console.log('IDがないので、ユーザー名が取得できませんでした')
    return ''
  }
}

/**
 * 3桁区切りの表示
 * @param value 値
 */
export function numberToLocale(value) {
  // 値が「---」の場合は値をそのまま返す
  if (value == '---') return value
  if (value && value != '') {
      return Number(value).toLocaleString()
  } else {
      return ''
  }
}

/**
 * シングルクォート削除
 * @param value 値
 */
export function deleteMark(value) {
  if (value && value != '') {
      return value.replace(/'/g, '')
  } else {
      return ''
  }
}

/**
 * 初期発注率
 * @param code カテゴリコード
 */
export function getPurchaseInitRate(code, list, selectVal) {
  let rateData
  let rate = 0
  // カテゴリコードがある場合
  if (code && code != '') {
    rateData = list.find((p) => {
      return p.categoryCode == code
    })
    // 発注率デフォルト
    if (rateData && rateData.rate) {
      rate = Number(rateData.rate)
    }

  // カテゴリコード無し
  } else {
    if (selectVal) {
      rate = Number(selectVal)
      if (!rate || isNaN(rate)) {
        rate = 0
      }
    }
  }

  return rate
}

/**
 * 発注率判定
 * @param code カテゴリコード
 * @param rate 率
 */
export function getPurchaseRate(code, sup, list, selectVal) {
  let rate = getPurchaseInitRate(code, list, selectVal)
  // アポロ、もしくはRLSが含まれる発注先はカテゴリに関係なく100％
  if (sup.indexOf('アポロ') > -1 || sup.indexOf('ｱﾎﾟﾛ') > -1 || sup.indexOf('RLS') > -1) {
    return 100
  }
  return rate
}

/**
 * 苗字を抽出
 * @param {*} val 対象
 */
export function getSurname(val) {
  let res = []
  if (val && val != '') {
    if (val.indexOf('　') > -1) {
      res = val.split('　')
      return res[0]
    } else {
      res = val.split(' ')
      return res[0]
    }
  }
  return val
}

/**
 * 項目表項目リスト
 * @param {*} type 区分
 * @returns 
 */
export function setClassification(type) {
  // 定期清掃
  if (type == 3) {
    return [
      {name: '共用階段'},
      {name: '共用廊下'},
      {name: 'アプローチ'},
      {name: 'エントランス'},
      {name: '管理室'},
      {name: 'エレベーターマット'},
      {name: 'エントランスガラス'},
      {name: '屋上ドレン'},
      {name: '桝フィルター'},
      {name: '自転車置場'},
      {name: '灯具清掃'},
      {name: 'ガラス清掃'},
      {name: '側溝・桝清掃'},
      {name: '中庭'}
    ]
  }
}

/**
 * 定期清掃の項目表のカラムを設定
 * @param {*} detail 詳細の有無
 * @param {*} id テンプレートのID
 */
export function setColumn(detail, id) {
  let columns = [
    {title: 'No.'},
    {title: '作業箇所'},
    {title: '作業内容'},
  ]
  // 詳細項目ある場合
  if (detail) {
    // カラム
    columns = [
      {title: 'No.'},
      {title: '作業箇所'},
      {title: ''},
      {title: ''},
      {title: '作業内容'},
    ]

  let d1 = ''
  let d2 = ''
  switch (id) {
    // 大京
    case 2:
      d1 = '清掃箇所'
      d2 = '部材'
      break;

    // ｵﾘｯｸｽ･ﾌｧｼﾘﾃｨｰｽﾞ
    case 3:
      d1 = '人数'
      columns.splice(3, 1)
      break;

    // 三井不動産
    case 4:
      d1 = '実施回数'
      d2 = '清掃区分'
      break;
  
    default:
      break;
  }

  if (d1) {
    columns[2].title = d1
  }
  if (d2) {
    columns[3].title = d2
  }
}

  return columns
}

/**
 * 張り紙タイプを得意先名から判断
 * @param name 得意先名
 */
export function getPosterType (name) {
  let type = 1
  if (hankaku(name).indexOf(hankaku('大京アステージ')) != -1) {
    type = 2
  } else if (hankaku(name).indexOf(hankaku('三井不動産')) != -1) {
    type = 4
  } else if (hankaku(name).indexOf(hankaku('ﾘﾋﾞﾝｸﾞｻﾎﾟｰﾄ')) != -1) {
    type = 4
  } else if (hankaku(name).indexOf(hankaku('三菱地所')) != -1) {
    type = 6
  } else if (hankaku(name).indexOf(hankaku('日商管理')) != -1) {
    type = 7
  } else if (hankaku(name).indexOf(hankaku('野村不動産')) != -1) {
    type = 8
  } else if (hankaku(name).indexOf(hankaku('ケイズ')) != -1) {
    type = 9
  }
  return type
}

/**
 * 時給が範囲内か確認
 * @param {*} val 時給
 * @returns エラーメッセージ
 */
export function validateHourlyWage (val) {
  if (1500 < Number(val)) {
    return '賃金が1,500円より高いため登録できません。'
  } else if(1025 >= Number(val)){
    return '賃金が1,025円以下のため登録できません。'
  }
  return null
}

/**
 * 定期清掃の報告書画面の詳細1、2のプレイスホルダー
 * @param {*} num 詳細1か2
 * @param {*} row 対象行
 * @param {*} tempId テンプレートID
 * @returns 
 */
export function patrolFixedCleanPlaceholder (num, row, tempId) {
  let val = ''
  if (num == 1) {
    switch (tempId) {
      case 2:
        val = '清掃箇所'
        break;
      case 3:
        val = '人数'
        break;
      case 4:
        val = '実施回数'
        break;
      case 6:
        val = '要 補修'
        break;
      case 8:
        val = '清掃種別'
        break;
    }
  } else {
    switch (tempId) {
      case 2:
        val = '部材'
        break;
      case 3:
        val = 'ボタンを押してください'
        if (row.end || row.complete) {
          val = '開始～終了'
        }
        break;
      case 4:
        val = '清掃種類'
        break;
      case 6:
        val = '要 至急補修'
        break;
    }
  }
  return val
}

/**
 * 開始時間から終了時間までの時間を取得
 * @param {*} start 
 * @param {*} end 
 * @returns 
 */
export function getHours (start, end) {
  let res = 0
  if (start && end) {
    if (start.indexOf(':') > -1 && end.indexOf(':') > -1) {
      const s = start.split(':')
      const e = end.split(':')
      if (s && s.length == 2 && e && e.length == 2 && s[0] && s[1] && e[0] && e[1]) {
        const sH = Number(s[0])
        const sm = Number(s[1])
        const eH = Number(e[0])
        const em = Number(e[1])

        let h = eH - sH
        let m = (em - sm) / 60

        res = h + m
      }
    }
  }
  return res
}

/**
 * 定期清掃のテンプレートIDを取得
 * @param {*} clientName1 得意先名1
 * @param {*} siteName 物件名
 * @returns 
 */
export function getTempId (clientName1, siteName) {
  const notDefaultClient = [
    { name: '大京アステージ', id: 2 },
    { name: 'オリックス・ファシリティーズ', id: 3 },
    { name: '三井不動産', id: 4 },
    { name: 'ｴﾑ・ｴﾌ・ﾘﾋﾞﾝｸﾞｻﾎﾟｰﾄ', id: 4 },
    { name: '三菱', id: 6 },
    { name: '東急コミュニティ', id: 7 },
    { name: '大和ライフネクスト', id: 8 }
  ]

  // 大京アステージの「グリーンキャピタル第2上野毛」のみ報告書が別（オリックスと同じ）
  const siteName3 = 'グリーンキャピタル第2上野毛'
  
  for (let i = 0; i < notDefaultClient.length; i++) {
    const c = notDefaultClient[i].name
    const id = notDefaultClient[i].id

    if (clientName1.indexOf(c) != -1 || hankaku(clientName1).indexOf(hankaku(c)) != -1) {
      if (id == 2) {
        if (hankaku(siteName) == hankaku(siteName3)) {
          return 3
        }
      }
      return id
    }
  }
  return null
}

/**
 * 文字列が何行になるか判定
 * @param {*} text 対象文字列
 * @param {*} maxNum 1行の最大文字数
 */
export function getTextRowCount (text, maxNum) {
  let count = 0

  if (!text) {
    return count
  }

  // 指定文字数以外で改行されていない
  if (text <= num && text.indexOf('\r\n') == -1 && text.indexOf('\n') == -1 && text.indexOf('\r') == -1) {
    return 1
  }

  let num = 0
  count++
  for (let i = 0; i < text.length; i++) {
    const t = text[i]
    num++
    if (t == '\r\n' || t == '\r' || t == '\n' || num == maxNum) {
      count++
      num = 0
    }
  }
  return count
}

/**
 * リッチテキストへ貼り付けする際にスタイルを省く
 * @returns 
 */
// export function onPasteNoStyle (e) {
//   let clipboardData = e.clipboardData
//   if(clipboardData){
//     // ペースト中断
//     e.preventDefault()
//     // ペーストする文字列を取得
//     let text = clipboardData.getData('text')
//     // 改行を違う文字列に置換（changeイベントにて元に戻す）
//     text = text.replace(/\r\n|\n|\r/g, '@@@')
//     // 位置を取得
//     const selection = window.getSelection()
//     if (!selection || !selection.rangeCount) {
//       alert('貼り付けできません。')
//       return
//     }
//     // 貼り付け
//     selection.deleteFromDocument()
//     selection.getRangeAt(0).insertNode(document.createTextNode(text))
//   }
// }

/**
 * リッチテキストをコピーする際にスタイルを省く
 */
export async function onCopyNoStyle () {
  let text = await navigator.clipboard.readText()
  if (!text) {
    return
  }
  text = text.replace(/\r\n/g, '@')
  // 先頭に＠があれば削除
  if (text.slice(0, 1) == '@' && text.slice(0, 2) != '@') {
    text = text.substring(1)
  }
  text = text.replace(/@@/g, '\n')

  // IE以外
  if (navigator.clipboard) {
    await navigator.clipboard.writeText(text)
  } else {
    window.Clipboard.setData('Text', text)
  }
}

/**
 * メインタスクの見積カテゴリから見積り作成日の目安日を取得
 * （RLSのみなら1日、定期が入れば2日、その他作業があれば3日）
 * @param {*} category 見積りカテゴリ
 * @returns 日数
 */
export function getScheduleDays (category) {
  if (category && category.length) {
    // RLS、定期以外を取得
    let other = category.filter((c) => {
      return (c != 'ラウンドサービス' && c != '定期清掃')
    })

    // その他もあった場合
    if (other && other.length) {
      return 3
    // その他がない場合
    } else {
      if (category.includes('定期清掃')) {
        return 2
      } else {
        return 1
      }
    }
  }
  return 0
}

/**
 * 文字列日付をフォーマット変換
 * @param {*} day 日付
 * @param {*} format フォーマット
 * @returns 文字列
 */
export function daySt(day, format) {
  const d = new Date(day).toISOString()
  return moment(d).format(format)
}

/**
 * 独自の改行コードで改行
 * @param {*} text 対象テキスト
 * @returns 
 */
export function replaceNewLine (text) {
  if (text) {
    text = text.replace(/＠/g, '@')
    // 改行コード
    const cd = constants.newLineCode
    text = text.replace(cd, '\r\n')
    return text
  } else {
    return text
  }
}

/**
 * 独自の改行コードを削除
 * @param {*} text 対象テキスト
 * @returns 
 */
export function removeNewLine (text) {
  if (text) {
    text = text.replace(/＠/g, '@')
    // 改行コード
    const cd = constants.newLineCode
    text = text.replace(cd, '')
    return text
  } else {
    return text
  }
}

/**
 * 改行を改行コードに変換
 * @param {*} text 対象テキスト
 * @returns 
 */
export function replaceNewLineCode (text) {
  if (text) {
    // 改行コード
    const cd = '@@'
    text = text.replace(/\r\n/g, cd)
    return text
  } else {
    return text
  }
}

/**
 * ユニークな文字列を生成(2~3文字)
 * @returns
 */
export function getUniqueStr () {
  const strong = 1000
  const res = Math.floor(strong * Math.random()).toString(16)
  return res
}

/**
 * 見積りの該当カテゴリーコードの中項目リスト取得
 * @param {*} categoryCode カテゴリーコード
 * @param {*} estimateSubItemList 全中項目リスト
 * @returns 
 */
export function getEstimateSubItemList(categoryCode, estimateSubItemList) {
  let item = []
  if (categoryCode) {
    const list = estimateSubItemList.filter((s) => {
      return s.parentCode == categoryCode
    })

    if (list && list.length) {
      // オブジェクトに変換
      item = list.map((original) => {
        if (original.selectValue) {
          return {...original, itemCode: JSON.parse(original.selectValue.replace(/\//g, ''))}
        } else {
          return {...original}
        }
      })
    }
  }
  return item
}

/**
 * 詳細モーダルで選択した値から、その名前を取得
 * @param {*} selectedValue 選択した値
 * @param {*} item 選択する項目リスト
 * @param {*} categoryName カテゴリー名
 * @param {*} groupName グループネーム
 * @param {*} tabId タブがあるカテゴリのみタブID
 * @param {*} subItemModalData 詳細モーダル内のデータ
 * @param {*} parkingFlg 駐車場料金を表示するか
 * @returns 
 */
export function getSelectedName(selectedValue, item, categoryName, categoryCode, groupName, tabId, tabIdx, subItemModalData, parkingFlg) {
  let res = []

  if (selectedValue && selectedValue.length && item && item.length) {
    for (let i = 0; i < item.length; i++) {
      const s = selectedValue[i]
      const it = item[i]
      const row = {}

      row.name = it.name
      // 入力値の場合
      if (!it.selectValue) {
        row.value = s

      // 選択形式の場合
      } else {
        row.value = it.itemCode[s]
      }

      if (parkingFlg) {
        // 駐車場代を表示
        if (it.item == 'parking' && row.value == 'あり') {
          if (subItemModalData.conf && subItemModalData.conf.parking) {
            // 定期、スポットではない、もしくは、定期・スポットで300㎡を超えていない
            if (!['5', '28'].includes(categoryCode) || (['5', '28'].includes(categoryCode) && ['1', '2'].includes(selectedValue[1])) || (['5', '28'].includes(categoryCode) && selectedValue[1] != '3' && subItemModalData.conf.meter != '3')) {
              const parking = Number(subItemModalData.conf.parking).toLocaleString()
              row.value += ` ➡ ${parking}円`
            }
          }
        }
      }
      // 他の項目によって選択肢から省かれていない場合は表示する
      if (judgementDisable(it, selectedValue, categoryCode)) {
        res.push(row)
      }
    }
  }
  return { categoryName, details: res, categoryCode, groupName, tabId, tabIdx }
}

/**
 * 特別清掃用の詳細モーダルで選択した内容を取得
 * @param {*} item 対象の項目リスト
 * @param {*} display 表
 * @param {*} categoryName カテゴリー名
 * @param {*} groupName グループネーム
 * @param {*} tabId タブがあるカテゴリのみタブID
 * @returns 
 */
export function getSelectedNameForSp(item, display, categoryName, categoryCode, groupName, tabId, tabIdx) {
  const res = []
  for (let i = 0; i < display.length; i++) {
    const d = display[i]
    // 特別清掃の詳細モーダルで選択している場合
    if (d.spModalValue) {
      const row = {}
      const it = item[Number(d.number)]

      row.name = d.name
      row.value = it.itemCode[d.spModalValue]
      // 特別清掃には非表示条件はないので、そのままpush
      res.push(row)
    }
  }
  return { categoryName, details: res, categoryCode, groupName, tabId, tabIdx }
}

/**
 * 他の項目によって選択肢から省かれている場合は、非表示にする
 * @param {*} it 対象の項目リスト
 * @param {*} selectedValue 選択リスト
 * @param {*} categoryCode カテゴリーコード
 * @returns 
 */
export function judgementDisable(it, selectedValue, categoryCode) {
  // ラウンドプラス以外は、協力費を非表示（まだ計算に使用していない）
  if (categoryCode != '2') {
    if (it.item == 'compensation') {
      return false
    }
  }
  // 非表示設定がない場合、表示する
  if (!it.disable) {
    return true
  }
  // RLSの築地目視点検の場合
  if (it.parentCode == '1' && it.order == '04') {
    if (Number(selectedValue[0]) <= Number(it.disable) && ['m1', 'm2', '1', '2'].includes(selectedValue[2])) {
      return true
    } else {
      return false
    }
  }
  // 非表示設定がある場合
  const disp = it.disable.split('-')
  let No = Number(disp[0])
  let value = String(disp[1])
  let res = false
  // 条件に合致しているなら表示
  if (selectedValue[No] == value) {
    res = true

    // 貯水
    if (it.parentCode == '12') {
      // 発注先がニッツなら、ニッツのエリア
      if (it.item == 'eriaNittsu' && selectedValue[1] == '2') {
        res = false
      // 発注先がベスパ―なら、ベスパ―のエリア
      } else if (it.item == 'eriaBesper' && selectedValue[1] == '1') {
        res = false
      }
    }
  } else {
    // 定期・スポットの㎡数不明の場合も戸数表示
    if (['5', '28'].includes(it.parentCode) && it.item == 'households' && selectedValue[1] == '4') {
      return true
    }
  }
  return res
}

/**
 * 詳細モーダルで選択した値を成形
 * @param {*} estimate 見積データ
 * @param {*} estimateSubItemList 見積り中項目リスト
 * @param {*} parkingFlg 駐車場代を表示するか
 * @returns 
 */
export function getSelectedDetails(estimate, estimateSubItemList, parkingFlg) {
  const details = estimate.details
  const selectedDetails = []
  if (!details) {
    return selectedDetails
  }
  for (let i = 0; i < details.length; i++) {
    const d = details[i]
    let item = []
    if (d.rowspan > 0 && d.subItemModalData && Object.keys(d.subItemModalData).length) {
      const md = d.subItemModalData
      const tab = d.subItemModalData.modalTabData
      const categoryCode = d.categoryCode
      const groupName = d.groupName
      // 対象の詳細モーダル項目リスト取得
      item = getEstimateSubItemList(d.categoryCode, estimateSubItemList)

      // modalTabDataがあるもの
      if (tab && tab.length) {
        tab.forEach((t, idx) => {
          let selectedValue = []
          if (t.data && t.data.selectedValue) {
            selectedValue = t.data.selectedValue
          }
          let categoryName = d.categoryName

          if (tab.length > 1) {
            categoryName += `（${(idx + 1)}）`
          }
          const tabIdx = idx
          const tabId = t.id
          selectedDetails.push(getSelectedName(selectedValue, item, categoryName, categoryCode, groupName, tabId, tabIdx, t.data, parkingFlg))
        })

      // ラウンドプラス
      } else if (d.categoryCode == '2') {
        const rlsPlus = { categoryName: d.categoryName, rlsPlus1: null, rlsPlus2: null, rlsPlus3: null }
        if (md.unitPrice) {
          // 巡回清掃の詳細を取得
          rlsPlus.rlsPlus1 = getSelectedName(md.selectedRls, getEstimateSubItemList('1', estimateSubItemList), '巡回清掃', categoryCode, groupName, null, null, md, parkingFlg)
          // 固定清掃がある場合
          if (md.unitPrice.normal && md.unitPrice.normal != '0') {
            const rlsPlus2 = getSelectedName(md.selectedNormal, getEstimateSubItemList('3', estimateSubItemList), '固定清掃', categoryCode, groupName, null, null, md, parkingFlg)
            // 表示しないものを削除する
            if (rlsPlus2 && rlsPlus2.details.length) {
              const det = rlsPlus2.details.filter((d) => {
                return d.name.indexOf('祝日対応') == -1 && d.name.indexOf('年末年始') == -1 && d.name.indexOf('早朝割増') == -1
              })
              rlsPlus2.details = det
            }
            rlsPlus.rlsPlus2 = rlsPlus2
          }
          // 定期清掃がある場合
          if (md.unitPrice.fixed && md.unitPrice.fixed != '0') {
            rlsPlus.rlsPlus3 = getSelectedName(md.selectedFixed, getEstimateSubItemList('5', estimateSubItemList), '定期清掃', categoryCode, groupName, null, null, md, parkingFlg)
          }
          selectedDetails.push(rlsPlus)
        }

      // 特別清掃
      } else if (d.categoryCode == '14') {
        if (md.display && md.display.length) {
          selectedDetails.push(getSelectedNameForSp(item, md.display, d.categoryName, categoryCode, groupName, null, null))
        }
      } else {
        // 詳細モーダルで選択した値
        const selectedValue = d.subItemModalData.selectedValue
        selectedDetails.push(getSelectedName(selectedValue, item, d.categoryName, categoryCode, groupName, null, null, md, parkingFlg))
      }
    }
  }
  return selectedDetails
}

/**
 * 数字を丸文字に変換
 * !!50までしかありません!!
 * @param {*} num 数字
 * @returns 
 */
export function toCircled (num) {
  if (!num || isNaN(num)) {
    return ''
  }
  if (num <= 20) {
    const base = '①'.charCodeAt(0);
    return String.fromCharCode(base + num - 1)
  }
  if (num <= 35) {
    const base = '㉑'.charCodeAt(0);
    return String.fromCharCode(base + num - 21)
  }
  const base = '㊱'.charCodeAt(0);
  return String.fromCharCode(base + num - 36)
}

/**
 * 和暦返還
 * @param {*} day 対象日
 * @returns 
 */
export function japaneseDate (day) {
  return new Date(day).toLocaleDateString('ja-JP-u-ca-japanese', { era: 'long', year: 'numeric', month: 'long', day: 'numeric' })
}

/**
 * 対象の文字列に検索文字が含まれているか（部分一致）
 * 全角半角関係なし
 * @param target 対象文字列
 * @param valArr 配列の検索文字列
 */
export function includesTxt (target, valArr) {
  // 対象の項目が空でない場合
  if (target) {
    // 検索文字がある場合
    if (valArr && valArr.length) {
      for (let i = 0; i < valArr.length; i++) {
        const val = valArr[i]
        if (hankaku(target).indexOf(hankaku(val)) > -1) {
          return true
        }
      }
    }
  }
  return false
}

/**
 * 社員用の雇い入れの権限（閲覧など）
 * 管理者、大野様、人事部はtrue
 * @param {*} userId ログインユーザーID
 * @param {*} info 兼任も含めて所属している部署データ
 * @returns 
 */
export function isRegularStaffAuthorized (userId, info) {
  let isEmployment = false
  // 管理者ユーザー、大野様はOK
  if (REGULAR_STAFF_AUTH.includes(userId)) {
    isEmployment = true
  // 人事部はOK
  } else {
    if (info && info.data.data && info.data.data.length) {
      for (let i = 0; i < info.data.data.length; i++) {
        const emp = info.data.data[i]
        if (emp.name == '人事総務経理') {
          isEmployment = true
          break
        }
      }
    }
  }
  return isEmployment
}

/**
 * 課長、もしくは部長の場合、所属部署を配列で返却
 * @param {*} info 兼任も含めて所属している部署データ
 * @returns 所属部署Idの配列、所属部署名の配列
 */
export function departmentArr (info) {
  const res = []
  if (info && info.data.data && info.data.data.length) {
    for (let i = 0; i < info.data.data.length; i++) {
      const emp = info.data.data[i]
      if (['課長', '部長'].includes(emp.sub)) {
        res.push({
          id: emp.departmentId,
          name: emp.name,
          sub: emp.sub
        })
      }
    }
  }
  return res
}

/**
 * 社員雇入れで、同じ所属部長・課長は表示する
 * @param {*} departmentArr 部署配列
 * @param {*} targetDep 社員の所属部署ID
 * @returns 
 */
export function isRegularStaffAuthorized2 (departmentArr, targetDep) {
  // 部署配列がある場合（部長OR課長のみ配列あり）
  if (departmentArr && departmentArr.length) {
    for (let i = 0; i < departmentArr.length; i++) {
      const dep = departmentArr[i]
      if (['課長', '部長'].includes(dep.sub) && dep.id == targetDep) {
        return true
      }
    }
  }
  return false
}

/**
 * 社員用の面接関連の権限（閲覧など）
 * @param {*} userId ログインユーザーID
 * @param {*} info 兼任も含めて所属している部署データ
 * @returns 
 */
export function isRegularStaffInterviewAuthorized (userId, userName, requester, pic, interviewsResult, userInfo, department) {
  let res = false
  // 管理者ユーザー、大野様は常にOK
  if (REGULAR_STAFF_AUTH.includes(userId)) {
    res = true
  // 担当者、依頼者はOK
  } else if (requester == userName || pic == userName) {
    res = true
  } else {
    // ユーザーの部署名、権限データ
    let ui = []
    if (userInfo.data.data && userInfo.data.data.length) {
      ui = userInfo.data.data
    }

    if (ui && ui.length) {
      // 人事総務部はOK
      res = ui.some((u) => {
        return u.name == '人事総務経理'
      })

      if (!res) {
        // 依頼先部署と同じ部署で、社員面接の権限があればOK
        res = ui.some((u) => {
          return u.name == department && u.regularStaffAuth
        })
      }
    }
  }

  // let res = false
  // // 管理者ユーザー、大野様は常にOK
  // if (REGULAR_STAFF_AUTH.includes(userId)) {
  //   res = true
  // // 担当者、依頼者はOK
  // } else if (requester == userName || pic == userName) {
  //   res = true
  // } else {
  //   // 採否
  //   let ir = null
  //   if (interviewsResult && interviewsResult.length) {
  //     ir = interviewsResult[0]
  //   }
  //   // ユーザーの部署名、権限データ
  //   let ui = []
  //   if (userInfo.data.data && userInfo.data.data.length) {
  //     ui = userInfo.data.data
  //   }

  //   // 採用の場合
  //   if (ir == '社員採用') {
  //     // 人事総務部はOK
  //     if (ui && ui.length) {
  //       res = ui.some((u) => {
  //         return u.name == '人事総務経理'
  //       })
  //     }

  //   // 未決、もしくは不採用
  //   } else {
  //     // 依頼先部署と同じ部署で、社員面接の権限があればOK
  //     if (ui && ui.length) {
  //       res = ui.some((u) => {
  //         return u.name == department && u.regularStaffAuth
  //       })
  //     }
  //   }
  // }
  return res
}

/**
 * 社員面接チャットワークマスタ編集権限
 * @param {*} userId ユーザーID
 * @param {*} userInfo ユーザー情報
 * @returns 
 */
export function isRegularStaffInterviewChatMstEdit (userId, userInfo) {
  let res = false
  // 管理者ユーザー、大野様は常にOK
  if (REGULAR_STAFF_AUTH.includes(userId)) {
    res = true
  } else {
    // ユーザーの部署名、権限データ
    let ui = []
    if (userInfo.data.data && userInfo.data.data.length) {
      ui = userInfo.data.data
    }
    // 人事総務部はOK
    if (ui && ui.length) {
      res = ui.some((u) => {
        return u.name == '人事総務経理'
      })
    }

    // 人事部でない場合
    if (!res) {
      // 社員面接の権限があればOK
      if (ui && ui.length) {
        res = ui.some((u) => {
          return u.regularStaffAuth
        })
      }
    }
  }
  return res
}

/**
 * 昇順に並び替え
 * @param {*} tar 対象配列
 * @returns 
 */
export function numberSort (tar) {
  if (tar && tar.length) {
    tar.sort((a, b) => a - b)
  }
  return tar
}

/**
 * 画像ファイルより、EXIFの情報を抽出
 * @param {*} file 画像ファイル
 * @param {*} tag 取得する情報
 * @returns 
 */
export async function getEXIFInfo(file, tag) {
  return new Promise((resolve) => {
    EXIF.getData(file, function() {
      resolve(EXIF.getTag(this, tag));
    });
  });
}

/**
 * 画像ファイルより、EXIFの情報を抽出
 * @param {*} file 画像ファイル
 * @param {*} tag 取得する情報
 * @returns 
 */
export async function getEXIFDateTimeOriginal(file) {
  console.log('写真撮影日時取得！！')
  console.log(this)
  let res = await getEXIFInfo(file, 'DateTimeOriginal')
  if (res) {
    res = res.replace(':', '-').replace(':', '-')
  }
  return res
}

/**
 * 写真の日付をチェック
 * @param {*} date 写真撮影日付
 * @returns NG：false
 */
export function checkPhotoDate(date) {
  // alert(date)
  // 日付が本日でない場合はNG
  if (date) {
    // 本日日付であり
    if (moment(date).format('YYYYMMDD') == moment().format('YYYYMMDD')) {
      // 時間が00：00ではないものはOK
      if (moment(date).format('mmss') != '0000') {
        return true
      }
    }
  } else {
    return true
  }
  alert('この写真は、本日撮影されたものではない可能性が高いため、添付できません。')
  return false
}

/**
 * 写真に日付を描画
 * @param {*} date 日付
 * @param {*} canvas 対象写真
 * @param {*} ctx 
 * @param {*} isTop 上部に表示する
 */
export function setDateToImg(date, canvas, ctx, isTop) {
  let text = moment(date).format('YYYY/MM/DD')
  if (!date) {
    text = moment().format('YYYY/MM/DD')
  }
  const x = canvas.width - 20
  let y = canvas.height - 20
  if (isTop) {
    y = 30
  }
  ctx.font = 'bold 25px serif'
  ctx.strokeStyle = '#0000ff'
  ctx.fillStyle = '#ffffff'
  // ctx.lineWidth = 20
  ctx.textAlign = 'right'
  // ctx.stroke()
  ctx.fillText(text, x, y)
}

/**
 * 写真の日付（年月）をチェック
 * @param {*} date 写真撮影日付
 * @returns NG：false
 */
export function checkPhotoMonth(date) {
  // alert(date)
  // 日付が今月でない場合はNG
  if (date) {
    // 今月の場合
    if (moment(date).format('YYYYMM') == moment().format('YYYYMM')) {
      // 時間が00：00ではないものはOK
      if (moment(date).format('mmss') != '0000') {
        return true
      }
    }
  } else {
    return true
  }
  alert('この写真は、今月撮影されたものではない可能性があるため、添付できません。')
  return false
}