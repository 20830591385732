// import * as moment from "node_modules/moment/moment"

import * as moment from 'moment'
import * as employmentMediaManager from '@managers/employmentMediaManager'

// 雇い入れ集計リスト（過去の分）
const EL_TASKS_TYPE = 'employment_list'
// リクエストポスト
const R_TASKS_TYPE = 'apollo_request_post'

// 面接データから取得する項目
const INT_ITEM = [
  // 登録日
  'createDate',
  // 採否
  'interviewsResult',
  // 募集媒体
  'interviewsMedium'
]

// 雇い入れデータから取得する項目
const EMP_ITEM = [
  // 登録日
  'createDate',
  // 種別
  'employmentClass',
  // 募集媒体
  'employmentMedium'
]

/**
 * 過去の集計結果を取得
 * @param {*} context 
 * @returns 
 */
export async function getOldList(context) {
  const searchCondition = {
    searchCondition: {
      type: EL_TASKS_TYPE,
      status: { $ne: "remove" },
    },
    sort: { insertedDate: -1 }
  }
  try {
    const responseData = await context.$pigeon.searchTasks(searchCondition)
    return responseData
  } catch (error) {
    console.log('getOldEmoloymentList error !!!')
    console.log(error)
    alert(error)
    return []
  }
}

/**
 * 保存されていない月から現在までを取得
 * @param {*} context 
 * @returns 
 */
export async function getNewList(context, start) {
  // 面接のデータ取得
  const searchCondition = {
    searchCondition: {
      type: R_TASKS_TYPE, // リクエストポスト
      category: { $eq: '面接関連' }, // お問い合わせカテゴリー
      interviewsRegularStaff: true, // 社員
      status: { $ne: 'remove' },
    },
    selectFields: INT_ITEM,
    sort: { createDate: -1 }
  }

  // DC雇い入れデータ取得
  const searchCondition2 = {
    searchCondition: {
      type: R_TASKS_TYPE, // リクエストポスト
      category: { $eq: '雇い入れ' }, // お問い合わせカテゴリー
      employmentRegularStaff: { $ne: true }, // 社員以外
      status: { $ne: 'remove' },
    },
    selectFields: EMP_ITEM,
    sort: { createDate: -1 }
  }

  // 社員雇い入れの「退職」データ取得
  const searchCondition3 = {
    searchCondition: {
      type: R_TASKS_TYPE, // リクエストポスト
      category: { $eq: '雇い入れ' }, // お問い合わせカテゴリー
      employmentClass: { $eq: '社員退職' }, // 種別
      employmentRegularStaff: true, // 社員
      status: { $ne: 'remove' },
    },
    selectFields: EMP_ITEM,
    sort: { createDate: -1 }
  }

  // 登録日の検索があれば、条件に追加
  if (start) {
    searchCondition.searchCondition.createDate = { $gte: start }
    searchCondition2.searchCondition.createDate = { $gte: start }
    searchCondition3.searchCondition.createDate = { $gte: start }
  }

  try {
    // 面接
    const men = await context.$pigeon.searchTasks(searchCondition)
    // DC雇い入れ
    const retire = await context.$pigeon.searchTasks(searchCondition2)
    // 社員雇い入れ「退職」
    const regular = await context.$pigeon.searchTasks(searchCondition3)

    // 募集媒体の色を取得
    const mediumList = await employmentMediaManager.getMediaList(context)

    let res = {}
    // 募集媒体合計数(入力されている分)
    // 面接（社員）
    if (men && men.length) {
      for (let i = 0; i < men.length; i++) {
        const m = men[i]
        const date = moment(m.createDate).format('YYYY-MM')

        // 同じ登録月がない場合
        if (!res[date]) {
          res[date] = { hire: 0, retire: 0, mediumName: {}, hireRegular: 0, rejectRegular: 0, unresolvedRegular: 0, retireRegular: 0, mediumNameRegular: {} }
        }
        // 未決
        if (!m.interviewsResult || !m.interviewsResult.length) {
          res[date].unresolvedRegular++
        // 採用
        } else if (m.interviewsResult.includes('社員採用')) {
          res[date].hireRegular++
        // 不採用
        } else if (m.interviewsResult.includes('社員不採用')) {
          res[date].rejectRegular++
        }

        // 募集媒体
        if (m.interviewsMedium) {
          if (!res[date].mediumNameRegular[m.interviewsMedium]) {
            res[date].mediumNameRegular[m.interviewsMedium] = 1
          } else {
            res[date].mediumNameRegular[m.interviewsMedium]++
          }
        }
      }
    }

    // DC雇い入れ
    if (retire && retire.length) {
      for (let i = 0; i < retire.length; i++) {
        const r = retire[i]
        const date = moment(r.createDate).format('YYYY-MM')

        // 同じ登録月がない場合
        if (!res[date]) {
          res[date] = { hire: 0, retire: 0, mediumName: {}, hireRegular: 0, rejectRegular: 0, unresolvedRegular: 0, retireRegular: 0, mediumNameRegular: {} }
        }

        // 入社
        if (r.employmentClass.includes('新規入社')) {
          res[date].hire++
        // 退職
        } else if (r.employmentClass.includes('退職')) {
          res[date].retire++
        }

        // 募集媒体
        if (r.employmentMedium && r.employmentClass.includes('新規入社')) {
          if (!res[date].mediumName[r.employmentMedium]) {
            res[date].mediumName[r.employmentMedium] = 1
          } else {
            res[date].mediumName[r.employmentMedium]++
          }
        }
      }
    }

    // 社員「退職」雇い入れ
    if (regular && regular.length) {
      for (let i = 0; i < regular.length; i++) {
        const r = regular[i]
        const date = moment(r.createDate).format('YYYY-MM')

        // 同じ登録月がない場合
        if (!res[date]) {
          res[date] = { hire: 0, retire: 0, mediumName: {}, hireRegular: 0, retireRegular: 0, rejectRegular: 0, unresolvedRegular: 0, mediumNameRegular: {} }
        }
        res[date].retireRegular++
      }
    }

    const res2 = []
    const keys = Object.keys(res)
    for (let i = 0; i < keys.length; i++) {
      const k = keys[i]

      // 登録月フォーマット
      const insertedDateForm = k.replace('-', '年') + '月'

      // 入退社の差
      res[k].diff = res[k].hire - res[k].retire

      // 募集媒体を成形
      res[k].medium = []
      res[k].mediumRegular = []

      // 募集媒体ごとに割合を出す、降順にする
      res[k] = formingMedium(res[k], 'mediumName', res[k].hire, 'medium', mediumList) // DC
      res[k] = formingMedium(res[k], 'mediumNameRegular', (res[k].hireRegular + res[k].unresolvedRegular + res[k].rejectRegular), 'mediumRegular', mediumList) // 社員
      res2.push({ insertedDate: k, insertedDateForm, ...res[k] })
    }

    return res2
  } catch (error) {
    console.log('employmenListManager.getDC error !!!')
    console.log(error)
    alert(error)
    return []
  }
}

/**
 * 募集媒体を成型
 * @param {*} res 対象行データ 
 * @param {*} name 募集媒体のプロパティ名
 * @param {*} mediumTotal 合計募集媒体件数
 * @param {*} medium 最終的な結果を入れるプロパティ名
 * @param {*} colorList 募集媒体のマスタ
 * @returns 
 */
function formingMedium(res, name, mediumTotal, medium, colorList) {
  // 募集媒体ごとに割合を出す
  const mediumName = Object.keys(res[name])
  if (mediumName && mediumName.length) {
    for (let j = 0; j < mediumName.length; j++) {
      const me = mediumName[j]

      // 募集媒体の色を取得
      let color = null
      let bgColor = null
      if (colorList && colorList.length) {
          const col = colorList.find((c) => {
            return c.name == me
          })
          if (col && col.color) {
            color = col.color
          }
          if (col && col.bgColor) {
            bgColor = col.bgColor
          }
      }

      // 割合
      let rate = 0
      if (mediumTotal) {
        rate = Math.round(res[name][me] * 100 / mediumTotal)
      }

      res[medium].push({ name: me, count: res[name][me], rate, color, bgColor })
    }
  }

  // 媒体募集の数が多い順に並び替え
  if (res[medium] && res[medium].length > 1) {
    res[medium].sort((a, b) => b.count - a.count)
  }
  delete res[name]
  return res
}

/**
 * 過去のデータを年月でupsert
 * @param {*} context 
 * @param {*} data 登録データ
 * @returns 
 */
export async function register(context, data) {
  try {
    // 更新条件（あれば更新、なければ新規登録）
    const filter = { type: EL_TASKS_TYPE, insertedDate: data.insertedDate }
    // 保存する内容にtypeを入れる
    data.type = EL_TASKS_TYPE

    await context.$pigeon.updateOne(filter, data)
    return true
  } catch (error) {
    console.log('employmenListManager.register error !!!')
    console.log(error)
    alert(error)
    return false
  }
}