<template>
	<div>
		<Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
			<template #action>
				<div class="content-container-app overflow-auto">
					<div class="text-lg text-blue-800 font-bold" v-if="isNew">新規登録</div>
					<div class="text-lg text-blue-800 font-bold" v-else>編集</div>
					<div class="w-full mt-3">
						<!-- 区分 -->
						<div class="text-xs mb-1 text-gray-700 font-bold">区分<span class="-top-0.5 ml-1 text-red-500"> * </span></div>
						<div class="flex mx-auto w-fit">
							<label class="mr-5 px-3 py-1 cursor-pointer">
								<input type="radio" name="classification" value="1" v-model="sub.classification">
								受注以外
							</label>
							<label class="ml-5 px-3 py-1 cursor-pointer">
								<input type="radio" name="classification" value="2" v-model="sub.classification">
								受注
							</label>
						</div>

						<!-- サブタスク名 -->
						<div class="mt-3">
							<WmsTextInput 
								name="name" 
								caption="サブタスク名"
								:required="true"
								v-model="sub.name"
							/>
						</div>

						<!-- 担当者設定値 -->
						<div class="mt-10 flex font-bold mb-1 cursor-pointer">
							<div class="rounded-l px-2 py-1 text-xs text-white" :class="!sub.byMainDepartment?'bg-blue-600':'bg-gray-300'" @click="clickByMainDepartment(false)">
								メイン依頼部署によってサブの担当が変わらない
							</div>
							<div class="rounded-r px-2 py-1 text-xs text-white" :class="sub.byMainDepartment?'bg-blue-600':'bg-gray-300'" @click="clickByMainDepartment(true)">
								メイン依頼部署によってサブの担当が変わる
							</div>
							
							<!-- プラスボタン -->
							<PlusCircleIcon v-if="sub.byMainDepartment" class="ml-auto text-blue-700 hover:text-indigo-700 active:text-indigo-600 cursor-pointer col-actions w-6 h-6" @click="addCharge()" />
						</div>

						<!-- メイン依頼先部署によって変わらない -->
						<div v-if="!sub.byMainDepartment" class="mt-1 flex">
							<!-- 担当部署 -->
							<div class="flex-1">
								<div class="text-xs mb-1 text-gray-700 font-bold">担当部署</div>
								<select
									v-model="sub.departmentName"
									class="border-gray w-full rounded py-1"
								>
								<option v-for="(row, index) in depList" :value="row.name" :key="'d' + index">
									{{ row.name }}
								</option>
								</select>
							</div>

							<!-- 担当スタッフ -->
							<div class="flex-1">
								<div class="text-xs mb-1 text-gray-700 font-bold">担当者</div>
								<select
									v-model="sub.toStaffName"
									class="border-gray w-full rounded py-1"
								>
								<option v-for="(row, index) in staffList(sub.departmentName)" :value="row.userName" :key="'s' + index">
									{{ row.userName }}
								</option>
								</select>
							</div>
						</div>
						<!-- メイン依頼先部署によって変わる -->
						<div v-else class="mt-1">
							<div v-for="(sc, i) in sub.charge" :key="'charge'+i" class="flex mt-3">
								<!-- メイン依頼先部署 -->
								<div class="flex-1">
									<div class="text-xs mb-1 text-gray-700 font-bold">メイン依頼先部署</div>
									<select
										v-model="sc.mainDepartmentName"
										class="border-gray w-full rounded py-1 text-sm"
									>
										<option v-for="(row, index) in mainDepList" :value="row.name" :key="'md' + index">
											{{ row.name }}
										</option>
									</select>
								</div>
								<div class="font-bold mx-2 mt-auto mb-1">➡</div>

								<!-- 担当部署 -->
								<div class="flex-1">
									<div class="text-xs mb-1 text-gray-700 font-bold">担当部署</div>
									<select
										v-model="sc.departmentName"
										class="border-gray w-full rounded py-1 text-sm"
										@change="reload++"
									>
										<option v-for="(row, index) in depList" :value="row.name" :key="'d' + index">
											{{ row.name }}
										</option>
									</select>
								</div>

								<!-- 担当スタッフ -->
								<div class="flex-1">
									<div class="text-xs mb-1 text-gray-700 font-bold">担当者</div>
									<select
										v-model="sc.toStaffName"
										class="border-gray w-full rounded py-1 text-sm"
									>
									<option v-for="(row, index) in staffList(sub.charge[i].departmentName)" :value="row.userName" :key="'s' + index">
										{{ row.userName }}
									</option>
									</select>
								</div>
								
								<!-- 削除ボタン -->
								<TrashIcon class="text-gray-400 cursor-pointer hover:text-gray-500 active:text-gray-600 w-6 h-6 mt-auto" @click="deleteCharge(i)" />
							</div>
						</div>

						<!-- 期限 -->
						<div class="mt-10">
							<div class="flex">
								<div class="text-xs mb-1 text-gray-700 font-bold">{{!sub.limitMonthCount?'期限（〇〇から●日後）':'期限（〇〇から●ヶ月後）'}}</div>
								<div class="flex ml-28 font-bold mb-1 cursor-pointer">
									<div class="rounded-l px-2 py-1 text-xs text-white" :class="!sub.limitMonthCount?'bg-blue-600':'bg-gray-300'" @click="clickLimitMonth(false)">
										日数
									</div>
									<div class="rounded-r px-2 py-1 text-xs text-white" :class="sub.limitMonthCount?'bg-blue-600':'bg-gray-300'" @click="clickLimitMonth(true)">
										月数
									</div>
								</div>
							</div>
							<div class="flex">
								<div>
									<select
										v-model="sub.limitTarget"
										class="border-gray min-w-188 rounded py-1"
									>
										<option v-for="(row, index) in limitTargetList" :value="row.value" :key="'l' + index">
											{{ row.name }}
										</option>
									</select>
								</div>
								<div class="font-bold ml-2 mt-auto w-10">から</div>
								<input type="number" class=" py-1 border-gray rounded" v-model="sub.limit">
								<div class="font-bold ml-2 mt-auto">{{!sub.limitMonthCount?'日後':'ヶ月後'}}</div>
							</div>
						</div>

						<!-- メモ -->
						<div class="mt-5">
							<WmsTextInput 
								name="memo" 
								caption="メモ"
								v-model="sub.memo"
							/>
						</div>

						<!-- 最終完了タスク -->
						<div class="mt-5">
							<input
								type="checkbox"
								id="finalCompletionTask"
								v-model="sub.finalCompletionTask"
							>
							<label for="finalCompletionTask" class="text-blue-800 font-bold text-sm py-1 px-2"> 最終完了タスク </label>
						</div>

            <!-- 詳細 -->
            <div class="border border-blue-200 rounded mt-5">
              <div class="font-bold text-white text-sm flex justify-between py-2 px-3 bg-blue-400">
                詳細タスク
                
                <!-- 並べ替え -->
                <div class="toggle flex items-center my-auto ml-10 mr-auto">
                  <span class="mr-1 text-white font-bold text-xs">並べ替え</span>
                  <Toggle v-model="sortable" size="small" />
                </div>
                <!-- プラスボタン -->
                <PlusCircleIcon class="text-white hover:text-indigo-700 active:text-indigo-600 cursor-pointer col-actions w-6 h-6" @click="addDetail()" />
              </div>

              <draggable
                key="sub"
                :list="sub.details"
                direction="vertical"
                handle=".row-drag-handle"
                tag="div"
              >
                <div v-for="(row, i) in sub.details" :key="i">
                  <div class="flex justify-between py-1 px-3">
                  <div>
                    <div v-if="sortable" class="mx-1.5 items-center my-1 row-drag-handle prasp">
                      <MenuIcon class="text-gray-400" />
                    </div>
                    <div v-else class="ml-1 my-1 w-8">
                      {{ i + 1 }}.
                    </div>
                  </div>
                    <input
                      type="text"
                      class="w-full border-gray-300 rounded py-1 text-sm mr-3"
                      v-model="row.task"
                    />
                    <!-- 削除ボタン -->
                    <TrashIcon class="text-gray-400 cursor-pointer hover:text-gray-500 active:text-gray-600 w-6 h-6 m-auto" @click="deleteDetail(i)" />
                  </div>
                </div>
              </draggable>
            </div>
						<div>
							<PrimaryButton text="登録" size="normal px-10 mb-5 mt-5 w-full" @click="register()" />
						</div>
					</div>
					<div class="text-transparent">{{reload}}</div>
				</div>
			</template>
		</Modal>
	</div>
</template>
<script>

import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import WmsTextInput from '@wmscomponents/WmsTextInput.vue'
import { TrashIcon, PlusCircleIcon, MenuIcon } from '@vue-hero-icons/solid'
import Toggle from '@components/Toggle.vue'
import draggable from "vuedraggable"
import * as logManager from '@managers/logManager'
import * as dialogs from '@libs/dialogs'
import * as utils from '@libs/utils'
import { SUB_TASK_LIMIT } from '@libs/constants'

const FORMID = 'task_master'

const SUB = {
	classification: '1',
	name: '',
	departmentName: '',
	toStaffName: '',
	limitTarget: null,
	limit: null,
	memo: '',
	finalCompletionTask: '',
  details: [{ task: '', isDone: false }],
	limitMonthCount: false,
	byMainDepartment:false
}

export default {
	components: {
		Modal,
		PrimaryButton,
		WmsTextInput,
    TrashIcon,
    PlusCircleIcon,
    MenuIcon,
    Toggle,
    draggable
	},

	props: {
		target: {
			type: Object
		},
		subList: {
			type: Array
		},
		open: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			reload: 0,
			// 新規作成
			isNew: false,
			// サブタスク
			sub: utils.clone(SUB),
      // 並べ替え
      sortable: false,
			// 期限の対象日
			limitTargetList: utils.clone(SUB_TASK_LIMIT),
		}
	},

	watch: {
		open() {
			if (!this.open) {
				return
			}
			this.getTarget()
		}
	},

	computed: {
		/**
		 * 部署リスト
		 */
		depList() {
			return this.$store.getters.departmentList
		},

		/**
		 * メイン部署リスト
		 */
		mainDepList() {
			const dep = utils.clone(this.$store.getters.departmentList)
			dep.push({ name: '他全て' })
			return dep
		},

		/**
		 * スタッフリスト
		 * @param name 部署名
		 */
		staffList() {
			return (name) => {
				let res = []
				if (name) {
					// 部署IDを取得
					const dep = this.depList.find((d) => {
						return d.name == name
					})
					// 部署IDが取得できた場合
					if (dep && dep.departmentId) {
						// 該当の部署に所属しているスタッフを取得
						const ud = this.$store.getters.userDepartment
						res = ud.filter((u) => {
							return u.departmentId == dep.departmentId
						})
						return res
					}
				}
				return res
			}
		}
	},

	methods: {
		/**
		 * 表示するデータ取得
		 */
		getTarget() {
			// 編集の場合
			if (this.target && Object.keys(this.target).length) {
				this.isNew = false
				this.sub = utils.clone(this.target)
				if (!this.sub.limitTarget) {
					this.sub.limitTarget = null
				}
        if (!this.sub.details) {
          this.sub.details = [{ task: '', isDone: false }]
        }
			// 新規の場合
			} else {
				this.isNew = true
				this.sub = utils.clone(SUB)
			}
		},

		/**
		 * 初期化
		 */
		closed() {
			// 新規作成
			this.isNew = false
			// サブタスク
			this.sub = utils.clone(SUB)
		},

		/**
		 * 登録処理
		 */
		async register() {
			// 必須
			if (!this.sub.classification) {
				await this.errRequired('区分')
				return
			}
			if (!this.sub.name) {
				await this.errRequired('サブタスク名')
				return
			}

			// サブタスク名がかぶっていないか
			const err = this.subList.find((s) => {
				return (s.name == this.sub.name && s._id != this.sub._id && s.classification == this.sub.classification)
			})
			if (err && err.name) {
				await dialogs.showErrorDialog('同じサブタスク名が既にあります。', '同じサブタスク名が既に存在します。\r\nサブタスク名を変更してください。')
				return
			}

			// メイン依頼先部署によってサブ担当を変更する場合
			if (this.sub.byMainDepartment) {
				let main = []
				if (this.sub.charge && this.sub.charge.length) {
					for (let i = 0; i < this.sub.charge.length; i++) {
						const sc = this.sub.charge[i]
						if (!sc.mainDepartmentName) {
							await dialogs.showErrorDialog('メイン依頼先部署未選択', (i + 1) + '番目のメイン依頼先部署を選択してください。\r\n設定している以外の部署全て共通する場合は「他全て」で設定できます。')
							return
						} else if (main.includes(sc.mainDepartmentName)) {
							await dialogs.showErrorDialog('メイン依頼先部署が重複', sc.mainDepartmentName + 'が重複しています。')
							return
						} else {
							main.push(sc.mainDepartmentName)
						}
					}
				}
			}

      // 詳細タスク未記入分は保存しない
      let det = []
      for (let i = 0; i < this.sub.details.length; i++) {
        const s = this.sub.details[i]
        if (s.task) {
          det.push(s)
        }
      }
      this.sub.details = det

			// 登録
			try {
				// 新規
				if (this.isNew) {
					await this.$pigeon.registerTask(FORMID, this.sub, {})
				// 更新
				} else {
					await this.$pigeon.updateTask(this.sub._id, this.sub, {})
				}

				// ログ登録
				logManager.recordingByRegist(
					this,
					this.isNew,
					'サブタスクマスタ',
					'サブタスク（' + this.sub.name + '）'
				)
			} catch (error) {
				alert(error)
			}
				this.$emit('modalClose')
				this.closed()
		},

		/**
		 * 必須項目アラート
		 * @param item 項目名
		 */
		async errRequired(item) {
				await dialogs.showErrorDialog(item + 'は必須です', item + 'が選択されていません。')
		},

    /**
     * 詳細追加
     */
    addDetail() {
      this.sub.details.push({ task: '', isDone: false })
    },

    /**
     * 詳細項目削除
     * @param i インデックス
     */
    deleteDetail(i) {
      this.sub.details.splice(i, 1)
      if (!this.sub.details) {
        this.addDetail()
      }
    },

		/**
		 * 期限のカウント方法
		 */
		clickLimitMonth(val) {
			this.sub.limitMonthCount = val
			this.reload++
		},

		/**
		 * メイン依頼先部署によってサブの担当が変わる
		 * @param val true：変わる、false：変わらない
		 */
		clickByMainDepartment(val) {
			this.sub.byMainDepartment = val
			if (val) {
				this.addCharge()
			} else {
				delete this.sub.charge
			}
			this.reload++
		},

		/**
		 * サブ担当追加
		 */
		addCharge() {
			if (!this.sub.charge) {
				this.sub.charge = []
			}
			this.sub.charge.push({ mainDepartmentName: '', departmentName: '', toStaffName: '' })
			this.reload++
		},

		/**
		 * サブ担当削除
		 */
		deleteCharge(i) {
      this.sub.charge.splice(i, 1)
      if (!this.sub.charge || !this.sub.charge.length) {
        this.addCharge()
      }
			this.reload++
		}
	}
}

</script>
<style scoped>
.content-container-app {
	width: 700px;
	padding: 0 10px;
}

.border-gray {
	border: solid #d3cfcf 1px;
}

.drop {
	width: 92%;
	margin-right: auto;
}

.min-w-188 {
	min-width: 188px;
}
</style>